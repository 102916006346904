import React,{useEffect,useState} from 'react';
import { Card, CardContent, Typography, Grid, TextField, Button, Divider,Dialog,
  DialogActions,
  DialogContent,
  DialogTitle } from '@mui/material';
import api from '../Services/api';
import DateRange from '@mui/icons-material/DateRange';  
import {Col} from "react-bootstrap";
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaIndustry, FaMap, FaBuilding, FaUserAlt} from 'react-icons/fa';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';





export const AssessorProfile = () => {
  let data = sessionStorage.getItem('user');
  let assessorDataId = JSON.parse(data);
  let id = assessorDataId.id;
  console.log('assessor Id',id);
  const [openDialog, setOpenDialog] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [errors, setErrors] = useState({});
    const [originalData, setOriginalData] = useState({});
    
    const [assessorData, setAssessorData] = useState({   
        
        assessorCompanyName: "",
        companyId:"",
        assessorAddress: "",
        assessorStateId: "",
        assessorPlaceName: "",
        assessorPinCode: "",
        assessorName: "",
        assessorEmail: "",
        assessorPhoneNumber: "",
        assessorEmail1: "",
        assessorPhoneNumber1: "",
        joiningDate: " ",
        site:" ",
        exitDate: " ",

       
     
});

const[companyData , setCompanyData] = useState({
    principleEmployer: "",
            stateId: "",
            placeName: "",
            pinCode: "",
            authorisedPersonName: "",
            companyEmailId: "",
            companyPhoneNumber: "",
            companyAddress: "",
            authorisedPersonName : "",
            companyEmailId: "",
            companyPhoneNumber:"",
            clientName: "",
            clientContactPersonName: "",
            clientContactPersonPhoneNumber: "",
            clientContactPersonEmailId: "",
            
    
})
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');



const validateForm = () => {
  const newErrors = {};
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^[6789]\d{9}$/;
  if (!assessorData.assessorName) newErrors.assessorName = 'Assessor Name is required';
 
  if (!emailPattern.test(assessorData.assessorEmail)) {
    newErrors.assessorEmail = assessorData.assessorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
  }
  if (assessorData.assessorEmail1 && !emailPattern.test(assessorData.assessorEmail1)) {
    newErrors.assessorEmail1 = 'Invalid email address (e.g., text@email.com)';
  }
  
  if (assessorData.assessorPhoneNumber1 && !phonePattern.test(assessorData.assessorPhoneNumber1)) {
    newErrors.assessorPhoneNumber1 = 'Invalid phone number. Number should not start with zero)';
  }
  if (!phonePattern.test(assessorData.assessorPhoneNumber)) {
    newErrors.assessorPhoneNumber = assessorData.assessorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

  const handleEdit = () => {
    setOriginalData({...assessorData});
    setOpenDialog(true); 
  };

  const handleCancelEdit = () => {
    setOpenDialog(false); 
    setAssessorData({...originalData});
    
   
  };

  


  useEffect(() => {
    api.assessor.findOne(id)
    .then(response => {
      const temp = response.data.data;
    
    console.log("assessorData",temp);
    const temp1 = response.data.data.companyId;
    console.log("companyid",temp1);
       setAssessorData(temp);
       setOriginalData(temp);
       setCompanyId(temp1); 
    
    })
    .catch(error => console.error('error fetching company data', error));
  },[]);

useEffect(() => {
  if(!companyId) return;
   
api.client.getClient(companyId).then(response =>{
    const temp2 = response.data;
    console.log("client details",temp2);
    setCompanyData(temp2);
})
},[companyId]);


 
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setAssessorData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const handleSave = async ()=>{
  if(validateForm()){
   try{
  const response = await  api.assessor.editAssessor(id,assessorData)
   if(response.status === 200){
    setSnackbarOpen(true);
    setSnackbarMessage("Assessor details updated successfully")
   setOriginalData({ ...companyData });
   setOpenDialog(false);
   }
   else if(response.status === 404){
     setErrorSnackbarOpen(true);
     setSnackbarErrorMessage("Assessor not found");
   }
   else{
     setErrorSnackbarOpen(true);
     setSnackbarErrorMessage("Error in editing assessor profile")
   }
 
 }
  catch(error) {
   console.error("Error occurred while updating",error)
 }
}
 };

  return (
    <>
     <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>
    <Card className="d-md-flex m-5">
  
  <Col className=" p-4">
    
    <div>
    <div className='d-flex'>
   <img  className="bg-light" src={clientIcon} alt="client" height="50px" width="50px" ></img>
   <Typography className="p-2" variant="h6" gutterBottom>
 Principal Employer
</Typography>
   </div>


 <Typography className="mt-2 " variant="body2"  component="div">
 <FaBuilding /> Principal Employer Name : {companyData?.data?.principleEmployer} 
 </Typography>
 <Typography  className="mt-2 " variant="body2"  component="div">
 <FaMapMarkerAlt /> Address : {companyData?.data?.companyAddress}, {companyData?.data?.placeName} {companyData?.data?.stateId}-  {companyData?.data?.pinCode} 
 </Typography>





 </div>
<br></br>
 <div>
<Typography  variant="h6" gutterBottom>
Contact Person Details
</Typography>

<Typography className="mt-2" variant="body2" component="div">
<FaUserAlt /> Name : {companyData?.data?.authorisedPersonName}
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaEnvelope />  Email : {companyData?.data?.companyEmailId} {companyData?.data?.companyEmailId1 && `, ${companyData?.data?.companyEmailId1}`}  
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaPhone />  Phone Number : {companyData?.data?.companyPhoneNumber} {companyData?.data?.companyPhoneNumber1 && `, ${companyData?.data?.companyPhoneNumber1}`}
</Typography>
</div>

</Col>
<div className='vr'></div>
      <Col className=" p-4">
    
           <div>
           <div className='d-flex'>
          <img  className="bg-light" src={clientIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2" variant="h6" gutterBottom>
        Client
    </Typography>
          </div>
     
     
        <Typography className="mt-2 " variant="body2"  component="div">
        <FaBuilding /> Client Name : {companyData?.data?.clientName} 
        </Typography>
        <Typography  className="mt-2 " variant="body2"  component="div">
        <FaMapMarkerAlt /> Address : {companyData?.data?.companyAddress}, {companyData?.data?.placeName}  {companyData?.data?.stateId}- {companyData?.data?.pinCode}
        </Typography>
      
       
       

       
        </div>
       <br></br>
        <div>
<Typography  variant="h6" gutterBottom>
    Contact Person Details
</Typography>

<Typography className="mt-2" variant="body2" component="div">
<FaUserAlt /> Name : {companyData?.data?.clientContactPersonName}
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaEnvelope /> Email : {companyData?.data?.clientContactPersonEmailId} {companyData?.data?.clientContactPersonEmailId1 && `, ${companyData?.data?.clientContactPersonEmailId1}`}
</Typography>
<Typography className="mt-2" variant="body2"  component="div" >
<FaPhone /> Phone Number : {companyData?.data?.clientContactPersonPhoneNumber} {companyData?.data?.clientContactPersonPhoneNumber1 && `, ${companyData?.data?.clientContactPersonPhoneNumber1}`}  
</Typography>
</div>

      </Col>
      <div className='vr'></div>
 
      <Col className="  bg-dark">
      <button className='pt-2 btn btn-sm btn-secondary float-end p-3' onClick={handleEdit} style={{border:'0.5px solid black',borderRadius:'0px 0px 0px 50px'}}>Edit</button>
      <CardContent className="card-content  p-4 " >
        <div className='d-flex' >
          <img   className='bg-light' src={assesorIcon} alt="client" height="50px" width="50px" ></img>
          <Typography className="p-2 text-white" variant="h6" gutterBottom>
        Assessor 
  </Typography>
  </div>
       
    
     <Typography className="mt-2 text-white" variant="body2"  component="div">
     <FaBuilding /> Company Name : {assessorData?.assessorCompanyName}
     </Typography>
     <Typography className="mt-2 text-white" variant="body2" component="div">
<FaUserAlt />  Address : {assessorData?.assessorAddress},  {assessorData?.assessorPlaceName} {assessorData?.assessorStateId}- {assessorData?.assessorPinCode}
</Typography>
<Typography className="mt-2 text-white" variant="body2" component="div">
<FaMapMarkerAlt />  Site : {assessorData?.site}
</Typography>
<Typography className="mt-2 text-white" variant="body2"  component="div" >
<FaEnvelope />  Email : {assessorData?.assessorEmail} {assessorData?.assessorEmail1 && `, ${assessorData?.assessorEmail1}`}
</Typography>
<Typography className="mt-2 text-white " variant="body2"  component="div" >
<FaPhone />  Phone Number : {assessorData?.assessorPhoneNumber} {assessorData?.assessorPhoneNumber1 && `, ${assessorData?.assessorPhoneNumber1}`}
</Typography>

<Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange/> Joining Date : {assessorData?.joiningDate.slice(0, 10).split('-').reverse().join('-')}
            </Typography> 
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <DateRange/> Exit Date : {assessorData?.exitDate.slice(0, 10).split('-').reverse().join('-')}
            </Typography> 
     
            </CardContent>
   

   </Col>
     



    </Card>
    <Dialog open={openDialog} onClose={handleCancelEdit} maxWidth="md" fullWidth>
        <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT ASSESSOR PROFILE</h6>
        <DialogContent >
          <Grid container spacing={2}>
          
       
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Assessor Name"
                name="assessorName"
                value={assessorData.assessorName}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.assessorName}
              helperText={errors.assessorName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="assessorEmail"
                value={assessorData.assessorEmail}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.assessorEmail}
              helperText={errors.assessorEmail}
              />
            </Grid>
           
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="email"
                label="Alternative Email"
                name="assessorEmail1"
                value={assessorData.assessorEmail1}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.assessorEmail1}
              helperText={errors.assessorEmail1}
              
              />
            </Grid>
            
          
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="assessorPhoneNumber"
                value={assessorData.assessorPhoneNumber}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.assessorPhoneNumber}
              helperText={errors.assessorPhoneNumber}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Alternative PhoneNumber"
                name="assessorPhoneNumber1"
                value={assessorData.assessorPhoneNumber1}
                onChange={handleInputChange}
                margin="normal"
                error={!!errors.assessorPhoneNumber1}
              helperText={errors.assessorPhoneNumber1}
               
              />
              
            </Grid>
         
          </Grid>
        </DialogContent>
       
          <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-danger" onClick={handleCancelEdit} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" onClick={handleSave} disabled={!validateForm}>
            Save
          </button>
          </div>
      
      </Dialog>
    </>
  )
}
