import React, { useState, useEffect } from 'react'
import api from '../../../Services/api';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import LcrReport from './lcrReport';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import MonthlyComplianceReport from './monthlyCompliance';
 import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ArcElement
    } from 'chart.js';

    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement);
    const months = [
        { name: "JANUARY", value: "01" },
        { name: "FEBRUARY", value: "02" },
        { name: "MARCH", value: "03" },
        { name: "APRIL", value: "04" },
        { name: "MAY", value: "05" },
        { name: "JUNE", value: "06" },
        { name: "JULY", value: "07" },
        { name: "AUGUST", value: "08" },
        { name: "SEPTEMBER", value: "09" },
        { name: "OCTOBER", value: "10" },
        { name: "NOVEMBER", value: "11" },
        { name: "DECEMBER", value: "12" }
    ];

const VendorsMonthlyData = () => {
    let data1 = sessionStorage.getItem('user');
    let clientData = JSON.parse(data1);
    let id = clientData.id;
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2020 + 1 }, (x, i) => 2020 + i);
    const [vendorOptions, setVendorOptions] = useState([]);
    const[selectedVendor, setSelectedVendor] = useState('');
    const[selectedMonth,setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedYearForReport, setSelectedYearForReport] = useState('');
        const [selectedQuarter, setSelectedQuarter] = useState('');
        const [snackbarOpen, setSnackbarOpen] = useState(false);
          const [snackbarMessage, setSnackbarMessage] = useState('');
          const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
          const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
          const [openDialog, setOpenDialog] = useState(false);
          const [openDialogComplianceReport, setOpenDialogComplianceReport] = useState(false);

          const handleMonthChange = (event) => {
            setSelectedMonth(event.target.value);
        };

          const handleOpenDialog = () => {
            setOpenDialog(true);
          };
        
          const handleCloseDialog = () => {
            setOpenDialog(false);
          };
          const handleOpenDialogComplianceReport = () => {
            setOpenDialogComplianceReport(true);
          };
        
          const handleCloseDialogComplianceReport = () => {
            setOpenDialogComplianceReport(false);
          };
        
  const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
        {
            label: 'Accepted',
            data: [],
            backgroundColor: '#50c878',
        },
        {
            label: 'Rejected',
            data: [],
            backgroundColor: '#B22222',
        },
        {
            label: 'UnderReview',
            data: [],
            backgroundColor: '#EFCC00',
        }
        ]
    });
    const handleQuarterChange = (e) => {
        setSelectedQuarter(e.target.value);
    };
    useEffect(() => {
        const fetchVendors = async () => {
            try {
            const response = await api.client.getAllVendors(id);
            setVendorOptions(response.data.data.vendorProfile);
        }
        catch(error){
            console.error("Error in fetching the data",error)
        }
    }
    fetchVendors();
    }, [id]);

    const handleVendorChange = (event) =>{
const {value} = event.target;
setSelectedVendor(value);
    }
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
    const handleYearChanges = (event) => {
        setSelectedYearForReport(event.target.value);
    };
    const getQuarterMonths = (quarter) => {
        switch (quarter) {
        case 'Q1':
            return [0, 1, 2];
        case 'Q2':
            return [3, 4, 5]; 
        case 'Q3':
            return [6, 7, 8]; 
        case 'Q4':
            return [9, 10, 11]; 
        default:
            return [];
        }
    };
    useEffect(() => {
        if (snackbarMessage) {
            setSnackbarOpen(true); 
        } else {
            setSnackbarOpen(false);  
        }
    }, [snackbarMessage]); 
    
    useEffect(() => {
        if (snackbarErrorMessage) {
            setErrorSnackbarOpen(true);  
        } else {
            setErrorSnackbarOpen(false); 
        }
    }, [snackbarErrorMessage]); 


    useEffect(() => {
        if (selectedVendor && selectedVendor !== "select") {
            fetchVendorMonthlyData(selectedVendor, selectedYear, selectedQuarter);
        }
    }, [selectedVendor, selectedYear, selectedQuarter]);

    useEffect(() => {
        if (vendorOptions.length > 0 && !selectedVendor) {
            const firstVendorId = vendorOptions[0].vendorId; 
            setSelectedVendor(firstVendorId);
        }
    }, [vendorOptions, selectedVendor]); 

   
  
      const fetchVendorMonthlyData = async (vendorId, year, quarter = '') => {
        try {
        const response = await axios.get(`https://www.grcpulse.in/api/monthlydata/yearly/${vendorId}`, {
            params: { year }
        });
       if(response.status === 200){
        const data = response.data.data;
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        console.log("mothlydata",data);
        const quarterMonths = quarter ? getQuarterMonths(quarter) : [...Array(12).keys()];

        const acceptedPercent = [];
        const rejectedPercent = [];
        const underReviewPercent = [];
        const monthLabels = [];
 
        quarterMonths.forEach((monthIndex) => {
            const monthData = data.find(item => item.month === (monthIndex + 1).toString().padStart(2, '0'));
            monthLabels.push(months[monthIndex]);
            if (monthData) {
            acceptedPercent.push(monthData.AcceptedPercent || 0);
            rejectedPercent.push(monthData.RejectedPercent || 0);
            underReviewPercent.push(monthData.UnderReviewPercent || 0);
            } else {
            acceptedPercent.push(0);
            rejectedPercent.push(0);
            underReviewPercent.push(0);
            }
        });

        setChartData({
            labels: monthLabels,
            datasets: [
            {
                label: 'Accepted',
                data: acceptedPercent,
                backgroundColor: '#50c878',
                 barThickness: 12,
            },
            {
                label: 'Rejected',
                data: rejectedPercent,
                backgroundColor: '#B22222',
                 barThickness: 12,
            },
            {
                label: 'UnderReview',
                data: underReviewPercent,
                backgroundColor: '#EFCC00',
                 barThickness: 12,
            }
            ]
        });
        } 
        else if(response.status === 404)
            {
           setErrorSnackbarOpen(true);
           setSnackbarErrorMessage(response.data.message)
            }
            else if(response.status === 400){
                setErrorSnackbarOpen(true);
           setSnackbarErrorMessage(response.data.message)
            }
            else if(response.status === 500){
                setErrorSnackbarOpen(true);
           setSnackbarErrorMessage(response.data.message)
            }
            else{
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage('Some error occured, please try again later');
            }
       
    
   
    }catch (error) {
        console.error("Error fetching monthly data:", error);
        }
    };
    return (
        <div>
  <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="success"
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
      >
        <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="error"
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
      </Snackbar>
      <h6 className='pt-3 pb-3 px-3 bg-dark text-light'>Vendor Monthly Compliance</h6>
      <section className='pt-3 pb-2 px-2 d-flex float-end'>
       
<select className='p-1' value={selectedVendor} onChange={handleVendorChange} style={{borderRadius:'4px',width:'200px',border:'1px solid lightgray',maxWidth:'250px'}}>
              <option value="select">Select Vendor</option>
              {vendorOptions && vendorOptions.map(vendor => (
                  <option key={vendor.vendorId} value={vendor.vendorId}>
                      {vendor.vendorCompanyName}, {vendor.vendorStateId}
                  </option>
              ))}
          </select>
          <select  className='p-1 mx-2' onChange={handleQuarterChange} style={{border:'1px solid lightgray',borderRadius:'4px'}} value={selectedQuarter}>
                <option value="">All Months</option> 
                <option value="Q1">Q1</option>
                <option value="Q2">Q2</option>
                <option value="Q3">Q3</option>
                <option value="Q4">Q4</option>
                </select>
          <select className='p-1' style={{border:'1px solid lightgray',borderRadius:'4px'}} value={selectedYear || ''} onChange={handleYearChange} >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
               </section>
                    <div className="bg-light" style={{border:'1px solid lightgray'}}>
                            <Bar
                          
                                data={chartData}
                                options={{
                                responsive: true,
                                plugins: {
                                    legend: { display:false},
                                    tooltip: { enabled: true }
                                },
                                scales: {
                                    x: {
                                    title: { display: true },
                                    ticks: { color: 'gray' },
                                    borderColor: '#1c1c1c',
                                    borderWidth: 2,
                                    grid: { display: false },
                                    
                                   
                                
                                    },
                                    y: {
                                    title: { display: true},
                                    min: 0,
                                    max: 100,
                                   
                                    ticks: {  stepSize: 15, color: 'gray', callback: value => value + '%' },
                                    borderColor: '#1c1c1c',
                                    borderWidth: 2,
                                    grid: { display: true }
                                    }
                                }
                                }}
                            />
                            </div>
                            <section className="d-flex mt-4 justify-content-around bg-dark">
                                <div className='justify-content-center align-items-center'>
                               <h6 className="p-2 text-center" style={{color:"#eeee",fontSize:'14px'}}>LCR Report</h6>
                               <button className='mb-3 btn btn-danger btn-sm' onClick={handleOpenDialog}>View Report</button>
                               </div>
                               <div className='vr text-white'></div>
                               <div>
                               <h6 className='pt-2 pb-2  text-center' style={{color:"#eeee",fontSize:'14px'}}>Vendor Monthly Compliance Report</h6>
                               <select className="p-1 bg-dark text-light" value={selectedMonth || ''} onChange={handleMonthChange} style={{border:'1px solid lightgray',borderRadius:'4px'}}>
                        <option value="">Select Month</option>
                        {months.map((month) => (
                            <option key={month.value} value={month.value}>
                                {month.name}
                            </option>
                        ))}
                    </select>
                               <select className="p-1 mx-2 bg-dark text-light" value={selectedYearForReport || ''} onChange={handleYearChanges} style={{border:'1px solid lightgray',borderRadius:'4px'}}>
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                <button className='btn btn-danger btn-sm' onClick={handleOpenDialogComplianceReport}>View Report</button>
              
                               </div>

                            </section>
                            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Export Contractor Data to Excel</span>
            <Button onClick={handleCloseDialog} color="error">
              Close
            </Button>
          </div>
        </DialogTitle>
        
        <DialogContent>
          <LcrReport />
        </DialogContent>
      </Dialog>


      
      <Dialog open={openDialogComplianceReport} onClose={handleCloseDialogComplianceReport} maxWidth="md" fullWidth>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Export Contractor Data to Excel</span>
            <Button onClick={handleCloseDialogComplianceReport} color="error">
              Close
            </Button>
          </div>
        </DialogTitle>
        
        <DialogContent>
            {selectedMonth && selectedYearForReport ? (
        <MonthlyComplianceReport month={selectedMonth} year={selectedYearForReport}/>
            ) : (
                <div style={{ color: 'red', textAlign: 'center' }}>
                <p>Please select both a month and a year to view the report.</p>
              </div>
            )}
        </DialogContent>
      </Dialog>
                           
        </div>

    )

}
export default VendorsMonthlyData;

