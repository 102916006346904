export const COLORS = {
    primary: '#007bff',
    secondary: '#dbd7d2',
    success: '#50c878',
    danger: '#FAA0A0',
    warning: '#fff44f',
    info: '#17a2b8',
    light: 'white',
    dark: '#333333',
    bggray:'#d5d2d2',
    bgRed:'#B22222',
    ivory:'#FFFFF0',  
    red:'#D22B2B'
  };

  