import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../Services/api';

import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';






const statesInIndia = [
  'Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh',
  'Jharkhand','Karnataka', 'Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland',
  'Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal',
  'Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli and Daman and Diu','Delhi','Ladakh','Lakshadweep','Puducherry'
];

export const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [editingClient, setEditingClient] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [clientFormData, setClientFormData] = useState({
    companyId: "",
    principleEmployer: "",
    stateId: "",
    placeName: "",
    pinCode:"",
    companyAddress: "",
    authorisedPersonName: "",
    companyPhoneNumber: "",
    companyEmailId: "",
    companyPhoneNumber1: "",
    companyEmailId1: "",
    clientName: "",
    clientContactPersonName: "",
    clientContactPersonPhoneNumber: "",
    clientContactPersonEmailId: "",
    clientContactPersonPhoneNumber1: "",
    clientContactPersonEmailId1: "",
    startDate: "",
    endDate: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[6789]\d{9}$/;
    const pinCodePattern = /^(?!000000$)[0-9]{6}$/;
    
    if (!clientFormData.principleEmployer) newErrors.principleEmployer = 'Company Name is required';
    if (!clientFormData.stateId) newErrors.stateId = 'State is required';
    if (!clientFormData.placeName) newErrors.placeName = 'Place is required';
    if (!clientFormData.companyAddress) newErrors.companyAddress = 'Address is required';
    if (!clientFormData.authorisedPersonName) newErrors.authorisedPersonName = 'Conatct Person name is required';

    if (!emailPattern.test(clientFormData.companyEmailId)) {
      newErrors.companyEmailId = clientFormData.companyEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    // if (!emailPattern.test(clientFormData.companyEmailId1)) {
    //   newErrors.companyEmailId1 = clientFormData.companyEmailId1 ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    // }
    if(!pinCodePattern.test(clientFormData.pinCode)){
      newErrors.pinCode = clientFormData.pinCode ? 'Invalid Pin code format(245678)' : 'Pin code required';
    }
    if (!phonePattern.test(clientFormData.companyPhoneNumber)) {
      newErrors.companyPhoneNumber = clientFormData.companyPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    // if (!phonePattern.test(clientFormData.companyPhoneNumber1)) {
    //   newErrors.companyPhoneNumber1 = clientFormData.companyPhoneNumber1 ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    // }
    if (!clientFormData.clientName) newErrors.clientName = 'Contractor Name  is required';
    if (!clientFormData.clientContactPersonName) newErrors.clientContactPersonName = 'Contact Person Name is required';
    if (!emailPattern.test(clientFormData.clientContactPersonEmailId)) {
      newErrors.clientContactPersonEmailId = clientFormData.clientContactPersonEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(clientFormData.clientContactPersonPhoneNumber)) {
      newErrors.clientContactPersonPhoneNumber = clientFormData.clientContactPersonPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (clientFormData.clientContactPersonEmailId1 && !emailPattern.test(clientFormData.clientContactPersonEmailId1)) {
      newErrors.clientContactPersonEmailId1 = 'Invalid email address (e.g., text@email.com)';
    }
    
    if (clientFormData.clientContactPersonPhoneNumber1 && !phonePattern.test(clientFormData.clientContactPersonPhoneNumber1)) {
      newErrors.clientContactPersonPhoneNumber1 = 'Invalid phone number. Number should not start with zero)';
    }
    // if (!emailPattern.test(clientFormData.clientContactPersonEmailId1)) {
    //   newErrors.clientContactPersonEmailId1 = clientFormData.clientContactPersonEmailId1 ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    // }
    // if (!phonePattern.test(clientFormData.clientContactPersonPhoneNumber1)) {
    //   newErrors.clientContactPersonPhoneNumber1 = clientFormData.clientContactPersonPhoneNumber1 ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    // }
    if (!clientFormData.startDate) newErrors.startDate = 'Contract Start Date is required';
    if (!clientFormData.endDate) newErrors.endDate = 'Contract End Date is required';

    const selectField = clientFormData.stateId;
    if (!selectField) {
      newErrors.selectField = 'Please select State';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const fetchClients = async () => {
    try {
      const response = await api.client.getClients();
      setClients(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setLoading(false);
    }
  };

  const handleEditClick = (client) => {
    setEditingClient(client.companyId);
    setClientFormData({ ...client });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setClientFormData({ ...clientFormData, [name]: value });
  };

  const handleSave = async () => {
    if(validateForm()){
    try {
      const response = await api.client.editClient(clientFormData.companyId, clientFormData);
      if (response.status === 200) {
       
        setSnackbarOpen(true);
        setSnackbarMessage("Updated successfully!")
        setEditingClient(null);
        fetchClients();
      } else {
        
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Error in submitting");

      }
    } catch (error) {
      console.error('Error saving data:', error);
     
    }
  }
  };

  const handleCancel = () => {
    setEditingClient(null);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <>
     <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>
        {loading ? (
          <div>
              <h4 className="text-center mt-5">Loading......</h4>
          </div>
        ) : 
    clients && clients.length > 0 ? (
    <div className="m-4">
      <h5>Client List <i class="bi bi-arrow-right-square-fill" ></i></h5>
     
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{backgroundColor:'black',color:'white'}}>
                <th>Principal Employer Details</th>
                <th>Contractor Details</th>
                <th>Action</th>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client) => (
                <TableRow key={client.companyId}>
                  <TableCell>
                  <div style={{fontSize:"14px",fontWeight:'bold'}}>{client.principleEmployer}</div>
                
                    <div style={{color:'gray'}} >Address: {client.companyAddress}, {client.placeName} {client.stateId}- {client.pinCode}</div>
                    
                    <div style={{color:'gray'}} >Contact Person: {client.authorisedPersonName}</div>
                    <div style={{color:'gray'}} >Email : {client.companyEmailId} {client.companyEmailId1 && `, ${client.companyEmailId1}`}</div>
                    <div style={{color:'gray'}} >Phone: {client.companyPhoneNumber} {client.companyPhoneNumber1 && `, ${client.companyPhoneNumber1}`}</div>
                  </TableCell>
                  <TableCell>
                    <div style={{fontSize:"14px",fontWeight:'bold'}}>{client.clientName}</div>
                    <div style={{color:'gray'}} >Contact Person: {client.clientContactPersonName}</div>
                    <div style={{color:'gray'}} >Phone: {client.clientContactPersonPhoneNumber} {client.clientContactPersonPhoneNumber1 && `, ${client.clientContactPersonPhoneNumber1}`}</div>
                    <div style={{color:'gray'}} >Email: {client.clientContactPersonEmailId} {client.clientContactPersonEmailId1 && `, ${client.clientContactPersonEmailId1}`}</div>
                    <div style={{color:'gray'}} >Contract Start Date: {client.startDate?.slice(0,10).split('-').reverse().join('-')}</div>
                    <div style={{color:'gray'}} >Contract End Date: {client.endDate?.slice(0,10).split('-').reverse().join('-')}</div>
                  </TableCell>
                  <TableCell>
                  <button className="mx-1 btn btn-sm btn-primary" onClick={() => handleEditClick(client)}>Edit</button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
    ) : (
      <div className='mt-4'>
        <p className="text-center">No Clients Found !! <Link to="/createcompany">Click here</Link> to Create</p>
      </div>
    )}

      {/* Edit Dialog Popup */}
      <Dialog open={editingClient !== null} onClose={handleCancel} fullWidth maxWidth="md">
      <h6 style={{textAlign:'center',color:'white',backgroundColor:"#1C1C1C",padding:'24px',letterSpacing:'0.5px'}}>EDIT CLIENT DETAILS</h6>
     
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Principal Employer"
                name="principleEmployer"
                value={clientFormData.principleEmployer}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.principleEmployer}
                helperText={errors.principleEmployer}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>State</InputLabel>
                <Select
                  name="stateId"
                  value={clientFormData.stateId}
                  onChange={handleFormChange}
                  error={!!errors.stateId}
                >
                  {statesInIndia.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.selectField && (
            <div className="pb-3 px-5" style={{ color: 'red', fontSize: '14px' }}>
              {errors.selectField}
            </div>
          )}
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Company Address"
                name="companyAddress"
                value={clientFormData.companyAddress}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.companyAddress}
                helperText={errors.companyAddress}
              />
              <TextField
                fullWidth
                label="Place"
                name="placeName"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.placeName}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.placeName}
                helperText={errors.placeName}
              />
              <TextField
                fullWidth
                label="Pin Code"
                name="pinCode"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.pinCode}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.pinCode}
                helperText={errors.pinCode}
              />
              <TextField
                fullWidth
                label="Authorised Person Name"
                name="authorisedPersonName"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.authorisedPersonName}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.authorisedPersonName}
              helperText={errors.authorisedPersonName}
              />
              <TextField
                fullWidth
                label="Email"
                name="companyEmailId"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.companyEmailId}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.companyEmailId}
              helperText={errors.companyEmailId}
              />
             
              <TextField
                fullWidth
                label="Alternative Email"
                name="companyEmailId1"
                InputLabelProps={{ shrink: true }}
                type="email"
                value={clientFormData.companyEmailId1}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.companyEmailId1}
              helperText={errors.companyEmailId1}
              />
            
              <TextField
                fullWidth
                label="Phone Number"
                name="companyPhoneNumber"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.companyPhoneNumber}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.companyPhoneNumber}
              helperText={errors.companyPhoneNumber}
              />
             
               <TextField
                fullWidth
                label="Alternative PhoneNumber"
                name="companyPhoneNumber1"
                InputLabelProps={{ shrink: true }}
                type="number"
                value={clientFormData.companyPhoneNumber1}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.companyPhoneNumber1}
              helperText={errors.companyPhoneNumber1}
              />
              
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Contractor Name"
                name="clientName"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.clientName}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.clientName}
              helperText={errors.clientName}
              />
              <TextField
                fullWidth
                label="Contact Person"
                name="clientContactPersonName"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.clientContactPersonName}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.clientContactPersonName}
              helperText={errors.clientContactPersonName}
              />
         
              <TextField
                fullWidth
                label="Email"
                name="clientContactPersonEmailId"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.clientContactPersonEmailId}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.clientContactPersonEmailId}
              helperText={errors.clientContactPersonEmailId}
              />
        
        
               <TextField
                fullWidth
                label="Alternative Email"
                name="clientContactPersonEmailId1"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.clientContactPersonEmailId1}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.clientContactPersonEmailId1}
              helperText={errors.clientContactPersonEmailId1}
              />
        
              <TextField
                fullWidth
                label="Phone Number"
                name="clientContactPersonPhoneNumber"
                value={clientFormData.clientContactPersonPhoneNumber}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                error={!!errors.clientContactPersonPhoneNumber}
              helperText={errors.clientContactPersonPhoneNumber}
              />
              
               <TextField
                fullWidth
                label="Alternative PhoneNumber"
                name="clientContactPersonPhoneNumber1"
                value={clientFormData.clientContactPersonPhoneNumber1}
                onChange={handleFormChange}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                error={!!errors.clientContactPersonPhoneNumber1}
              helperText={errors.clientContactPersonPhoneNumber1}
              />
            
              <TextField
                fullWidth
                type="date"
                label="Contract Start Date"
                name="startDate"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.startDate ? clientFormData.startDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.startDate}
              helperText={errors.startDate}
              />
              <TextField
                fullWidth
                type="date"
                label="Contract End Date"
                name="endDate"
                InputLabelProps={{ shrink: true }}
                value={clientFormData.endDate ? clientFormData.endDate.slice(0, 10) : ''}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.endDate}
              helperText={errors.endDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
       
        <div className='mx-4 mb-3' style={{display:'flex',justifyContent:'space-between'}}>
          <button className="btn btn-md btn-outline-danger" onClick={handleCancel} >
            Cancel
          </button>
          <button className="btn btn-md btn-outline-success" onClick={handleSave} disabled={!validateForm} >
            Save
          </button>
          </div>
      
      </Dialog>
   
    </>
  );
};
