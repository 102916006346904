import axios from "axios";
import setupInterceptorsTo from "./AxiosInterceptor";


// const BASE_URL = process.env.REACT_APP_LOCAL_URL;
setupInterceptorsTo(axios);


const api = {

    login : {
         clientLogin: (data) => {
            return axios.post('https://www.grcpulse.in/api/companyMasterProfile/login', data);
         },
         adminLogin: (data) => {
            return axios.post('https://www.grcpulse.in/api/admin/AdminLogin',data);
        },
        assessorLogin:(data)=>{
            return axios.post('https://www.grcpulse.in/api/Assessor/assessor-login',data);
        },
        vendorLogin: (data) => {
            return axios.post('https://www.grcpulse.in/api/vendorMasterProfile/login', data);
        },
    },

    masterData : {
        createMasterData: (data) => {
            return axios.post(`https://www.grcpulse.in/api/companyMasterData/newMasterData`,data);    
            },
           
            getClientMasterData : (id) => {
                return axios.get(`https://www.grcpulse.in/api/companyMasterData/clientMasterData/${id?id:'id'}`)
            },
            getVendorMasterData: (id) => {
                return axios.get(`https://www.grcpulse.in/api/companyMasterData/vendorDetails/${id?id:'id'}`);
            },
            updateMasterData: (data) => {
                return axios.put(`https://www.grcpulse.in/api/companyMasterData/updateMasterData`,data);
            }
          
    },

    client : {
        getClient: (id) => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/findOne/${id ? id : 'id'}`);
        },
        editClient: (id,data) => {
           return axios.put(`https://www.grcpulse.in/api/companyMasterProfile/updateOne/${id?id:'id'}`,data);
        },
        createClient: (data) => {
            return axios.post(`https://www.grcpulse.in/api/companyMasterProfile/newCompanyProfile`,data);
               },
               getClients: () => {
                return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/comapanyNames`);
            },
            getAllVendors: (id,data) => {
                return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/getallvendors/${id?id:'id'}`,data);
            },
            getAvgDataVendors: (id,year) => {
                return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/allvendors-monthly-data/${id?id:'id'}`,{params: {year}});
            },
            getLcrReport: (id,data) => {
                return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/getallvendors/${id?id:'id'}`,data);
            },
            getComplianceReport: (id, month, year) => {
                return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/monthly-compliance-report/${id?id:":id"}`, {params: {month, year}})
            }
                
    },



    assessor:{
      
        getassessor: (id,data) => {
            return axios.get(`https://www.grcpulse.in/api/companyMasterProfile/masterdata/${id?id:'id'}`, data);
        },
        getAssessors: () => {
            return axios.get(`https://www.grcpulse.in/api/Assessor/allassessors`);
        },
        createAssessor: (data) => {
            return axios.post(`https://www.grcpulse.in/api/Assessor/create-assessor`,data);
                },
        findOne: (id,data) => {
            return axios.get(`https://www.grcpulse.in/api/Assessor/assessor-details/${id?id:"id"}`,data);
        },
        editAssessor: (id,data) => {
            return axios.put(`https://www.grcpulse.in/api/Assessor/edit-assessor/${id?id:'id'}`,data);
     },
        
    },

    vendor:{
        createVendor: (data) => {
            return axios.post(`https://www.grcpulse.in/api/vendorMasterProfile/newVendorProfile`,data);
        },
        createVendorMonthlyData: (data) => {
            return axios.post(`https://www.grcpulse.in/api/monthlydata/createmonthlydata`,data);
        },
        findvendor: () => {
            return axios.get('https://www.grcpulse.in/api/vendorMasterProfile/findOne');
        },
        editVendor: (id,data) => {
            return axios.put(`https://www.grcpulse.in/api/Assessor/edit-vendor_details/${id?id:"id"}`,data);
        },
        getVendors: (id) => {
            return axios.get(`https://www.grcpulse.in/api/Assessor/vendor's-details/${id?id:"id"}`);
        },
        updateOne: (id,data) => {
            return axios.put(`https://www.grcpulse.in/api/vendorMasterProfile/updateOne/${id?id:":id"}`,data)
        },
       fetchVendorDetailsById: (id) => {
           return axios.get(`https://www.grcpulse.in/api/vendorMasterProfile/vendorDetails/${id?id:":id"}`);
       },
      getMonthlyData: (id, month, year) => {
           return axios.get(`https://www.grcpulse.in/api/monthlydata/getdata/${id?id:":id"}`, {params: {month, year}});
      },
      updateMonthlyData: (data) => {
          return axios.put(`https://www.grcpulse.in/api/monthlydata/update`,data);
      },
     deleteVendors : (id) => {
            return axios.put(`https://www.grcpulse.in/api/vendorMasterProfile/delete/${id?id:":id"}`);
     },
    },

    contactUs: {
        onContactUsApi: (data) => {
            return axios.post('https://www.grcpulse.in/api/webcontactus/contactus', data)
        }
    }
};

export default api;