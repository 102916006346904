import React from 'react'
import Navbar from '../layout/NavBar'
import Footer from '../layout/Footer'

const PrivacyAndPolicy = () => {
  return (
    <>
     <Navbar/> 
    <div className='w-100 h-auto p-12'>
      <h1 className='text-center text-4xl fw-bolder mt-5'>PRIVACY POLICY</h1>
      <section>
        <p className='mt-4 text-xl'>GRC@Pulse Application is designed and developed by PEOPLE PROCESS TECK PRIVATE LIMITED, a Private Limited Company incorporated under the laws of India, having its registered office at No. B-404, Hoysala Habitat, Doddaballapur Road, Yelahanka, Bangalore, Karnataka – 560119 (hereinafter referred to as “PPTeck”, “Company”, “We”, “Our” or “Us”). At PPTECK your i.e., Clients and Vendors (defined below) privacy is of great importance to us. We understand that you entrust us with certain Personal Information (defined below) to help us provide Services through our Application. In exchange for your trust, you expect and deserve our commitment to treat your information with respect and in accordance with the terms of this privacy policy (“Privacy Policy”).</p>
        <p className='mt-3 text-xl'>In this Privacy Policy (“Policy”), we describe the information that we collect about you when you use and access <a href='https://www.grcpulse.in'className='text-primary'>https://www.grcpulse.in</a> (“Website”) and the “GRC@Pulse” technology Application (“Application”) accessible through desktops, mobile phones, smart phones, and tablets which includes computer software, associated media, collaterals, and online or electronic documentation of both Clients (defined below) and Vendors (defined below) registered on this application and how we use and disclose that information.</p>
        <p className='mt-3 text-xl'>If you have any questions or comments about the Privacy Policy, please contact us at support@grcpulse.in. This Policy is incorporated into and is subject to the Company’s Terms and Conditions for Clients and Vendors, which can be accessed at <a href='https://www.grcpulse.in'className='text-primary'>https://www.grcpulse.in</a>(hereinafter “Terms”). Your use of the website and application and Services offered thereunder and any personal information you provide us therein remains subject to the terms of the Privacy Policy and Terms.</p>
        <p className='mt-3 text-xl'>BY ACCESSING OR USING THE WEBSITE, APPLICATION AND/OR SERVICES PROVIDED THEREIN, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THIS PRIVACY POLICY AND OUR TERMS. IF YOU DO NOT AGREE TO THE TERMS OR PRIVACY POLICY DO NOT USE THE SERVICES.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>1. YOUR PRIVACY – OUR COMMITMENT</h1>
        <p className='mt-3 fs-5'>We recognize the importance of your personal privacy. Please read the following Policy to understand how your Personal Information will be treated as you make full use of the Services offered through the Application. Except as disclosed in this Privacy Policy or as otherwise authorized by you, we will not share your Personal Information with third parties for their marketing purposes. Your trust and confidence are our highest priority.</p>

        <h1 className='fw-bold mt-5 fs-4'>2. CONSENT</h1>
        <p className='mt-3 fs-5'>By using our Services, you understand and accept this Privacy Policy along with any changes as may be made from time to time and thereby expressly consent to our collection, storage, processing, usage, disclosure, and transfer of Your Information in accordance with this Privacy Policy. If you are not agreeable to this, please do not use the Application or the Services. You acknowledge that You are providing Your Information out of Your free will. All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity/genuineness/misrepresentation/fraud/negligence, etc. of the information disclosed shall lie on the Company nor will the Company in any way be fully responsible to verify any information obtained from You.</p>
        <p className='mt-3 fs-5'>You have the option not to provide us the Information sought to be collected. If You do not provide us Your Information or if You withdraw the consent at any point in time, we will not be able to offer the Services available on the Application.</p>
       
        <h1 className='fw-bold fs-4 mt-5'>3. DEFINITIONS</h1>

          <p className='mt-3 fs-5'>
              As used in this Privacy Policy, the following terms shall have the meaning set forth below:
          </p>

          <p className='mt-3 fs-5'>
              <strong>(i) Application Content:</strong> All material, content, and information made available on the Application including but not limited to written text, graphics, images, logos, trademarks, audio/video material, or any other forms of expression.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(ii) Assessor:</strong> An individual who is a domain expert with great experience in their area of expertise. They assess the Compliance requirements and provide their observations.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(iii) Client:</strong> A registered user who is seeking to utilize the services provided by the Application on behalf of an organization that wants to engage.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(iv) Client / Vendor Information:</strong> Information pertaining to the Client’s / Vendor’s name, details about the organization, designation, industry and department, number of employees, location, GSTIN and PAN details, phone number, email address, sub-user information, and such other information/documents that the Company may require to be submitted from time to time.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(v) Confidential Information:</strong> All information and materials furnished by a party which:  
              (a) if in written format is marked as confidential,  
              (b) if disclosed verbally is noted as confidential at the time of disclosure, or  
              (c) in the absence of (a) or (b), is information that a reasonable party would deem to be non-public and confidential.  
              This includes but is not limited to Client Information, Vendor Information, technical processes, business plans, marketing data, software specifications, trade secrets, source code, and other intellectual property related to this Agreement.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(vi) Effective Date:</strong> The date on which You accept these Terms and Privacy Policy by clicking ‘Sign Up,’ ‘Sign In,’ ‘Log In,’ or ‘I Accept’ or enter into an agreement with GRC@Pulse.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(vii) Registered User:</strong> Any person who signs up and registers by creating an Account as a Client / Vendor / Assessor on the GRC@Pulse through their email ID for availing the Services.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(viii) Services:</strong> The service of functioning as an online facilitator on a digital Application that connects Clients with Vendors to achieve their business goals as per their industry and domain requirements. This includes but is not limited to the Services stipulated under Clause 2(B) of the Terms and any other services provided by the Registered Users and the Company.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(ix) Vendor:</strong> A Registered User who is interested in using this application to assess compliance status and present it to the Client.
          </p>

          <p className='mt-3 fs-5'>
              <strong>(x) Other Terms:</strong> All other capitalized terms shall have the meaning ascribed to them in the Other Terms.
          </p>

      </section>
      
      <section className='mt-5'>
      <h1 className='fw-bold fs-4'>4. INFORMATION WE COLLECT</h1>

        <h2 className='fw-bold mt-5 fs-4'>4.1 Information Provided to Us:</h2>

        <p className='mt-3 fs-5'><strong>Personal Information:</strong></p>

        <p className='mt-3 fs-5'>
            We receive and store Personal Information (i.e., information that, whether on its own or in combination with other data, may be used to readily identify or contact you), such as Client/Vendor Information provided by you when you register and use the application to create a profile. You always have the option to not provide information by choosing not to use a particular Service or feature. Importantly, we only collect Personal Information about you that we consider necessary for achieving this purpose and communicating with you about the Services being offered.
        </p>

        <p className='mt-3 fs-5'>
            By continuing to use our Application, you are deemed to have read the Privacy Policy and understood the purpose for which your Personal or Non-Personal Information is being collected and how it shall be used, thereby granting your express consent to such purpose and use. If, at a later date, you wish to withdraw this consent, please send us an email at <a href="mailto:support@grcpulse.in">support@grcpulse.in</a>.
        </p>

        <h2 className='fw-bold mt-5 fs-4'>4.2 Information Collected Automatically</h2>

        <p className='mt-3 fs-5'><strong>Non-Personal Information:</strong></p>

        <p className='mt-3 fs-5'>
            When you use and access the Website and/or Application, we automatically receive and record information on our server logs from your browser or mobile device, which is non-personal information that does not enable the Application to identify you personally. Non-personal information includes:
        </p>

        <ul className='mt-3 fs-5'>
            <li>Hardware model, browser type, IP address, and operating system version.</li>
            <li>Unique device identifiers, location data, computer browser information, and ISP details.</li>
            <li>Technical information such as the URL or advertisement that referred you to the Application.</li>
            <li>Search terms entered into a search engine that led you to the Application.</li>
            <li>Pages visited within the Application, links clicked, and duration of visits.</li>
            <li>General statistics and interaction data related to your use of the Website and/or Application.</li>
        </ul>

        <p className='mt-3 fs-5'>
            We may also collect information about how your device interacts with our Website and/or Application, including the pages accessed and links clicked. We treat this data as Non-Personal Information, except where applicable law requires otherwise.
        </p>

        <h2 className='fw-bold mt-5 fs-4'>4.3 Data Collection Devices</h2>

        <p className='mt-3 fs-5'><strong>Cookies:</strong></p>

        <p className='mt-3 fs-5'>
            We use data collection devices such as “cookies” on certain pages of the Website and Application to help analyze web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on your computer’s hard drive that assist us in providing the Services. We offer certain features that are only available through the use of cookies. 
        </p>

        <p className='mt-3 fs-5'>
            We also use cookies to allow you to enter your password less frequently during a session. Additionally, cookies help us provide information targeted to your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session. 
        </p>

        <p className='mt-3 fs-5'>
            You are always free to decline our cookies if your browser permits, although this may prevent you from using certain features of the Website, and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter third-party “cookies” or similar devices on certain pages of the Website. We do not control the use of cookies by third parties.
        </p>

        <h2 className='fw-bold mt-5 fs-4'>4.4 Third-Party Services</h2>

        <p className='mt-3 fs-5'>
            We also provide an option to create an account or register on the Application using your username and password from Third-Party Applications. By authorizing us to connect with Third-Party Applications, you are hereby allowing us to access and store the information that such Applications make available to us, and you also consent to its use and disclosure in accordance with this Privacy Policy. 
        </p>

        <p className='mt-3 fs-5'>
            It is your responsibility to review the privacy settings of such Third-Party Applications and to control and modify the information that is available to us through these services.
        </p>

      </section>

      <section className='mt-5'>
      <h1 className='fw-bold fs-4'>5. USE OF INFORMATION COLLECTED</h1>

        <p className='mt-3 fs-5'>
            Use of Personal Information about You is an integral part of our business. We neither rent nor sell your Personal Information to anyone. We may share your Personal Information only as described in this Privacy Policy and related documents.
        </p>

        <h2 className='fw-bold mt-5 fs-4'>5.1 Use of Personal/Non-Personal Information:</h2>

        <p className='mt-3 fs-5'><strong>Personal Information:</strong> We will use and store your Personal Information to:</p>

        <ul className='mt-3 fs-5'>
            <li>Deliver Services to you, enable you to create an Account, register for our Services, verify your identity and credentials, connect you with related Vendors, and otherwise enable you to use our Website, Application, and Services.</li>
            <li>Maintain and improve our Services, including facilitating payments, sending alerts regarding compliance status of appropriate Vendors, developing new features, and providing customer support.</li>
            <li>Develop safety features and authenticate all Registered Users.</li>
            <li>Send updates and administrative messages.</li>
            <li>Market the Services and for internal operational and administrative purposes.</li>
            <li>Process billing and payments, including sharing details with secured third-party payment gateways in connection with Engagement and/or the Application Services.</li>
            <li>Store personal correspondence, such as emails or letters, or information from other Registered Users or third parties regarding your activities on the Application.</li>
            <li>Improve marketing and promotional efforts.</li>
            <li>Analyze Website and/or Application usage.</li>
            <li>Enhance Application content and Service offerings.</li>
            <li>Customize the Application content, layout, and Services.</li>
            <li>Provide you with a smooth, efficient, safe, and customized experience while using the Application.</li>
            <li>Allow you to receive quality customer care services.</li>
            <li>Use your Personal Information to resolve disputes, troubleshoot problems, measure consumer interest in Services, inform you about new services and updates, customize your experience, detect and protect against fraud or criminal activity, enforce our Terms, and as otherwise described to you at the time of collection.</li>
        </ul>

        <h2 className='fw-bold mt-5 fs-4'>5.2 Aggregate Information</h2>

        <p className='mt-3 fs-5'>
            We will also use Personal Information, Non-Personal Information, and other data to create aggregated information for analytical and other purposes. We use this information for internal research on demographics, interests, site usage, and behavior to better understand, protect, and serve You. This information is compiled and analyzed on an aggregated basis. Unless otherwise stated in this Privacy Policy, we only use this data in aggregate form.
        </p>

        <h2 className='fw-bold mt-5 fs-4'>5.3 Non-Personally Identifiable Information</h2>

        <p className='mt-3 fs-5'>
            We may freely use Non-Personally Identifiable Information in connection with the Services offered through the Website and Application, including:
        </p>

        <ul className='mt-3 fs-5'>
            <li>To administer the Services being offered.</li>
            <li>To understand how well the Service is working.</li>
            <li>To market the Services and other products.</li>
            <li>To develop aggregate, statistical information on Service usage.</li>
            <li>To improve the Service by diagnosing issues and enhancing the design and layout of the Website and/or Application.</li>
        </ul>

        <h2 className='fw-bold mt-5 fs-4'>Email/SMS Communications</h2>

        <p className='mt-3 fs-5'>
            While creating an account, you must provide your email address and telephone number, to which we will send administrative and automatic alert emails, promotional emails, and text messages regarding updates and/or new Services available on the Application. 
        </p>

        <p className='mt-3 fs-5'>
            If you wish to opt out of promotional emails and text messages, you may do so by following the “unsubscribe” instructions in the email, the “opt-out” instructions in the text message, or by editing your account settings. All Registered Users receive administrative emails and text messages. From time to time, we may reveal general statistical information about our Application and Services through emails and messages.
        </p>

      </section>

        <section className='mt-5'>
        <h1 className='fw-bold fs-4'>6. OUR DISCLOSURE OF YOUR INFORMATION</h1>
        <p className='mt-3 fs-5'>The following are some circumstances under which and people to whom we would share your Information with from time-to-time:</p>
        <h2 className='fw-bold mt-5 fs-4'>6.1 Disclosure of Personal Information:</h2>
        <p className='mt-3 fs-5'>We do not disclose your personal information such as email address, contact details, telephone number and mailing address to any third party for marketing purposes. Except as otherwise provided under this Privacy Policy, we will disclose your Personal Information to third parties only in the following limited circumstances:</p>
        <ul className='mt-3 fs-5'>
          <li>(i) if and when Vendors use the Service through the Website and/or Application, we may disclose your Information to Clients, regarding their statutory documents submitted as part of the compliance assessment process. on the Application, etc.</li>
          <li>(ii) with third parties and third party service providers, like payment gateways, payment aggregator and other payment service providers, promotion and marketing partners, consultants, research firms, business partners, for supporting Our business, to provide you Services through the Application, for analyzing data and usage of the Service through the Website and Application, processing credit/debit card payments, operating the Service or providing support and maintenance services for the same, or providing customer service. The Company will not use your financial information for any purpose other than to complete a payment transaction with you.</li>
          <li>(iii) We and our affiliates will share/transfer some or all of the collected information (personal or otherwise) with another business entity should We (or our assets) plan to merge with, or be acquired by that business entity, or reorganization, amalgamation, restructuring of business, in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction or proceeding. Should such a transaction occur, that other business entity (or the new combined entity) will be contractually bound to comply with the terms of this Privacy Policy.</li>
          <li>(iv) to any law enforcement, judicial authority, governmental or regulatory authority, to the extent required by law or legal process.</li>
          <li>(v) if in our reasonable discretion, such use or disclosure is necessary to enforce or protect our legal rights or to protect authorized third parties.</li>
          <li>(vi) when we have your consent to otherwise share the information.</li>
        </ul>
        <h2 className='fw-bold mt-5 fs-4'>6.2 Non-Personally Identifiable Information:</h2>
        <p className='mt-3 fs-5'>We may disclose other Non-Personally Identifiable Information to third party advertisers and advertising agencies, partners, and other parties in order for us and such third parties to analyze (a) the performance of, to operate and improve the Services offered through the Website and Application and (b) the behavior of the Registered Users. We may also disclose, use, or publish this information for promoting the Services offered under the Website and/or Application. These third parties are subject to confidentiality agreements with us and other legal restrictions that prohibit their use of the information we provide them for any other purpose except to facilitate the specific outsourced operation unless you have explicitly agreed or given your prior permission to them for additional uses.</p>
        <h2 className='fw-bold mt-5 fs-4'>6.3 Aggregate Information:</h2>
        <p className='mt-3 fs-5'>Since the aggregate information we collect does not specifically identify you, it may be shared with any number of parties. We may disclose anonymous, aggregated information to third parties such as potential business partners, advertisers, and funding sources, in order to describe our business and operations.</p>
        <h2 className='fw-bold mt-5 fs-4'>6.4 Network Operators:</h2>
        <p className='mt-3 fs-5'>Use of the Service provided through the Website and Application may involve use of the services of third-party telecommunications carriers, internet service providers and service providers (such as the services of the carrier that provides cell phone service to you). Such carriers and service providers are not our contractors, and any information that a carrier collects in connection with your use of the Service provided through the Website and/or Application is not “Personal Information” and is not subject to this Privacy Policy. We are not responsible for the acts or omissions of telecommunications carriers, internet service providers or service providers.</p>
        <h2 className='fw-bold mt-5 fs-4'>6.5 Communications with Us or Through the Service:</h2>
        <p className='mt-3 fs-5'>Any communication or material you transmit to us by email or otherwise, including any data other than Personal Information, questions, comments, suggestions, or feedback, etc., will be treated as, non-confidential and non-proprietary information. Except to the extent expressly covered by this Policy, anything you transmit, post, or upload may be used by us for any purpose, including but not limited to, reproduction, disclosure, transmission, publication, broadcast and posting. Furthermore, you expressly agree that we are free to use any ideas, concepts, know-how, or techniques contained in any communication you send to us without compensation and for any purpose whatsoever, including but not limited to, developing, and marketing services using such information.</p>
        <p className='mt-3 fs-5'>You should be aware that Personal Information which you voluntarily include and transmit online in a publicly accessible blog, chat room, social media Application or otherwise online, or that you share in an open forum such as in personnel panel or survey, may be viewed and used by others without any restrictions. We are unable to control such uses of your Personal Information, and by using such services you assume the risk that the Personal Information provided by you may be viewed and used by third parties for any number of purposes.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>7. ACCESS OR CHANGE YOUR PERSONAL INFORMATION</h1>
        <p className='mt-3 fs-5'>You may review, correct, update, change or delete your information at any time by contacting us at <a className='text-primary' href="mailto:support@grcpulse.in">support@grcpulse.in</a>. To protect your privacy and security, we will verify your identity before granting access or making changes to your Personal Information.</p>
        <p className='mt-3 fs-5'>You may also change your password by directly logging into your Account through the Application.</p>
        <p className='mt-3 fs-5'>Even after you remove information from the Application, copies of that information may remain viewable elsewhere, to the extent it has been shared with others or it was otherwise distributed pursuant to your privacy settings.</p>
        <p className='mt-3 fs-5'>Removed and deleted information may remain on backup media for up to ninety (90) days prior to being deleted from our servers or for a period as required by law.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>8. STORING YOUR INFORMATION</h1>
        <p className='mt-3 fs-5'>Our retention periods for personal data are based on business needs and legal requirements. We retain personal data for as long as is necessary for the processing purpose(s) for which the information was collected, or for tax or legal purposes, and any other permissible, related purpose.</p>
        <p className='mt-3 fs-5'>When personal data is no longer needed, we either irreversibly anonymize the data (and we may further retain and use the anonymized information) or securely destroy the data.</p>
        <p className='mt-3 fs-5'>Upon Termination, PPTECK shall retain the Client / Vendor Information for a period of one hundred and eighty (180) days after any cancellation or withdrawal of the registration.</p>
        <p className='mt-3 fs-5'>We may use the non-personal information provided by the Registered Users for internal analysis and archival purposes.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>9. GOVERNING LAW</h1>
        <p className='mt-3 fs-5'>The Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to the principles of conflict of laws thereunder.</p>
        <p className='mt-3 fs-5'>Any dispute or difference, whether on the interpretation or otherwise, in respect of any Terms or provisions in the Policy hereof shall be referred to an independent arbitrator to be appointed by the Company. Such an arbitrator’s decision shall be final and binding on the parties.</p>
        <p className='mt-3 fs-5'>The arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996, as amended or replaced from time to time. The seat and venue of arbitration shall be Bangalore, India and the language of the arbitration shall be English.</p>
        <p className='mt-3 fs-5'>Subject to the aforesaid, the Courts at Bangalore shall have exclusive jurisdiction over any proceedings arising in respect of the Agreement.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>10. AMENDMENTS TO THE PRIVACY POLICY</h1>
        <p className='mt-3 fs-5'>We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used.</p>
        <p className='mt-3 fs-5'>If we decide to change our Privacy Policy, we will periodically inform you and post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.</p>
        <p className='mt-3 fs-5'>Users are bound by any changes to the Privacy Policy when they continue to use the Application after such changes have been first posted.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>11. SECURITY OF PERSONAL INFORMATION</h1>
        <p className='mt-3 fs-5'>The Company does not itself store Your bank account / payment card account information and does not have direct control over or responsibility for Your bank account / payment card account information. Hence, Company cannot guarantee that transmissions of Your bank account / payment card account information or personal information will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by Company or Company’s third-party service providers.</p>
        <p className='mt-3 fs-5'>Company assumes no liability or responsibility for disclosure of Your information due to any reason, including but not limited to errors in transmission, unauthorized third-party access, or other causes beyond its control.</p>
        <p className='mt-3 fs-5'>The Company is always committed to keep such personal information safe and ensure that such data / information is only transacted over secure websites of approved payment gateways which are digitally encrypted and provide highest possible care.</p>
        <p className='mt-3 fs-5'>Although we shall try our best to protect Your data, we cannot take any guarantee for the security of Your data transmitted through the Application.</p>
        <p className='mt-3 fs-5'>We will take reasonable security measures and procedures, and as specified by applicable law, to maintain appropriate physical, technical and administrative security to help prevent loss, misuse, or unauthorized access, disclosure, or modification of Personal Information.</p>
        <p className='mt-3 fs-5'>While we take these reasonable efforts to safeguard your personal information, you acknowledge and agree that no system or transmission of data over the Internet or any other public network can be guaranteed to be 100% secure.</p>
        <p className='mt-3 fs-5'>You should take steps to protect against unauthorized access to your password, phone, and computer, among other things, by signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private.</p>
        <p className='mt-3 fs-5'>We are not responsible or liable in any manner whatsoever for your account getting hacked or for the unauthorized use of your information or for any lost, stolen, compromised passwords, or for any activity on your Account via unauthorized password activity.</p>
        <p className='mt-3 fs-5'>You agree and undertake that you shall not hold Company liable therefore in any way.</p>
        <p className='mt-3 fs-5'>The use and storage of any information, including without limitation, password, account information, transaction activity and any other information available on your internet access device or computer or mobile phone is at your own risk and responsibility.</p>
        <p className='mt-3 fs-5'>You furthermore acknowledge and accept that Company may not encrypt all content or communications shared on the Application.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>12. GRIEVANCE REDRESSAL MECHANISM</h1>
        <p className='mt-3 fs-5'>In accordance with the (i) Information Technology Act, 2000, the (ii) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, and the (iii) Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021, and based on rules, regulations and amendments made there under, in case of grievances you can contact:</p>
        <h2 className='fw-bold mt-5 fs-4'>Details of the Grievance Officer</h2>
        <p className='mt-3 fs-5'><strong>Name:</strong> Srinivas Tanuku</p>
        <p className='mt-2 fs-5'><strong>Designation:</strong> Director</p>
        <p className='mt-2 fs-5'><strong>Phone:</strong> 9686601696</p>
        <p className='mt-2 fs-5'><strong>Email Id:</strong> <a className="text-primary" href="mailto:srinivas.tanuku@peopleprocessteck.com">srinivas.tanuku@peopleprocessteck.com</a></p>
        <p className='mt-2 fs-5'><strong>Time:</strong> Monday – Friday (10:00 AM - 6:00 PM)</p>
        <p className='mt-2 fs-5'>In the event you wish to make a complaint regarding any violation of the provisions of the Privacy Policy, you may send a written complaint to the Grievance Officer, who shall acknowledge the complaint within twenty-four (24) hours and redress the complaint within fifteen (15) days.</p>
      </section>

      <section className='mt-5'>
        <h1 className='fw-bold fs-4'>13. CONTACT DETAILS</h1>
        <p className='mt-2 fs-5'>Please feel free to reach out to us by email at <a className="text-primary" href="mailto:support@grcpulse.in">support@grcpulse.in</a>.</p>
        <p className='mt-2 fs-5'>This Privacy Policy was last updated on December 22, 2024.</p>
        <p className='mt-2 fs-5'>Copyright &copy; PEOPLE PROCESS TECK PRIVATE LIMITED, 2024. All Rights Reserved.</p>
      </section>
    </div>
    <Footer/>
    </>
  )
}

export default PrivacyAndPolicy