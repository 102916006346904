export const MONTHS = {
  "january": "January",
  "february": "February",
  "march": "March",
  "april": "April",
  "may": "May",
  "june": "June",
  "july": "July",
  "august": "August",
  "september": "September",
  "october": "October",
  "november": "November",
  "december": "December",
}

export const STATES = {
  andhraPradesh: "Andhra Pradesh",
  arunachalPradesh: "Arunachal Pradesh",
  assam: "Assam",
  bihar: "Bihar",
  chhattisgarh: "Chhattisgarh",
  central: "Central",
  delhi: "Delhi",
  goa: "Goa",
  gujarat: "Gujarat",
  haryana: "Haryana",
  himachalPradesh: "Himachal Pradesh",
  jammuAndKashmir: "Jammu & Kashmir",
  jharkhand: "Jharkhand",
  karnataka: "Karnataka",
  kerala: "Kerala",
  madhyaPradesh: "Madhya Pradesh",
  maharashtra: "Maharashtra",
  manipur: "Manipur",
  meghalaya: "Meghalaya",
  mizoram: "Mizoram",
  nagaland: "Nagaland",
  odisha: "Odisha",
  punjab: "Punjab",
  rajasthan: "Rajasthan",
  sikkim: "Sikkim",
  tamilNadu: "Tamil Nadu",
  telangana: "Telangana",
  tripura: "Tripura",
  uttarPradesh: "Uttar Pradesh",
  uttarakhand: "Uttarakhand",
  westBengal: "West Bengal",
};
