import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import api from '../../../Services/api';
 
const LcrReport = () => {
  // State to store fetched data
  const [data, setData] = useState([]);
  const [masterData, setMasterData] = useState([]);
 
  // Define the headers for the Excel sheet
  const headers = [
    'Sl No',
    'Vendor Code',
    'Name of Contractor/Sub-Contractor',
    'Address',
    'Contact Person',
    'Contact No',
    'Escalation Contact No',
    'Email Id',
    'Escalation Email Id',
    'Nature of Work',
    'Date of Commencement',
    'Date of Completion',
    'CLRA License No',
    'No. of Workers',
    'Valid Date',
    'BOCW RC No',
    'No. of Workers',
    'Valid Date',
    'WC Policy Number',
    'No. of Workers Covered',
    'Valid Date',
    'ESI Number',
    'PF Number',
    'PO Copy Number',
    'Valid Date',
    'HR Policy Undertaking Ack',
    'PSARA Licence',
    'Valid Date',
    'Factory Licence',
    'Valid Date',
  ];
 
  let data1 = sessionStorage.getItem('user');
  let clientData = JSON.parse(data1);
  let id = clientData.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.client.getLcrReport(id);
        const result = response.data;
        console.log(result);
        console.log("response", result.data.vendorProfile);
        console.log("master response", result.data.vendorMasterData);
 
        // Check if vendorProfile is an array and set the data
        if (Array.isArray(result.data.vendorProfile) ) {
          setData(result.data.vendorProfile);
          setMasterData(result.data.vendorMasterData)
        } else {
          console.error('vendorProfile is not an array', result.data.vendorProfile);
          setData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
      }
    };
 
    fetchData();
  }, []);
 
  // Function to generate Excel file
  const handleExport = () => {
    // Mapping API data to Excel sheet format
    const mappedData = data.map((item, index) => {
      const masterItem = masterData.find((master) => master.vendorId === item.vendorId);
 
   return{
      'Sl No': index + 1,  // Automatically generate Sl No starting from 1
      'Vendor Code' : item?.vendorCode ? item?.vendorCode : 'N/A',
      'Name of Contractor/Sub-Contractor': item?.vendorCompanyName || 'N/A',
      'Address': (item?.vendorCompanyAddress ? item.vendorCompanyAddress + ', ' : '') + (item?.vendorPlaceName ? item.vendorPlaceName + '-' : '') + (item?.vendorPinCode || 'N/A'),
      'Contact Person': item?.vendorName || 'N/A',
      'Contact No': item?.vendorPhoneNumber || 'N/A',
      'Escalation Contact No': item?.vendorPhoneNumber1 || 'N/A',
      'Email Id': item?.vendorEmail || 'N/A',
      'Escalation Email Id': item?.vendorEmail1 || 'N/A',
      'Nature of Work': item?.natureOfWork || 'N/A',

      // Check for nested values in masterItem and handle with fallbacks
      'Date of Commencement': masterItem?.dateOfCommencement?.value?.slice(0, 10) || 'N/A',
      'Date of Exit': masterItem?.dateOfCompletion?.value?.slice(0, 10) || 'N/A',
      'CLRA License No': masterItem?.clraLicence?.value || 'N/A',
      'No. of Workers (CLRA)': masterItem?.clraLicence?.noOfWorkers || 'N/A',
      'Valid Date (CLRA)': masterItem?.clraLicence?.validDate || 'N/A',

      'BOCW RC No': masterItem?.bocwRc?.value || 'N/A',
      'No. of Workers (BOCW)': masterItem?.bocwRc?.noOfWorkers || 'N/A',
      'Valid Date (BOCW)': masterItem?.bocwRc?.validDate || 'N/A',

      'WC Policy Number': masterItem?.wCPolicyNumber?.value || 'N/A',
      'No. of Workers Covered (WC)': masterItem?.wCPolicyNumber?.noOfWorkers || 'N/A',
      'Valid Date (WC)': masterItem?.wCPolicyNumber?.validDate || 'N/A',

      'ESI Number': masterItem?.esicAllotment?.value || 'N/A',
      'PF Number': masterItem?.epfoAllotment?.value || 'N/A',

      'PO Copy Number': masterItem?.poCopy?.value || 'N/A',
      'Valid Date (PO Copy)': masterItem?.poCopy?.validDate || 'N/A',

      'HR Policy Undertaking Ack': masterItem?.hrPolicyUndertakingAck?.validation === 2 ? 'Submitted' : 'Not Submitted',
      
      'PSARA Licence': masterItem?.passaraLicence?.value || 'N/A',
      'Valid Date (PSARA)': masterItem?.passaraLicence?.validDate || 'N/A',

      'Factory Licence': masterItem?.factoryLicence?.value || 'N/A',
      'Valid Date (Factory Licence)': masterItem?.factoryLicence?.validDate || 'N/A',
 
   };
 
    });
 
    // const
 
    // Create worksheet from the data
    const ws = XLSX.utils.json_to_sheet(mappedData);
 
    // Add headers manually to the worksheet
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
 
 
    // Style the worksheet (optional)
    const wsCols = [
      { wpx: 50  }, // Sl No
      { WPX: 50  }, //vendor code
      { wpx: 250 }, // Name of Contractor/Sub-Contractor
      { wpx: 300 }, // Address
      { wpx: 200 }, // Contact Person
      { wpx: 100 }, // Contact No
      { wpx: 100 }, // Escalation Contact No
      { wpx: 250 }, // Email Id
      { wpx: 250 }, // Escalation Email Id
      { wpx: 150 }, // Nature of Work
      { wpx: 100 }, // Date of Commencement
      { wpx: 100 }, // Date of Exit
      { wpx: 100 }, // CLRA License No
      { wpx: 100 }, // No. of Workers
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // BOCW RC No
      { wpx: 100 }, // No. of Workers
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // WC Policy Number
      { wpx: 100 }, // No. of Workers Covered
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // ESI Number
      { wpx: 100 }, // PF Number
      { wpx: 100 }, // PO Copy Number
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // HR Policy Undertaking Ack
      { wpx: 100 }, // PSARA Licence
      { wpx: 100 }, // Valid Date
      { wpx: 100 }, // Factory Licence
      { wpx: 100 }, // Valid Date
 
    ];
 
    ws['!cols'] = wsCols; // Apply column widths
 
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Contractor Report');
 
    // Write the workbook to a file
    XLSX.writeFile(wb, 'LCR Report.xlsx');
  };
 
  return (
    <div>
    
      <button className="btn btn-sm btn-danger mb-2" onClick={handleExport}>Download Excel</button>
      {/* Optionally display the fetched data in a table */}
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>
          <tr>
            {headers.map((header, index) => (
              <th key={index} style={{ border: '1px solid white', padding: '8px' }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item, index) => {
              const masterItem = masterData.find((master) => master.vendorId === item.vendorId);
              console.log("masteritem", masterItem);
              
              return (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px', alignContent:"center" }} >{index + 1}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{ item?.vendorCode || 'N/A'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.vendorCompanyName}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.vendorCompanyAddress}, {item.vendorPlaceName}, {item.vendorStateId}-{item.vendorPinCode}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.vendorName}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.vendorPhoneNumber}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.vendorPhoneNumber1 ? item.vendorPhoneNumber1 : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.vendorEmail}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.vendorEmail1 ? item.vendorEmail1 : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{item.natureOfWork}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem?.dateOfCommencement?.value.slice(0,10) : 'N/A'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem?.dateOfCompletion?.value.slice(0,10) : 'N/A'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.clraLicence.value : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.clraLicence.noOfWorkers : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.clraLicence.validDate : 'NA'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.bocwRc.value : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.bocwRc.noOfWorkers : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.bocwRc.validDate : 'NA'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem?.wCPolicyNumber?.value : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem?.wCPolicyNumber?.noOfWorkers : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem?.wCPolicyNumber?.validDate : 'NA'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.esicAllotment.value : 'NA'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.epfoAllotment.value : 'NA'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.poCopy.value : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.poCopy.validDate : 'NA'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem && masterItem?.hrPolicyUndertakingAck && masterItem?.hrPolicyUndertakingAck?.validation === 2 ? 'Submitted' : 'Not Submitted'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.passaraLicence.value : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.passaraLicence.validDate : 'NA'}</td>

                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.factoryLicence.value : 'NA'}</td>
                <td style={{ border: '1px solid black', padding: '8px' }} >{masterItem ? masterItem.factoryLicence.validDate : 'NA'}</td>
 
              </tr>
            )
})
          ) : (
            <tr>
              <td colSpan={headers.length}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
 
export default LcrReport;