
import React, { useState, useEffect } from 'react';
import './EditableCard.css';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import axios from 'axios';
import api from '../Services/api';
import { COLORS } from './colors';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';



const titles = [
    "Date of Commencement",
    "Date of Completion",
    "PO Copy Number",
    "EPFO Allotment copy",
    "ESIC allotment copy",
    "P Tax RC Number",
    "PT EC Number",
    "LWF Number",
    "Factory Licence",
    "Building plan layout",
    "Stability Certificate",
    "Concern to Establishment",
    "Concern to Operations",
    "Fire Advisory",
    "Fire NOC",
    "Environment Clearance",
    "Medical/Hospital - Certificate",
    "Apprenticeship Registration",
    "NEEM/NAPS - Registration",
    "CLRA RC",
    "S&CE RC",
    "CLRA Licence",
    "BOCW RC",
    "ISMW RC",
    "ISMW Licence",
    "PASSARA Licence",
    "FSSAI Licence",
    "WC Policy Number",
    "HR PolicyUndertakingAcK",
];

const MasterdataTableClient = () => {
    let data1 = sessionStorage.getItem('user');
    let clientData = JSON.parse(data1);
    let id = clientData.id;
    console.log('COMPANY Id', id);
    const [vendorData, setVendorData] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const [assessorOptions, setAssessorOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [completedCheckboxes, setCompletedCheckboxes] = useState(new Array(titles.length).fill(false));
    const [rejectedCheckboxes, setRejectedCheckboxes] = useState(new Array(titles.length).fill(false));
    const [remarks, setRemarks] = useState(new Array(titles.length).fill(''));
    const [vendorRemark, setVendorRemark] = useState(new Array(titles.length).fill(''));
    const [savedStatus, setSavedStatus] = useState(new Array(titles.length).fill(false));
    const [clientFormData, setClientFormData] = useState({
        clientName: '',
        companyId: id,
        principleEmployer: '',
        companyAddress: '',
        placeName: '',
        clientContactPersonName: '',
        clientContactPersonEmailId: '',
        clientContactPersonPhoneNumber: '',
        stateId: ''
    });
    
    const[selectedVendorDetails,setSelectedVendorDetails] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
  
    const [assessorFormData, setAssessorFormData] = useState({
        assessorCompanyName: '',
        assessorId: '',

    });
    const [vendorFormData, setVendorFormData] = useState({
        vendorCompanyName: '',
        vendorId: '',

    });

    useEffect(() => {
        api.assessor.getassessor(id)
            .then((response) => {
                if (response.status === 200) {
                    setAssessorOptions(response.data.data);
                }
                else {

                    setErrorSnackbarOpen(true);
                    setSnackbarErrorMessage("No Assessor Found Under this Client")
                }
            })
            .catch(error => {
                console.error('Error fetching assessor details:', error);
                
            });
    }, [])

    useEffect(() => {
        api.client.getClient(id)
            .then(response => {
                setClientFormData(response.data.data);
               

            })

    }, [])




    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'assessorName') {
            const selectedAssessor = assessorOptions.find(assessor => assessor.assessorCompanyName === value);
            if (selectedAssessor) {
                setAssessorFormData(prevState => ({
                    ...prevState,
                    assessorCompanyName: value,
                    assessorId: selectedAssessor.assessorId,
                }));

                fetchVendor(selectedAssessor.assessorId);
            }
        }

        if (name === 'vendorName') {
            const selectedVendor = vendorOptions.find(vendor => vendor.vendorCompanyName === value);
            if (selectedVendor) {
                setVendorFormData(prevState => ({
                    ...prevState,
                    vendorCompanyName: value,
                    vendorId: selectedVendor.vendorId,
                }));

                fetchVendorData(selectedVendor.vendorId);
                fetchVendorDetails(selectedVendor.vendorId);
            }
        }


        if (name !== 'clientName' && name !== 'assessorName') {
            setClientFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
 


    const fetchVendorDetails = async (vendorId) => {
        try {
          const response = await api.vendor.fetchVendorDetailsById(vendorId);
          setSelectedVendorDetails(response.data.data);
          console.log(response.data.data);
         
        } catch (error) {
          console.error('Error fetching vendor data:', error);
        }
      }

    const fetchVendor = async (assessorId) => {
        console.log("id", assessorId);
        try {
            const response = await api.vendor.getVendors(assessorId);
            if (response.status === 200) {
                const vendorDetails = response.data.data;
                setVendorOptions(vendorDetails);
            }
            else if (response.status === 404) {
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("No Vendor found under this Assessor")

                setVendorOptions([]);
            }

        } catch (error) {
            console.error("Vendor could not be fetched:", error);
            

        }
    };

    const fetchVendorData = async (vendorId) => {

        try {
            const response = await api.masterData.getVendorMasterData(vendorId);
            if (response.status === 200) {
                setVendorData(response.data.data);
                const newCompletedCheckboxes = [];
                const newRejectedCheckboxes = [];
                const newRemarks = [];
                const newVendorRemarks = [];

                // Iterate over the fetched data and set the values accordingly
                for (let i = 0; i < titles.length; i++) {

                    const rowData = response.data.data[Object.keys(response.data.data)[i]];

                    if (rowData) {
                        if (rowData.validation === 2) {
                            newCompletedCheckboxes[i] = true;
                            newRejectedCheckboxes[i] = false;
                            newRemarks[i] = rowData.remark;
                            newVendorRemarks[i] = rowData.vendorRemark;


                        } else if (rowData.validation === 3) {
                            newCompletedCheckboxes[i] = false;
                            newRejectedCheckboxes[i] = true;
                            newRemarks[i] = rowData.remark;
                            newVendorRemarks[i] = rowData.vendorRemark;


                        } else if (rowData.validation === 1) {
                            newCompletedCheckboxes[i] = false;
                            newRejectedCheckboxes[i] = false;
                            newVendorRemarks[i] = rowData.vendorRemark;
                            newRemarks[i] = " ";

                        }
                        else {
                            newCompletedCheckboxes[i] = false;
                            newRejectedCheckboxes[i] = false;
                            newRemarks[i] = " ";
                            newVendorRemarks[i] = " ";
                        }

                    }
                }

                setCompletedCheckboxes(newCompletedCheckboxes);
                setRejectedCheckboxes(newRejectedCheckboxes);
                setRemarks(newRemarks);
                setVendorRemark(newVendorRemarks);
            }
            else if (response.status === 404) {

                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("MasterData not found for this vendor");
            }
            else {
                
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("error fetching details");
            }
        } catch (error) {
            console.error('Error fetching vendor data:', error);
            
        }
    };









    const handleViewDocument = (fileUrl) => {
        window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')
    };



    const rows = titles.map((title, index) => {
        let rowClassName = '';
        if (vendorData && vendorData[Object.keys(vendorData)[index]]) {
            const validationStatus = vendorData[Object.keys(vendorData)[index]].validation;
            if (validationStatus === 1) {
                rowClassName = 'pending';
            } else if (validationStatus === 2) {
                rowClassName = 'accepted';
            } else if (validationStatus === 3) {
                rowClassName = 'rejected';
            }
        }
        if (completedCheckboxes[index]) {
            rowClassName = 'accepted';
        } else if (rejectedCheckboxes[index]) {
            rowClassName = 'rejected';
        }
        let filename = '';
        if (
            vendorData &&
            vendorData[Object.keys(vendorData)[index]] &&
            vendorData[Object.keys(vendorData)[index]].fileUrl
        ) {
            const fullUrl = vendorData[Object.keys(vendorData)[index]].fileUrl;
            filename = fullUrl.substring(fullUrl.lastIndexOf('/') + 1);
        }

        return (
            <tr className={rowClassName} >
                <td>{title}</td>
                <td>
            {vendorData && (
            <div
            style={{
              overflowX: 'auto',
              overflow: 'auto',
              width: '200px',
              height: vendorData[Object.keys(vendorData)[index]]?.validDate && vendorData[Object.keys(vendorData)[index]]?.noOfWorkers ? '100px' : 'auto',
            }}
          >
            <p>
              {vendorData[Object.keys(vendorData)[index]].value}
              <br />
              {vendorData[Object.keys(vendorData)[index]]?.validDate ? `Valid Date: ${vendorData[Object.keys(vendorData)[index]].validDate}`: null}
              <br />
              {vendorData[Object.keys(vendorData)[index]]?.noOfWorkers ? `No. of Workers: ${vendorData[Object.keys(vendorData)[index]].noOfWorkers}` : null}
            </p>
          </div>
          
            )}
          </td>
                <td>   {vendorData && (
                    <>

                        {filename && (
                            <div >
                                <button className='btn btn-sm btn-primary' onClick={() => handleViewDocument(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-eye"></i> Doc</button>
                                <button className="btn mx-1 btn-sm  btn-primary" onClick={() => window.open(vendorData[Object.keys(vendorData)[index]].fileUrl)}><i class="bi bi-download"></i> Doc</button>
                            </div>

                        )}
                    </>
                )}</td>
                <td>
                    {vendorData && (
                        <>
                            <p>
                                {vendorData[Object.keys(vendorData)[index]].validation === 1
                                    ? "Under Review"
                                    : vendorData[Object.keys(vendorData)[index]].validation === 2
                                        ? "Accepted"
                                        : vendorData[Object.keys(vendorData)[index]].validation === 3
                                            ? "Rejected"
                                            : "Not Uploaded"}
                            </p>
                        </>
                    )}
                </td>


                <td className="remark">
                   
                    {vendorData && (
            <>
              {vendorData[Object.keys(vendorData)[index]].remark && (
  <p style={{padding: '2px', width:'250px', height:'100px', border: '1px solid lightgrey', overflow:'auto'}}> {vendorData[Object.keys(vendorData)[index]].remark}</p>
)}
            </>
          )}
        
         
          
                </td>
                <td className="vendor remark">
                             
                {vendorData && (
            <>
              {vendorData[Object.keys(vendorData)[index]].vendorRemark && (
  <p style={{ padding:'2px', width:'250px', height:'100px', border: '1px solid lightgrey', overflow:'auto'}}>{vendorData[Object.keys(vendorData)[index]].vendorRemark}</p>
)}
            </>
          )}
                </td>


            </tr>
        );
    });

    return (
        <div>

<Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          
          }}
          severity="success"
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
        >
          <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          severity="error"
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          sx={{
            marginTop: '2.9%', 
            marginRight: '7%'
          }}
          onClose={() => {setErrorSnackbarOpen(false)}}
        >
          <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
        </Snackbar>

                  <div className='px-3 pt-3 pb-3' style={{ letterSpacing: '1px',borderBottom:'1px solid lightgray'}}>  
                    {/* <p className=' text-center'>Principle Employer : <span style={{fontWeight:'bolder'}}>{clientFormData.principleEmployer}</span></p> */} 
                <div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
                    <span style={{ fontSize: '14px' }}>Under review</span>
                    <div className="vr  mx-3"></div>
                    <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
                    <span style={{ fontSize: '14px' }}>Accepted</span>
                    <div className="vr mx-3"></div>
                    <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
                    <span style={{ fontSize: '14px' }}>Rejected</span>
                </div>  
           
            <div className="mx-3 mb-3 mt-3 float-end">
                    <Select 
                        value={assessorFormData.assessorCompanyName}
                        onChange={handleChange}
                        name="assessorName"
                        displayEmpty
                        style={{width:'auto',height:"30px",marginRight:"8px"}}
                    >
                        <MenuItem value="" disabled>Select Assessor</MenuItem>
                        {Array.isArray(assessorOptions) && assessorOptions.map((assessor) => (
                            <MenuItem key={assessor.assessorId} value={assessor.assessorCompanyName}>
                                {assessor.assessorCompanyName}, {assessor.assessorStateId}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select 
                        value={vendorFormData.vendorCompanyName}
                        onChange={handleChange}
                        name="vendorName"
                        displayEmpty
                        style={{ width:'auto',height:"30px"}}
                    >
                        <MenuItem value="" disabled>Select Vendor</MenuItem>
                        {Array.isArray(vendorOptions) && vendorOptions.map((vendor) => (
                            <MenuItem key={vendor.vendorId} value={vendor.vendorCompanyName}>
                                {vendor.vendorCompanyName}, {vendor.vendorStateId}
                            </MenuItem>
                        ))}
                    </Select>

     
                </div>
     

            <table className="mt-0 pt-0 ">
                <thead>
                    <tr className="text-white bg-dark">
                        <th >Name of DOC</th>
                        <th>Date/Doc No</th>
                        <th>Uploaded File</th>
                        <th>Status</th>
                            <th>Assessor Remark</th>
                            <th>Vendor Remark</th>
                        

                    </tr>
                </thead>
                <tbody >
                {selectedVendorDetails&& (
 
 <h6 className='px-2 pt-3 pb-2'>
Nature of Work:<span className='text-center fw-bold'> {selectedVendorDetails.natureOfWork}</span>
</h6>

)}
                    {rows}
                </tbody>
            </table>

        </div>
    )
}

export default MasterdataTableClient
