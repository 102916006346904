import ComplianceCalenderContainer from "../Component/ComplianceCalenderContainer"
import "../css/pages/compliance-calender-page.css"

const ComplianceCalendar = () => {
  return (
    <div className="compliance-calender-page">
      <ComplianceCalenderContainer />
    </div>
  )
}

export default ComplianceCalendar
