import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import api from '../../../Services/api';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import MonthlyData from '../Vendor/monthlydata';
import ReportTable from '../Vendor/reporttable';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);




const DoughnutChart = ({ accepted, rejected, underReview }) => {
  // Prepare the data for the doughnut chart
  const data = {
    labels: ['Accepted', 'Rejected', 'Under Review'],// The label and remaining portion
    datasets: [
      {

        data: [accepted, rejected, underReview], // Data for each status
        backgroundColor: [
          '#50c878',  // Accepted (green)
          '#B22222',  // Rejected (red)
          '#EFCC00',  // Under Review (gray)
          '#50c878',  // Compliant (green)
        ],
        borderWidth: 5,
        borderColor: '#eeee'
      }
    ],
  };

  // Set the options for the doughnut chart (e.g., cutout, rotation)
  const options = {
    cutout: '50%', // This will create the hole in the middle
    responsive: true,
    plugins: {
      legend: { position: 'bottom' },
      tooltip: {
        enabled: true, // Disable tooltip if not needed
      },

    },
  };

  return (
    <div style={{ display: 'inline-block', textAlign: 'center' }}>
      <Doughnut data={data} options={options} />
       <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
        Total Progress: {((accepted / 100) * 100).toFixed(2)}%
      </p> 
    </div>
  );
};



const Index = () => {
  let data = sessionStorage.getItem('user');
  let vendorData = JSON.parse(data);
  let vendorId = vendorData.id;

  const [vendorChartData, setVendorChartData] = useState({});

  const [vendorsData, setVendorsData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');

  const [rejectedPercentage, setRejectedPercentage] = useState(0);
  const [underReview, setUnderReview] = useState(0);

  const handlePercentagesChange = (rejected, underreview) => {
    setRejectedPercentage(rejected);
    setUnderReview(underreview);
  };

  useEffect(() => {
    vendorDetails();
    masterData(vendorId);
  }, [vendorId]);






  const vendorDetails = async () => {
    const response = await api.vendor.findvendor();
    const temp = response.data.filtterData;
    setVendorsData(temp);
    console.log(temp,"vendorDetails");
  };

  const masterData = async (vendorId) => {
    try {
      const response = await api.masterData.getVendorMasterData(vendorId);
      if (response.status === 200) {
        const chartData = response.data;
        setVendorChartData((prevData) => ({
          ...prevData,
          [vendorId]: chartData,
        }));
      }
      else if (response.status === 404) {
        setSnackbarOpen(true);
        setSnackbarErrorMessage("MasterData not found for this vendor");
      }
      else if (response.status === 500) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
      }
      else if (response.status === 400) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);

      } else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Error fetching master data.");
      }
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };


  const getChartData = (vendorId) => {
    if (!vendorChartData[vendorId]) return {};
    const chart = vendorChartData[vendorId];
    const Compliant = chart.totalPercantage || 0;
    const accepted = chart.acceptPercentage || 0;
    const rejected = chart.rejectPercentage || 0;
    const underReview = chart.URpercent || 0;
    const notUploaded = Math.max((Compliant - accepted - rejected - underReview).toFixed(2), 0);
    return { accepted, rejected, underReview, Compliant, notUploaded };
  };


  return (
    <div style={{overflow:'hidden'}}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="success"
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
      >
        <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="error"
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
      </Snackbar>

      <div className='mx-4 mt-2' style={{ display: 'grid', gridTemplateColumns: '0.5fr 1fr 0.5fr', gridTemplateRows: '1fr 0fr 0fr', gap: '5px' }}>

        {/* First Row */}
        <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
          {Object.keys(vendorChartData).map((vendorId) => {
            const { Compliant, accepted, rejected, underReview, notUploaded } = getChartData(vendorId);
            return (
              <div key={vendorId} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', border: '1px solid lightgray' }}>

                {/* Ensure that h6 takes up full width */}
                <h6 className="text-center  text-dark p-2" style={{ backgroundColor: 'rgba(0,0,0,0.1)', width: '100%', textAlign: 'center', marginTop: '0px' }}>
                  MasterData
                </h6>

                {/* Doughnut chart */}
                <DoughnutChart
                  accepted={accepted}
                  rejected={rejected}
                  underReview={underReview}
                />

              </div>
            );
          })}
        </div>
          <MonthlyData />
        
          <ReportTable 
        rejected={rejectedPercentage} 
        underReview={underReview} 
        onPercentagesChange={handlePercentagesChange}
      />

      </div>
    
 
  <div className="mx-5 d-flex flex-wrap justify-content-center align-items-center">
  
    <div className="mx-2 p-2" style={{ minWidth: '150px',maxWidth:'auto',backgroundColor: 'rgba(0,0,0,0.85)'}}>
    <h6 className="text-center text-light fw-bold">{vendorsData.vendorCompanyName}</h6>
        <h6 className='text-center text-secondary' style={{fontSize:'11px'}}>Vendor Company Name</h6>
    </div>
    
    <div className="mx-2 p-2" style={{  minWidth: '150px',maxWidth:'auto',  backgroundColor: 'rgba(0,0,0,0.2)' }}>
    <h6 className="text-center text-dark fw-bold">{vendorsData.natureOfWork}</h6>
        <h6 className='text-center text-secondary' style={{fontSize:'11px'}}>Nature Of Work</h6>
    </div>
    
  
    <div className="mx-2 p-2" style={{  minWidth: '150px',maxWidth:'auto',backgroundColor: 'rgba(0,0,0,0.85)' }}>
    <h6 className="text-center text-light fw-bold">{vendorsData.vendorContractEndDate?.slice(0,10)}</h6>
    <h6 className='text-center text-secondary' style={{fontSize:'11px'}}>Contract-End Date</h6>
    </div>
    
   
    <div className="mx-2 p-2" style={{ minWidth: '150px',maxWidth:'auto',backgroundColor: 'rgba(0,0,0,0.2)' }}>
    <h6 className='text-center text-dark fw-bold'>{rejectedPercentage}%</h6>
    <h6 className='text-center text-secondary' style={{fontSize:'11px'}}>Open Risks</h6>
    </div>

    {/* <div className="mx-2 p-2" style={{ minWidth: '150px',maxWidth:'auto', backgroundColor: 'rgba(0,0,0,0.85)' }}>
    <h6 className='text-center text-light fw-bold'>{underReview}%</h6>
    <h6 className='text-center text-secondary' style={{fontSize:'11px'}}>Under Review</h6>
   
    </div> */}
  </div>


    </div>
  );
};

export default Index;
