import React, { useEffect, useState } from 'react';
import api from '../../../Services/api';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import ProgressBar from 'react-bootstrap/ProgressBar'; // You can use this for the progress bar
import './index.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const YearlyMonthlydata = () => {
    let data1 = sessionStorage.getItem('user');
    let clientData = JSON.parse(data1);
    let id = clientData.id;

    const currentYear = new Date().getFullYear();
    const [yearlyData, setYearlyData] = useState(null);
    const [vendorList, setVendorList] = useState([]);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedQuarter, setSelectedQuarter] = useState('Q4'); // New state for selected quarter/half

    useEffect(() => {
        const getData = async () => {
            const response = await api.client.getAvgDataVendors(id, selectedYear);
            setYearlyData(response.data.data);
        };
        getData();
    }, [id, selectedYear]);

    useEffect(() => {
        const fetchVendors = async () => {
            const response = await api.client.getAllVendors(id);
            setVendorList(response.data.data.vendorProfile);
        };
        fetchVendors();
    }, []);

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleQuarterChange = (e) => {
        setSelectedQuarter(e.target.value);
    };

    // Data for Doughnut Chart
    const overallAccepted = yearlyData ? parseFloat(yearlyData.overallYearlyAcceptedAvg) : 0;
    const overallRejected = yearlyData ? parseFloat(yearlyData.overallYearlyRejectedAvg) : 0;
    const doughnutData = {
        labels: ['Accepted', 'Rejected'],
        datasets: [
            {
                data: [overallAccepted, overallRejected],
                backgroundColor: ['#50c878', '#B22222'],  // Green for accepted, red for rejected
                borderWidth: 1,
                
            },
        ],
    };

    // Data for Bar Chart
    let vendorLabels = [];
    let combinedData = [];
    
    if (yearlyData && yearlyData.vendorAverages) {
        for (let i = 0; i < yearlyData.vendorAverages.length; i++) {
            const vendor = yearlyData.vendorAverages[i];
            const vendorName = vendorList.find(v => v.vendorId === vendor.vendorId)?.vendorCompanyName || '';
            
            vendorLabels.push(vendorName.slice(0, 5)); // Vendor names for X-axis
            // Combine the accepted and rejected data into one (you can sum or average them)
            const combinedValue = parseFloat(vendor.yearlyAcceptedAverage) + parseFloat(vendor.yearlyRejectedAverage);
            combinedData.push(combinedValue);
        }
    }
    

    
    const getQuarterOrHalfData = () => {
        if (yearlyData && yearlyData.vendorAverages) {
            return yearlyData.vendorAverages.map(vendor => {
                switch (selectedQuarter) {
                    case 'Q1':
                        return vendor.Q1AcceptedAvg;
                    case 'Q2':
                        return vendor.Q2AcceptedAvg;
                    case 'Q3':
                        return vendor.Q3AcceptedAvg;
                    case 'Q4':
                        return vendor.Q4AcceptedAvg;
                    case 'H1':
                        return vendor.H1AcceptedAvg;
                    case 'H2':
                        return vendor.H2AcceptedAvg;
                        case 'Yearly':
                        return vendor.yearlyAcceptedAverage;
                    default:
                        return 0;
                }
            });
        }
        return [];
    };

    const getQuarterOrHalfDataRejected = () => {
        if (yearlyData && yearlyData.vendorAverages) {
            return yearlyData.vendorAverages.map(vendor => {
                switch (selectedQuarter) {
                    case 'Q1':
                        return vendor.Q1RejectedAvg;
                    case 'Q2':
                        return vendor.Q2RejectedAvg;
                    case 'Q3':
                        return vendor.Q3RejectedAvg;
                    case 'Q4':
                        return vendor.Q4RejectedAvg;
                    case 'H1':
                        return vendor.H1RejectedAvg;
                    case 'H2':
                        return vendor.H2RejectedAvg;
                        case 'Yearly':
                        return vendor.yearlyRejectedAverage;
                    default:
                        return 0;
                }
            });
        }
        return [];
    };

    const acceptedQuarterData = getQuarterOrHalfData();
    const rejectedQuarterData = getQuarterOrHalfDataRejected();

    return (
        <div className="bg-dark">
           
                <div className="pt-3 d-flex justify-content-between">
                    <h6 className="p-1 mx-2" style={{color:'#eeee',border:'1px solid gray'}}>Vendor's Avg Yearly Compliance</h6>
                    <div>
                <select  value={selectedQuarter} onChange={handleQuarterChange} className="bg-danger text-light quarter-dropdown">
                        {['Yearly','Q1', 'Q2', 'Q3', 'Q4', 'H1', 'H2'].map((quarter) => (
                            <option key={quarter} value={quarter}>
                                {quarter}
                            </option>
                        ))}
                    </select>
                    <select value={selectedYear} onChange={handleYearChange} className="bg-danger text-light mx-2 year-dropdown">
                    {Array.from({ length: currentYear - 2020 + 1 }, (_, i) => 2020 + i).map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                    </div>
                   
                </div>
         

            {yearlyData && (
                <section className="d-flex flex-column align-items-center">

                  <div className="mt-3" style={{height:'200px',width:'200px'}}>
                        <Doughnut
                     
                            data={doughnutData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                       display:false,
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: function (context) {
                                                let percentage;
                                                if (context.label === 'Accepted') {
                                                    percentage = (overallAccepted / 100 * 100).toFixed(2);
                                                    return `Accepted: ${percentage}%`;
                                                } else if (context.label === 'Rejected') {
                                                    percentage = (overallRejected / 100 * 100).toFixed(2);
                                                    return `Rejected: ${percentage}%`;
                                                }
                                            },
                                        },
                                    },
                                },
                            }}
                          
                        />
                       
                        </div>
                 

                        <Bar

className='w-100'

data={{

    labels: vendorLabels,  // Vendor names for the X-axis

    datasets: [

        {

            label: 'Rejected',

            data: rejectedQuarterData,  // Data for "Rejected"

            backgroundColor: '#B22222', // Red color for "Rejected"

            stack: 'stack1', // Stack group identifier

        },

        {

            label: 'Accepted',

            data: acceptedQuarterData,  // Data for "Accepted"

            backgroundColor: '#50c878', // Green color for "Accepted"

            stack: 'stack1', // Stack group identifier

        },

    ],

}}

options={{

    responsive: true,

    scales: {

        x: {

            grid: {

                display: false, // Hide grid lines for X-axis

            },

            ticks: {

                color: 'white', // White color for X-axis tick labels

            },

            axisLine: {

                display: true, // Ensure the main X-axis line is visible

                borderColor: 'white', // White color for the axis line

                borderWidth: 1, // Width of the X-axis line

            },

        },

        y: {

            min: 0,

            max: 100,

            grid: {

                display: false, // Hide grid lines for Y-axis

            },

            ticks: {

                stepSize: 20,

                color: 'white', // White color for Y-axis tick labels

                callback: function(value) {

                    return value + '%'; // Append '%' to the y-axis tick values

                },

            },

            axisLine: {

                display: true, // Ensure the main Y-axis line is visible

                borderColor: 'white', // White color for the axis line

                borderWidth: 1, // Width of the Y-axis line

            },

        },

    },

    plugins: {

        legend: {

            display: false,  // Disable the legend

        },

        title: {

            display: true,

        },

        tooltip: {

            callbacks: {

                label: function(tooltipItem) {

                    const vendor = yearlyData.vendorAverages[tooltipItem.dataIndex];

                    const fullVendorName = vendorList.find(v => v.vendorId === vendor.vendorId)?.vendorCompanyName || '';

                    const acceptedValue = tooltipItem.raw;

                    const rejectedValue = rejectedQuarterData[tooltipItem.dataIndex];

                    if (tooltipItem.datasetIndex === 0) {
                        // Dataset 0 represents Rejected
                        return `${fullVendorName}: Rejected ${rejectedValue}%`;
                    } else {
                        // Dataset 1 represents Accepted
                        return `${fullVendorName}: Accepted ${acceptedValue}%`;
                    }

                }

            },

        },

    },

}}

/>

                         
                   

                 
                </section>
            )}

        </div>
    );
};

export default YearlyMonthlydata;
