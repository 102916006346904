import React from 'react'
import VendorsMonthlyData from './vendorsMonthlyData'
import YearlyMonthlydata from './yearlyMonthlydata'

export default function Index() {
  return (
    <div  style={{ display: 'grid',gridTemplateColumns: '1fr 1fr',gridTemplateRows: '1fr 0fr', gap: '10px' }}>
    
      <YearlyMonthlydata/>

      <VendorsMonthlyData/>
     
    </div>
  )
}
