import { useState } from "react"
import Navbar from "../layout/NavBar"
import contactUsLogo from "../Images/contact-us-illu.png"
import api from "../Services/api"
import { useNavigate } from "react-router-dom"

const ContactUs = () => {
  const navigation = useNavigate();
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [message, setMessage] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [contactNumberError, setContactNumberError] = useState("")
  const [messageError, setMessageError] = useState("")
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const indianMobileRegex = /^\d{10}$/;

  async function handleOnSubmit(event) {
    event.preventDefault(); // Prevent form submission
    event.stopPropagation(); // Stop event propagation

    try {
      const response = await api.contactUs.onContactUsApi({ name, email, phoneNumber: contactNumber, reason: message })
      if( response.status === 200 ){
         alert("Successfully sent message");
         navigation("/");
      }else{
        return alert(" error while sending mail!");
      }
    } catch (err) {
      console.log("Something Went Wrong")
      console.log(err)
    }
  
  }

  function handleOnName(e) {
    const value = e.target.value
    setName(value)

    if (!value) {
      setNameError("Name is required")
      setName(value)
      return
    }

    if (value.length > 50) {
      setNameError("Name must not exceed 50 characters")
      setName(value)
      return
    }

    setNameError("")
  }

  function handleOnEmailChange(e) {
    const value = e.target.value
    setEmail(value)

    if (!value) {
      setEmailError("Email is required")
      return
    }

    if (!emailRegex.test(value)) {
      setEmailError("Invalid email Id")
      return
    }

    setEmailError("")
  }

  function handleOnConatctNumberChange(e) {
    const value = e.target.value
    setContactNumber(value)

    if (!value) {
      setContactNumberError("Contact number is required")
      return
    }

    if (value.length < 10 || value.length > 10) {
      setContactNumberError('Invalid contact number')
      return
    }

    if (!indianMobileRegex.test(value)) {
      setContactNumberError("Invalid contact number")
      return
    }

    setContactNumberError("")
  }

  function handleOnMessageChange(e) {
    const value = e.target.value
    setMessage(value)

    if (!value) {
      setMessageError("Message is required")
      return
    }

    if (value.length > 250) {
      setMessageError("Message must not exceed 250 characters")
      return
    }

    setMessageError("")
  }

  function handleDisableButton() {
    if (nameError || contactNumberError || emailError || messageError) {
      return true
    }

    if (!name || !contactNumber || !email || !message) {
      return true
    }

    return false
  }

  return (
    <>
      <Navbar />
      <div class="bg-cover p-5 min-h-screen bg-[#B22222]">
      <div className="w-[75%] mt-5 pb-[26px] bg-white rounded-md mx-auto  shadow-card-shadow relative  grid grid-cols-2 items-start">
        <div className="p-4  max-w-[100%] ">
            <h1 className="text-[#B22222] font-bold text-2xl">Get In Touch</h1>
            <h1 className="mt-2 text-xs">We are here for you.How can we help?</h1>
            <form className="mt-2" onSubmit={handleOnSubmit}>
              <input value={name} onChange={handleOnName} className="p-2 w-full rounded-md mt-2  bg-gray-100 bg-gray-100 border border-gray-100" placeholder="Enter Your Name" />
              {nameError && <p className="text-red-500 text-xs mt-1 min-h-[20px]">{nameError}*</p>}
              <input value={email} onChange={handleOnEmailChange} className="p-2 w-full rounded-md mt-4  bg-gray-100 bg-gray-100 border border-gray-100" placeholder="Enter Your Email Address" />
              {emailError && <p className="text-red-500 text-xs mt-1 min-h-[20px]">{emailError}*</p>}
              <input value={contactNumber} onChange={handleOnConatctNumberChange} className="p-2 w-full rounded-md mt-4  bg-gray-100 bg-gray-100 border border-gray-100" placeholder="Enter Your Contact Number" />
              {contactNumberError && <p className="text-red-500 text-xs mt-1 min-h-[20px]">{contactNumberError}*</p>}
              <textarea value={message} onChange={handleOnMessageChange} rows={3} className="p-2 w-full rounded-md mt-4  bg-gray-100 border border-gray-100" placeholder="Enter Your Message" />
              {messageError && <p className="text-red-500 text-xs mt-1 min-h-[20px]">{messageError}*</p>}
              <button disabled={handleDisableButton()} type="submit" className={`text-white p-2 w-full mt-3 rounded-md font-bold transition ${handleDisableButton() ?  "bg-gray-300" : "bg-[#B22222] hover:bg-[#8B0000]"}`}>SUBMIT</button>
            </form>
          </div>
         <div className="flex justify-center items-center"> 
          <img src={contactUsLogo} alt="" className="w-[275px] h-[350px]" />
        </div>
        </div>
      </div>
    </>)
}

export default ContactUs
