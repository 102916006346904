import Footer from "../layout/Footer"
import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";
import Navbar from "../layout/NavBar"
import imageOne from "../Images/sv-three.png"
import imageTwo from "../Images/sv-one.png"
import imageThree from "../Images/sv-two.png"

const VendorCompliance = () => {
  return (
    <>
      <Navbar />
      <div className="p-4 bg-primary-color h-[400px] bg-opacity-20 grid grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl text-primary-color text-center font-bold text-4xl">Vendor Compliance</h1>
          <h1 className="leading-6 text-center  mt-2">
            Vendor compliance involves verifying the third-party suppliers adherence to applicable laws, ethical standards, and contractual obligations. Our services include
          </h1>
          <div className="flex gap-2 justify-center mt-2">
            <h1 className="flex items-center"><TiTick fontSize="1rem" /> <span>Vendor audits</span></h1>
            <h1 className="flex items-center"><TiTick fontSize="1rem" /> <span>Documentation review</span></h1>
            <h1 className="flex items-center"><TiTick fontSize="1rem" /> <span>Training</span></h1>
          </div>
           <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
              <Link to="/contact-us">
                  <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
                       Contact Us
                   </button>
              </Link>
            </div>
        </div>
        <div className="mt-5 flex justify-center items-center">
          <img src={imageOne} alt="" className="w-[350px] h-[350px]" />
        </div>
      </div>
      <div className="mt-4 px-[100px] grid grid-cols-2">
        <div>
          <img src={imageThree} alt="" className="w-[350px] h-[350px]" />
        </div>
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Benefits of Ensuring HR Statutory Compliance for Subcontractors
          </h1>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Legal Safeguard:</h1>
              <h1 className="mt-1"> Avoid penalties and legal liabilities arising from subcontractors' non-compliance.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Reputation Management:</h1>
              <h1 className="mt-1"> Build trust and a strong reputation as a socially responsible employer.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Operational Continuity:</h1>
              <h1 className="mt-1"> Prevent disruptions caused by non-compliance-related legal issues.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Workforce Safety:  </h1>
              <h1 className="mt-1"> Ensure a safe and fair work environment for all workers on your premises.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 px-4 mt-4">
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Key Areas of HR Statutory Compliance for Vendors / Subcontractors
          </h1>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Contract Labor (Regulation and Abolition) Act, 1970</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Labor Welfare Fund (LWF)
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Health, Safety, and Welfare
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Equal Remuneration Act, 1976</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Maternity Benefit Act, 1961
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Workmen’s Compensation Act, 1923</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Attendance and Work Hour Records
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
              Grievance Redressal Mechanism
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
              Licenses and Approvals              </h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <img src={imageTwo} alt="" className="w-[450px] h-[450px]" />
        </div>
      </div>
      <Footer />
    </>)
}

export default VendorCompliance