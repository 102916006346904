import Navbar from "../layout/NavBar"
import Footer from "../layout/Footer"
import { Link } from "react-router-dom"
import serviceHeaderImageOne from "../Images/ench-two.png"
import serviceHeaderImageTwo from "../Images/ench-one.png"
import serviceHeaderImageThree from "../Images/ench-three.png"

const EstablishmentComplianceService = () => {

  return (
    <>
      <Navbar />
      <div className="p-4 bg-primary-color h-[400px] bg-opacity-20 grid grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl text-primary-color text-center font-bold">Establishment Compliance</h1>
          <h1 className="leading-6 text-center">HR statutory compliance involves adherence to labor laws, employee-related regulations, and statutory requirements set by central, state, or local authorities. These regulations are designed to protect the rights of employees, ensure workplace safety, and establish fair practices.</h1>
        <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
          <Link to="/contact-us">
            <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
              Contact Us
            </button>
          </Link>
        </div>
        </div>
        <div className="flex justify-center items-center">
          <img src={serviceHeaderImageOne} alt="" className="w-[400px] h-[400px]" />
        </div>
      </div>
      <div className="mt-4 px-[100px] grid grid-cols-2">
        <div>
          <img src={serviceHeaderImageTwo} alt="" className="w-[350px] h-[350px]" />
        </div>
        <div>
          <h1 className="font-bold text-primary-color text-xl">Benefits of Ensuring HR Statutory Compliance</h1>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Avoidance of Legal Penalties:</h1>
              <h1> Non-compliance can result in heavy fines, legal actions, or even business shutdowns.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Improved Employee Trust:</h1>
              <h1> Compliance fosters a positive work environment and strengthens.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Operational Efficiency: </h1>
              <h1>
              Streamlined compliance processes reduce administrative burden and operational risks and seamless scalability.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 px-4 mt-4">
        <div>
          <h1 className="font-bold text-primary-color text-xl">Key Areas of HR Statutory Compliance for Establishments </h1>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Non-compliance can result in heavy fines, legal actions, or even business shutdowns.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Employee State Insurance (ESI) Compliance </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Payment of Wages Act, 1936</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Minimum Wages Act, 1948</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Payment of Gratuity Act, 1972 </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Shops and Establishments Act</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Equal Remuneration Act, 1976</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Maternity Benefit Act, 1961 </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Payment of Bonus Act, 1965</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Occupational Safety, Health, and Working Conditions Code (OSH Code)</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Industrial Disputes Act, 1947
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Labour Welfare Fund (LWF)
              </h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <img src={serviceHeaderImageThree} alt="" className="w-[450px] h-[450px]" />
        </div>
      </div>
      
      <Footer />
    </>
  )
}

export default EstablishmentComplianceService