import { useState } from "react"
import api from "../Services/api"
import Navbar from "../layout/NavBar"
import { useNavigate } from "react-router-dom"


const RequestADemo = () => {
  const navigate = useNavigate();
  const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  const indianMobileRegex = new RegExp(/^\d{10}$/)
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [contactNumberError, setContactNumberError] = useState("")
  const [message, setMessage] = useState("")
  const [messageError, setMessageError] = useState("")


  async function handleOnSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
     
    try {
      const response = await api.contactUs.onContactUsApi({ name, email, phoneNumber: contactNumber, reason: message })
      if( response.status === 200 ){
        alert("Successfully Requested For Demo!");
        navigate("/");
      }else{
        alert("error while sending request!");
      }
    } catch (err) {
      console.log(err)
    }
  
  }

  function handleOnNameChange(event) {
    const value = event.target.value
    if (!value) {
      setNameError("Name is required")
      setName(value)
      return
    }

    if (value) {
      if (value.length > 25) {
        setNameError("Name must not exceed 25 characters")
        setName(value)
        return
      }
    }
    setName(value)
    setNameError("")
  }

  function handleOnEmailChange(event) {
    const value = event.target.value
    if (!value) {
      setEmailError("Email is required")
      setEmail(value)
      return
    }

    if (value) {
      if (!emailRegex.test(email)) {
        setEmailError("Invalid Email")
        setEmail(value)
        return
      }
    }

    setEmailError("")
    setEmail(value)
  }

  function handleOnContactNumberChange(event) {
    const value = event.target.value
    if (!value) {
      setContactNumberError("Contact Number is required")
      setContactNumber(value)
      return
    }

    if (value) {
      if (!indianMobileRegex.test(value)) {
        setContactNumberError("Invalid Contact Number")
        setContactNumber(value)
        return
      }
    }
    setContactNumberError("")
    setContactNumber(value)
  }

  function handleOnMessageChange(event) {
    const value = event.target.value
    if (!value) {
      setMessageError("Message is required")
      setMessage(value)
      return
    }

    if (value) {
      if (value.length > 250) {
        setMessageError("Message must not exceed 250 characters")
        setMessage(value)
        return
      }
    }

    setMessageError("")
    setMessage(value)
  }

  function handleTogglebtn() {
    if (contactNumberError || messageError || emailError || nameError) {
      return true
    }

    if (!contactNumber || !message || !email || !name) {
      return true
    }
    return false
  }

  return (
    <>
      <Navbar />
      <div className="bg-primary-color  p-8">
        <div className="bg-white py-2 mt-5 px-10 min-h-screen rounded-md grid grid-cols-2">
          <div className="pt-3  flex flex-col  ">
            <h1 className="font-bold text-3xl mb-3">Request A Demo</h1>
            <ul class="list-disc list-inside space-y-2 mt-2">
              <li>
                <span class="font-medium">Wage and Payroll Management:</span> We help in managing wage and payroll with accurate calculations and timely disbursements.
              </li>
              <li>
                <span class="font-medium">Employee Attendance Tracking:</span> We assist in tracking employee attendance through real-time monitoring and detailed reporting.
              </li>
              <li>
                <span class="font-medium">PF and ESI Contributions:</span> We simplify PF and ESI contributions by automating calculations and submissions.
              </li>
              <li>
                <span class="font-medium">Comprehensive Record Maintenance:</span> We ensure comprehensive record maintenance for wages, attendance, PF, and ESI, making audits and reporting seamless.
              </li>
            </ul>
          </div>
          <div className="p-4 ">
            <h1 className="text-xl font-bold text-center">Fill the below form to request a demo</h1>
            <h1 className="text-xl font-bold text-center">Request a demo</h1>
            <p className="text-center">OR</p>
            <h1 className="text-center">Call us at <span className="font-bold">080-31634351</span> </h1>
            <form className="mt-4">
              <div>
                <input onChange={handleOnNameChange} name="name" value={name} className="p-2 border border-gray-200 w-full rounded-md placeholder:text-gray-500" placeholder="Enter Your Name" />
                {nameError && <p className="text-red-500 text-xs">{nameError}<sup>*</sup></p>}
              </div>
              <div>
                <input onChange={handleOnEmailChange} name="email" value={email} className="p-2 border border-gray-200 w-full rounded-md placeholder:text-gray-500 mt-4" placeholder="Enter Your Email" />
                {emailError && <p className="text-red-500 text-xs">{emailError}<sup>*</sup></p>}
              </div>
              <div>
                <input onChange={handleOnContactNumberChange} name="contactNumber" value={contactNumber} className="p-2 border border-gray-200 w-full rounded-md placeholder:text-gray-500 mt-4" placeholder="Enter Your Contact Number" />
                {contactNumberError && <p className="text-red-500 text-xs">{contactNumberError}<sup>*</sup></p>}
              </div>
              <div>
                <textarea value={message} onChange={handleOnMessageChange} rows={4} className="p-2 border border-gray-200 w-full rounded-md placeholder:text-gray-500 mt-4" placeholder="Enter Your Message" />
                {messageError && <p className="text-red-500 text-xs">{messageError}<sup>*</sup></p>}
              </div>
              <button
                type="button"
                onClick={handleOnSubmit}
                disabled={handleTogglebtn()}
                className={`p-2  text-white rounded-md w-full mt-4 ${handleTogglebtn() ? "bg-gray-300" : "bg-[#B22222] hover:bg-[#8B0000]"}`}>Request A Demo</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestADemo