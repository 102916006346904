import Navbar from "../layout/NavBar"
import Footer from "../layout/Footer"
import { Link } from "react-router-dom"
import serviceHeaderImageOne from "../Images/hrs-one.png"
import serviceHeaderImageTwo from "../Images/hrs-two.png"
import serviceHeaderImageThree from "../Images/hrs-three.png"

const HrServiceProviderAuditService = () => {
  return (
    <>
      <Navbar />
      <div className="p-4 bg-primary-color h-[400px] bg-opacity-20 grid grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl text-primary-color text-center font-bold">
            HR Service Provider Audit
          </h1>
          <h1 className="leading-6 text-center ">
            Outsourcing HR functions is a common practice, but monitoring their compliance is equally important. Our HR service provider audit evaluates the adherence of external HR service providers to legal, ethical, and contractual standards. We help ensure that payroll processing, recruitment, and employee management are handled in compliance with applicable labor laws. </h1>
            <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
              <Link to="/contact-us">
                <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
                  Contact Us
                </button>
              </Link>
            </div>     
            </div>
        <div className="flex justify-center items-center">
          <img src={serviceHeaderImageOne} alt="" className="w-[350px] h-[350px]" />
        </div>
      </div>
      <div className="mt-4 px-[100px] grid grid-cols-2">
        <div>
          <img src={serviceHeaderImageTwo} alt="" className="w-[350px] h-[350px]" />
        </div>
        <div>
          <h1 className="font-bold text-primary-color text-xl">Benefits of Compliance Audits and Inspections</h1>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Compliance Assurance:</h1>
              <h1 className="mt-1"> Ensures adherence to labor laws and regulations, reducing legal risks. </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Improved Processes: </h1>
              <h1 className="mt-1"> Identifies inefficiencies, leading to streamlined HR operations..</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Risk Mitigation:  </h1>
              <h1 className="mt-1">  Helps detect potential issues early, preventing costly mistakes.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Enhanced Employee Experience:  </h1>
              <h1 className="mt-1"> Ensures policies are employee-friendly, boosting morale and retention.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Cost Efficiency:  </h1>
              <h1 className="mt-1">Optimizes HR functions, reducing unnecessary expenses.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 px-4 mt-4">
        <div>
          <h1 className="font-bold text-primary-color text-xl">Key Areas of Compliance Audits and Inspections</h1>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Compliance Checks </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Payroll Accuracy   </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Recruitment Processes  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Performance Management  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Employee Relations  </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Data Security </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Benefits Administration </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Payroll Management
              </h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <img src={serviceHeaderImageThree} alt="" className="w-[450px] h-[450px]" />
        </div>
      </div>
      <Footer />
    </>)
}

export default HrServiceProviderAuditService