import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import axios from 'axios';
import api from '../Services/api';

import { COLORS } from '../Component/colors';
import {
  Button,
  TextField,
  Grid,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  MenuItem,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Register necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const months = [
  { name: "JANUARY", value: "01" },
  { name: "FEBRUARY", value: "02" },
  { name: "MARCH", value: "03" },
  { name: "APRIL", value: "04" },
  { name: "MAY", value: "05" },
  { name: "JUNE", value: "06" },
  { name: "JULY", value: "07" },
  { name: "AUGUST", value: "08" },
  { name: "SEPTEMBER", value: "09" },
  { name: "OCTOBER", value: "10" },
  { name: "NOVEMBER", value: "11" },
  { name: "DECEMBER", value: "12" }
];
const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2020 + 1 }, (x, i) => 2020 + i);
const statesInIndia = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi', 'Ladakh', 'Lakshadweep', 'Puducherry'];

export const VendorList = () => {
  let data = sessionStorage.getItem('user');
  let assessorData = JSON.parse(data);
  let id = assessorData.id;

  const [vendors, setVendors] = useState([]);
  const [errors, setErrors] = useState({});
  const [expandedVendor, setExpandedVendor] = useState(null);
  const [vendorChartData, setVendorChartData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(null);// State to manage Dialog visibility
  const [formData, setFormData] = useState({
    vendorId: '',
    vendorCompanyName: '',
    vendorCompanyAddress: '',
    vendorPlaceName: '',
    vendorPinCode: '',
    vendorStateId: '',
    vendorName: '',
    vendorEmail: '',
    vendorPhoneNumber: '',
    vendorEmail1: '',
    vendorCode:"",
    vendorPhoneNumber1: '',
    vendorContractStartDate: '',
    vendorContractEndDate: '',
    natureOfWork: ''
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
  const [isHandleCreate, setIsHandleCreate] = useState(false);
  const[loading,setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await api.vendor.getVendors(id);
      setVendors(response.data.data);
      setLoading(false);
      const chartDataPromises = response.data.data.map((vendor) => {
        return masterData(vendor.vendorId);
      });


      await Promise.all(chartDataPromises);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[6789]\d{9}$/;
    const pinCodePattern = /^(?!000000$)[0-9]{6}$/;
    if (!formData.vendorCompanyName) newErrors.vendorCompanyName = 'Vendor Company Name is required';

    if (!formData.vendorCompanyAddress) newErrors.vendorCompanyAddress = 'company Address is required';
    if (!formData.vendorPlaceName) newErrors.vendorPlaceName = 'Place Name is required';
    if (!formData.natureOfWork) newErrors.natureOfWork = 'Nature of work is required';
    if (!formData.vendorName) newErrors.vendorName = 'Vendor Name is required';

    if (!emailPattern.test(formData.vendorEmail)) {
      newErrors.vendorEmail = formData.vendorEmail ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }

    if (formData.vendorEmail1 && !emailPattern.test(formData.vendorEmail1)) {
      newErrors.vendorEmail1 = 'Invalid email address (e.g., text@email.com)';
    }
    
    if (formData.vendorPhoneNumber1 && !phonePattern.test(formData.vendorPhoneNumber1)) {
      newErrors.vendorPhoneNumber1 = 'Invalid phone number. Number should not start with zero)';
    }
    if (!pinCodePattern.test(formData.vendorPinCode)) {
      newErrors.vendorPinCode = formData.vendorPinCode ? 'Invalid Pin code format(245678)' : 'Pin code required';
    }
    if (!phonePattern.test(formData.vendorPhoneNumber)) {
      newErrors.vendorPhoneNumber = formData.vendorPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (!formData.vendorCode) newErrors.vendorCode = 'Vendor Code is required';
    const stateIdVendor = formData.vendorStateId;
    if (!stateIdVendor) {
      newErrors.stateIdVendor = 'Please select State';
    }

    if (!formData.vendorContractStartDate) newErrors.vendorContractStartDate = 'Contract Start Date is required';
    if (!formData.vendorContractEndDate) newErrors.vendorContractEndDate = 'Contract End Date is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const masterData = async (vendorId) => {
    try {

      const response = await api.masterData.getVendorMasterData(vendorId);
      const chartData = response.data; // assuming this has the necessary data

      setVendorChartData((prevData) => ({
        ...prevData,
        [vendorId]: chartData // Store chart data for this vendor by vendorId
      }));
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  // Prepare chart data for the pie chart
  const getChartData = (vendorId) => {
    if (!vendorChartData[vendorId]) return {}; // Return empty object if no data for the vendor

    const chart = vendorChartData[vendorId];
    const accepted = chart.acceptPercentage || 0;
    const rejected = chart.rejectPercentage || 0;
    const underReview = chart.URpercent || 0;
    const total = chart.totalPercantage || 0

    return {
      labels: ['Accepted', 'Rejected', 'Under Review'],
      datasets: [
        {
          data: [accepted, rejected, underReview],
          backgroundColor: [COLORS.success, COLORS.danger, COLORS.bggray]
        },
      ],
    };
  };
  const handleEditClick = (vendor) => {
    setFormData({
      vendorId: vendor.vendorId,
      vendorCompanyName: vendor.vendorCompanyName,
      vendorCompanyAddress: vendor.vendorCompanyAddress,
      vendorPinCode: vendor.vendorPinCode,
      vendorStateId: vendor.vendorStateId,
      vendorPlaceName: vendor.vendorPlaceName,
      vendorName: vendor.vendorName,
      vendorCode: vendor.vendorCode,
      vendorEmail: vendor.vendorEmail,
      vendorPhoneNumber: vendor.vendorPhoneNumber,
      vendorEmail1: vendor.vendorEmail1,
      vendorPhoneNumber1: vendor.vendorPhoneNumber1,
      vendorContractStartDate: vendor.vendorContractStartDate,
      vendorContractEndDate: vendor.vendorContractEndDate,
      natureOfWork: vendor.natureOfWork
    });
    setOpenDialog(true);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleExpandClick = (vendorId) => {
    setExpandedVendor(expandedVendor === vendorId ? null : vendorId);
  };
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `${value}%`; // Add the percentage symbol
          },
        },
      },
      legend: {
        labels: {
          generateLabels: function (chart) {
            const data = chart.data.datasets[0].data;
            const labels = chart.data.labels;
            return labels.map((label, index) => {
              return {
                text: `${label}: ${data[index]}%`,
                fillStyle: chart.data.datasets[0].backgroundColor[index],
              };
            });
          },
        },
      },
    },
  };



  const handleMonthlyData = async (id, selectedMonth, selectedYear) => {
    let data = sessionStorage.getItem('user');
    let assessorData = JSON.parse(data);
    const id1 = assessorData.id;
    const id2 = assessorData.companyId;
 
    if (!selectedMonth || !selectedYear) {
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage("Please select month and year");
      return;
    }
    else {
      const Monthlydata = {
        vendorId: id,
        month: selectedMonth,
        year: selectedYear,
        assessorId: id1,
        companyId: id2,
        complianceFiledDate: '',
        auditorObservation: '',
        auditorRecommendation: '',
        objectivesOfTheAudit: '',
        AcceptedPercent: "",
      RejectedPercent: "",
      UnderReviewPercent: "",
      complied: "",
      nonComplied: "",
      noOfEmployees:"",
        combinedMusterRollCumRegisterOfWages: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        RegisterOfWorkmen: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        leaveRegisterLeaveBook: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        EmploymentCard: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        WageSlip: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        ServiceCertificateForLeftEmployees: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },        
        inAndOutTimeRegister: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        BankStatementOrSalaryCreditConfirmation: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        PFDeclarationOnLetterhead: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        ESIDeclarationOnLetterhead: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        OvertimeDeclarationOnLetterhead: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        PaymentDoneAsPerRevisedRateOfMinimumwages: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
         },
         PFMonthlyECR: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        PFCombinedChallan: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        PFTRRNSummary: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        IW1InternationalWorkerMonthlyReturns: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        ESIAccidentRegister: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        ESICContributionChallan: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        ESICMonthlyStatement: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        PehchanCard: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        professionalTaxReturns: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        PTChallan: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        MonthlyBonusPaidOrNot: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        statutoryBonusReturn: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        registrationOfEstablishment: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        appointmentOrderInFormQ : {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        musterRollOfWomenWorkers: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        employeesCompensationPolicy: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        EqualRemunerationAndRegisterToBeMaintained: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        RegisterofOvertimeandPayment: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        RegisterOfLeaveAndLeaveBook: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        RegisterOfAdultWorkersCumMusterRoll: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        CombinedregisterforMusterRollandWages: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        RegisterOfUnclaimedWagesAndPaymentToTheBoard: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        },
        LWFChallan: {
          na: false,
          fileUrl: "",
          validation: 0,
          remark: "",
          vendorRemark: "",
          qId: "",
        } 
       
      }
      try {
        const response = await api.vendor.createVendorMonthlyData(Monthlydata)
        if (response.status === 201) {
          setSnackbarOpen(true);
          setSnackbarMessage(response.data.message);
          setSelectedValues((prevState) => ({
            ...prevState,
            [Monthlydata.vendorId]: {
              ...prevState[Monthlydata.vendorId],
              selectedMonth: '',
              selectedYear: '',
            },
          }));

        }
        else if (response.status === 404) {
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage(response.data.message);
        }

        else if (response.status === 400) {
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage(response.data.message);
        }
        else if (response.status === 500) {
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage(response.data.message);
        }
        else {
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage("Some error occured please try again");
        }



      }
      catch (error) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Error creating MonthlyData");
      }
    }
  }

  const handleSave = async () => {
    if (validateForm()) {
      try {
        const response = await api.vendor.editVendor(formData.vendorId, formData);
        if (response.status === 200) {
          setSnackbarOpen(true);
          setSnackbarMessage("Updated Succesfully")
          fetchData();
          setOpenDialog(false);
        }
        else if (response.status === 404) {
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage("VendorData not found");
        } else {

          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage("Error updating data");
        }
      } catch (error) {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Error updating data");
      }
    }
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };
  const handleMonthChange = (vendorId, event) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [vendorId]: {
        ...prevState[vendorId],
        selectedMonth: event.target.value,
      },
    }));
  };
  
  const handleYearChange = (vendorId, event) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [vendorId]: {
        ...prevState[vendorId],
        selectedYear: event.target.value,
      },
    }));
  };


  const handleDeleteClick = async (vendor) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${vendor.vendorCompanyName}?`);
    if (isConfirmed) {
      try {
        const vendorId = vendor.vendorId;
        console.log("vendorId", vendorId);
        const response = await api.vendor.deleteVendors(vendorId);
        if (response.status === 200) {
          setSnackbarOpen(true);
          setSnackbarMessage("Deleted Succesfully")
        }
        else {
          setErrorSnackbarOpen(true);
          setSnackbarErrorMessage("Some error occured please try again later");
        }

      } catch (error) {
        console.error('Error Finding the Id', error);
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage("Some error occured please try again later");
      }
    }
    else {
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage("Deleting Cancelled");
    }

  }
  return (
    <div className="m-3">
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',

        }}
        severity="success"
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        sx={{
          marginTop: '2.9%',
          marginRight: '7%'
        }}
      >
        <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        severity="error"
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        sx={{
          marginTop: '2.9%',
          marginRight: '7%'
        }}
        onClose={() => { setErrorSnackbarOpen(false) }}
      >
        <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
      </Snackbar>
      {loading ? (
  <div>
  <h4 className="text-center mt-5">Loading......</h4>
</div>
      ) : 
      vendors && vendors.length > 0 ? (
        <>
          <h5 className='mt-4'>Vendor List <i class="bi bi-arrow-right-square-fill"></i></h5>
        
          <TableContainer className="mt-2" component={Paper}>
            <table>
              <thead >
                <tr style={{backgroundColor:'black'}}>
                  {/* <th style={{ color: 'black', fontWeight: "bold", letterSpacing: '0.5px' }}>Client Details</th> */}
                  <th className="text-center" style={{ color: 'white', fontWeight: "bold", letterSpacing: '0.5px' }}>Vendor Company Details</th>
                  <th className="text-center" style={{ color: 'white', fontWeight: "bold", letterSpacing: '0.5px' }}>Nature Of Work</th>
                  <th className="text-center" style={{ color: 'white', fontWeight: "bold", letterSpacing: '0.5px' }}>Contact-Person Details</th>
                  {/* <th className="text-center" style={{color:'white',fontWeight:"bold",letterSpacing:'0.5px'}}>Contract</th> */}
                  <th className="text-center" style={{ color: 'white', fontWeight: "bold", letterSpacing: '0.5px' }}>Status</th>
                  <th className="text-center" style={{ color: 'white', fontWeight: "bold", letterSpacing: '0.5px' }}>Actions</th>
                </tr>
              </thead>
              <TableBody>
                {vendors.map((vendor) => (
                  <TableRow key={vendor.vendorId}>
                   
                    <TableCell ><span style={{fontWeight:'bold',fontSize:'16px'}}>{vendor.vendorCompanyName}</span> <br></br>{vendor.vendorCompanyAddress}, {vendor.vendorPlaceName} {vendor.vendorStateId}- {vendor.vendorPinCode} <br></br> Contract : {vendor.vendorContractStartDate.slice(0, 10).split('-').reverse().join('-')} to {vendor.vendorContractEndDate.slice(0, 10).split('-').reverse().join('-')}</TableCell>
                    <TableCell>{vendor.natureOfWork}</TableCell>
                    <TableCell>{vendor.vendorName}<br></br> {vendor.vendorPhoneNumber} {vendor.vendorPhoneNumber1 && `, ${vendor.vendorPhoneNumber1}`}<br></br>{vendor.vendorEmail} <br></br>{vendor.vendorEmail1 && ` , ${vendor.vendorEmail1}`}</TableCell>
                    {/* <TableCell>{vendor.vendorContractStartDate} - {vendor.vendorContractEndDate}</TableCell> */}
                    <TableCell>
                      {vendorChartData[vendor.vendorId] && vendorChartData[vendor.vendorId].acceptPercentage !== undefined && (
                        <Pie data={getChartData(vendor.vendorId)} options={options} />
                      )}
                    </TableCell>
                    <TableCell>
                      <p className="text-center "><span className="text-danger">Note :</span> Create button will be enabled only if Accepted percentage is above 85%</p>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <button className="mx-1 btn btn-sm btn-primary" onClick={() => handleEditClick(vendor)}>Edit</button>

                        <button className=" mx-1 btn btn-sm btn-danger" onClick={() => handleDeleteClick(vendor)}>Delete</button>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>

                        <>
                        <select
            className="mx-2 p-1"
            style={{borderRadius:'4px',border:'1px solid lightgray'}} 
            value={selectedValues[vendor.vendorId]?.selectedMonth || ''}
            onChange={(e) => handleMonthChange(vendor.vendorId, e)}
          >
            <option value="">Select Month</option>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
          <select
          className="p-1"
          style={{borderRadius:'4px',border:'1px solid lightgray'}} 
            value={selectedValues[vendor.vendorId]?.selectedYear || ''}
            onChange={(e) => handleYearChange(vendor.vendorId, e)}
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
                        </>
                        <button className=" mx-1 btn btn-sm btn-warning" disabled={vendorChartData[vendor.vendorId]?.acceptPercentage < 85} onClick={() => handleMonthlyData(vendor.vendorId,  selectedValues[vendor.vendorId]?.selectedMonth, 
    selectedValues[vendor.vendorId]?.selectedYear)}>Create</button>
                      </div>

                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </table>
          </TableContainer>
        </>
      ) : (
        <div className='mt-4'>
          <p className="text-center">No Vendors Found !! <Link to="/createVendor">Click here</Link> to Create</p>
        </div>
      )}
 
      <Dialog open={openDialog} onClose={handleCancel} maxWidth="md" fullWidth>
        <h6 style={{ textAlign: 'center', color: 'white', backgroundColor: "#1C1C1C", padding: '24px', letterSpacing: '0.5px' }}>EDIT VENDOR DETAILS</h6>
        <DialogContent >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Company Name"
                name="vendorCompanyName"
                value={formData.vendorCompanyName}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.vendorCompanyName}
                helperText={errors.vendorCompanyName}
              />
            </Grid>

            <Grid item xs={12} md={6}>

              <Select
                fullWidth
                className="mt-3"
                label="State ID"
                value={formData.vendorStateId}
                onChange={handleFormChange}
                name="vendorStateId"
                InputLabelProps={{ shrink: true }}
                displayEmpty
                error={!!errors.vendorStateId}
              >
                <MenuItem value="" disabled >Select State</MenuItem>
                {statesInIndia.map((state, index) => (

                  <MenuItem key={index} value={state}>{state}</MenuItem>
                ))}
              </Select>

            </Grid>
            {errors.stateIdVendor && (
              <div className="pb-3 px-5" style={{ color: 'red', fontSize: '14px' }}>
                {errors.stateIdVendor}
              </div>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Company Address"
                name="vendorCompanyAddress"
                value={formData.vendorCompanyAddress}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.vendorCompanyAddress}
                helperText={errors.vendorCompanyAddress}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Place"
                InputLabelProps={{ shrink: true }}
                name="vendorPlaceName"
                value={formData.vendorPlaceName}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.vendorPlaceName}
                helperText={errors.vendorPlaceName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="PinCode"
                name="vendorPinCode"
                InputLabelProps={{ shrink: true }}
                value={formData.vendorPinCode}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.vendorPinCode}
                helperText={errors.vendorPinCode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Vendor Name"
                InputLabelProps={{ shrink: true }}
                name="vendorName"
                value={formData.vendorName}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.vendorName}
                helperText={errors.vendorName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="vendorEmail"
                InputLabelProps={{ shrink: true }}
                value={formData.vendorEmail}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.vendorEmail}
                helperText={errors.vendorEmail}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Alternative Email"
                name="vendorEmail1"
                InputLabelProps={{ shrink: true }}
                value={formData.vendorEmail1}
                onChange={handleFormChange}
                type="email"
                margin="normal"
                error={!!errors.vendorEmail1}
                helperText={errors.vendorEmail1}
              />
            </Grid>


            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="vendorPhoneNumber"
                InputLabelProps={{ shrink: true }}
                value={formData.vendorPhoneNumber}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.vendorPhoneNumber}
                helperText={errors.vendorPhoneNumber}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Alternative PhoneNumber"
                name="vendorPhoneNumber1"
                InputLabelProps={{ shrink: true }}
                value={formData.vendorPhoneNumber1}
                onChange={handleFormChange}
                type="number"
                margin="normal"
                error={!!errors.vendorPhoneNumber1}
                helperText={errors.vendorPhoneNumber1}
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nature of Work"
                name="natureOfWork"
                InputLabelProps={{ shrink: true }}
                value={formData.natureOfWork}
                onChange={handleFormChange}
                margin="normal"
                error={!!errors.natureOfWork}
                helperText={errors.natureOfWork}
              />
            </Grid>
            <Grid item xs={12} md={6}>
             <TextField
                          fullWidth
                          label="Vendor Code"
                          name="vendorCode"
                          InputLabelProps={{ shrink: true }}
                          value={formData.vendorCode}
                          onChange={handleFormChange}
                          helperText={errors.vendorCode}
                          error={!!errors.vendorCode}
                         
            
                        />
                        </Grid>

            <Grid item xs={12} md={6}>

              <TextField
                fullWidth
                label="Contract Start Date"
                type="date"
                name="vendorContractStartDate"
                InputLabelProps={{ shrink: true }}
                value={formData.vendorContractStartDate ? formData.vendorContractStartDate.slice(0, 10): ''}
                onChange={handleFormChange}
                error={!!errors.vendorContractStartDate}
                helperText={errors.vendorContractStartDate}
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Contract End Date"
                name="vendorContractEndDate"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formData.vendorContractEndDate ? formData.vendorContractEndDate.slice(0, 10): ''}
                onChange={handleFormChange}
                error={!!errors.vendorContractEndDate}
                helperText={errors.vendorContractEndDate}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <div className='mx-4 mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button className="btn btn-md btn-danger" onClick={handleCancel} >
            Cancel
          </button>
          <button className="btn btn-md btn-success" onClick={handleSave} disabled={!validateForm}>
            Save
          </button>
        </div>

      </Dialog>
    </div>
  );
};
