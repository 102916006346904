import React, { useState,useEffect ,useRef} from 'react';
import './EditableCard.css';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import axios from 'axios';
import api from '../Services/api';
import './EditableCard.css';
import {COLORS} from './colors';
import MonthlyReport from './MonthlyReport';
import SnackbarContent from '@mui/material/SnackbarContent';
import Snackbar from '@mui/material/Snackbar';

const months = [
    { name: "JANUARY", value: "01" },
    { name: "FEBRUARY", value: "02"},
    { name: "MARCH", value: "03" },
    { name: "APRIL", value: "04" },
    { name: "MAY", value: "05" },
    { name: "JUNE", value: "06" },
    { name: "JULY", value: "07" },
    { name: "AUGUST", value: "08" },
    { name: "SEPTEMBER", value: "09" },
    { name: "OCTOBER", value: "10" },
    { name: "NOVEMBER", value: "11" },
    { name: "DECEMBER", value: "12" }
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2020 + 1 }, (x, i) => 2020 + i);
  const titles = [
    "The Contract Labour (Regulation & Abolition) Act, 1970",
    "Generic",
    "Minimum Wages Act, 1936",
     "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952",
     "The Employees' State Insurance Act and Regulations, 1948",
     "Professional Tax",
    "The Payment of Bonus Act, 1972",
    "Shops And Commercial Establishments Act",
    "The Maternity Benefit Act, 1961",
    "The Employees Compensation Act, 1923",
    "The Equal Remuneration Act, 1976", 
    "The Factories Act, 1948",
    "The Labour Welfare Fund Act, 1965",
   
];
const titleToKeyMap = {
    "Combined Muster Roll-cum-Register of Wages": "combinedMusterRollCumRegisterOfWages",
    "Register of Workmen": "RegisterOfWorkmen",    
    "Leave Register/Leave Book":"leaveRegisterLeaveBook",
    "Employment Card":"EmploymentCard",
    "Wage Slip": "WageSlip",
    "Service Certificate for Left Employees" : "ServiceCertificateForLeftEmployees",
    "In and out time Register": "inAndOutTimeRegister",
    "Bank Statement for Salary Credit Confirmation": "BankStatementOrSalaryCreditConfirmation",
    "PF Declaration on letterhead": "PFDeclarationOnLetterhead",
    "ESI Declaration on letterhead": "ESIDeclarationOnLetterhead",
    "Overtime Declaration on letterhead": "OvertimeDeclarationOnLetterhead",
    "Payment done as per revised rate of Minimum wages": "PaymentDoneAsPerRevisedRateOfMinimumwages",
    "PF Monthly ECR": "PFMonthlyECR",
    "PF Combined Challan": "PFCombinedChallan",
    "PF TRRN Summary": "PFTRRNSummary",
    "IW-1 International Worker Monthly Returns": "IW1InternationalWorkerMonthlyReturns",
    "ESI-Accident Register": "ESIAccidentRegister",
    "ESIC Contribution Challan": "ESICContributionChallan",
    "ESIC Monthly Statement": "ESICMonthlyStatement",
    "Pehchan Card (Insured Person)": "PehchanCard",
    "Professional Tax Returns": "professionalTaxReturns",
    "PT Challan": "PTChallan",
    "Monthly Bonus Paid or Not": "MonthlyBonusPaidOrNot",
    "Statutory Bonus Return": "statutoryBonusReturn",
    "Registration Of Establishment": "registrationOfEstablishment",
    "Appointment Order In Form Q": "appointmentOrderInFormQ",
    "Muster Roll Of Women Workers": "musterRollOfWomenWorkers",
    "Employees Compensation Policy": "employeesCompensationPolicy",
    "Equal Remuneration And Register To Be Maintained": "EqualRemunerationAndRegisterToBeMaintained",
    "Register of Overtime and Payment": "RegisterofOvertimeandPayment",
    "Register Of Leave And Leave Book": "RegisterOfLeaveAndLeaveBook",
    "Register Of Adult Workers Cum Muster Roll":"RegisterOfAdultWorkersCumMusterRoll",  
    "Combined register for Muster Roll and Wages":"CombinedregisterforMusterRollandWages",
    "Register Of Unclaimed Wages And Payment To The Board": "RegisterOfUnclaimedWagesAndPaymentToTheBoard",
    "LWF Challan": "LWFChallan",

    
    
     


};



const preferredSubTitles = {
    "The Contract Labour (Regulation & Abolition) Act, 1970": [
        "Combined Muster Roll-cum-Register of Wages",
        "Register of Workmen",
        "Leave Register/Leave Book",
        "Employment Card",
        "Wage Slip",
        "Service Certificate for Left Employees",
       
    ],
    "Generic": [
        "In and out time Register",
        "Bank Statement for Salary Credit Confirmation",
        "PF Declaration on letterhead",
        "ESI Declaration on letterhead",
        "Overtime Declaration on letterhead"
    ],
    "Minimum Wages Act, 1936": [
        "Payment done as per revised rate of Minimum wages"
    ],
    "The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952": [
        "PF Monthly ECR",
        "PF Combined Challan",
        "PF TRRN Summary",
        "IW-1 International Worker Monthly Returns"
    ],
    "The Employees' State Insurance Act and Regulations, 1948": [
        "ESI-Accident Register",
        "ESIC Contribution Challan",
        "ESIC Monthly Statement",
        "Pehchan Card (Insured Person)"
    ],
    "Professional Tax": [
        "Professional Tax Returns",
        "PT Challan"
    ],
    "The Payment of Bonus Act, 1972": [
        "Monthly Bonus Paid or Not",
        "Statutory Bonus Return"
    ],
    "Shops And Commercial Establishments Act": [
        "Registration Of Establishment",
        "Appointment Order In Form Q"
    ],
    "The Maternity Benefit Act, 1961": [
        "Muster Roll Of Women Workers",
        
    ],
    "The Employees Compensation Act, 1923": [
        "Employees Compensation Policy",
        
    ],
    "The Equal Remuneration Act, 1976": [
        "Equal Remuneration And Register To Be Maintained",
        
    ],
    "The Factories Act, 1948": [
        "Register of Overtime and Payment",
        "Register Of Leave And Leave Book",
        "Register Of Adult Workers Cum Muster Roll",
        "Combined register for Muster Roll and Wages"
    ],
    "The Labour Welfare Fund Act, 1965": [
        "Register Of Unclaimed Wages And Payment To The Board",
        "LWF Challan"
    ],
    
   
};
const MonthlyDataTableClient = () => {
    let data1 = sessionStorage.getItem('user');
    let clientData = JSON.parse(data1);
    let id = clientData.id;
   const[complianceFiledDate,setComplianceFiledDate]=useState('');
    const[monthlyData,setMonthlyData]=useState({});
    const [vendorId,setVendorId]=useState('');
    const[assessorOptions,setAssessorOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]); 
    const[clientFormData,setClientFormData]=useState({
        clientName: '',
        companyId: id,
        principleEmployer: '',
        companyAddress: '',
        placeName: '',
        clientContactPersonName: '',
        clientContactPersonEmailId: '',
        clientContactPersonPhoneNumber: '',
        stateId: ''
    });
     const [loading, setLoading] = useState(true);
        const[selectedVendorDetails,setSelectedVendorDetails] = useState('');
   const  [assessorFormData,setAssessorFormData] = useState({
    assessorCompanyName:'',
    assessorId: '',
   
   });
   const  [vendorFormData,setVendorFormData] = useState({
    vendorCompanyName:'',
    vendorId: '',
   
   });
   const [showModal, setShowModal] = useState(false);  
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarMessage, setSnackbarMessage] = useState('');
   const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
   const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(''); // State to control the modal visibility
   const handleShow = () => setShowModal(true); 
   const handleClose = () => setShowModal(false);
   const [selectedMonth, setSelectedMonth] = useState(''); 
const [selectedYear, setSelectedYear] = useState(null);
const fileInputRefs = useRef(Array(titles.length * Math.max(...Object.values(preferredSubTitles).map(arr => arr.length))).fill(null).map(() => React.createRef()));


useEffect(()=>{
    api.assessor.getassessor(id) 
                            .then(response => {
                                if(response.status === 200){
                                setAssessorOptions(response.data.data);
                                }
                                else if(response.status === 400){
                                    setErrorSnackbarOpen(true);
                                    setSnackbarErrorMessage("Error fetching Details");
                                    setAssessorOptions([]);
                                }
                                else if(response.status === 500){
                                    setErrorSnackbarOpen(true);
                                    setSnackbarErrorMessage(response.data.message);
                                    setAssessorOptions([]);
                                }
                                else if(response.status === 404){
                                    setErrorSnackbarOpen(true);
                                    setSnackbarErrorMessage(response.data.message);
                                    setAssessorOptions([]);
                                }
                                else{
                                    setErrorSnackbarOpen(true);
                                    setSnackbarErrorMessage("Some error occured, please try again");
                                    setAssessorOptions([]);
                                }
                                
                              
                            })
                            .catch(error => {
                                console.error('Error fetching assessor details:', error);
                            });
},[])

useEffect(()=>{
    api.client.getClient(id)
    .then(response => {
        if(response.status === 200){
        setClientFormData(response.data.data);
        }
        else if(response.status === 400){
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("Error fetching details");
        }
        else if(response.status === 404){
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage(response.data.message);
            setClientFormData({});
        }
        else if(response.status === 500){
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage(response.data.message);
            setClientFormData({});
        }
        else{
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("Some error occured, please try again");
            setClientFormData({});
        }
       
    })

},[])
   
const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    
   
};
useEffect(() => {

    if (vendorId && selectedMonth && selectedYear) {
      fetchVendorData(vendorId, selectedMonth, selectedYear);
    }
  }, [vendorId, selectedMonth, selectedYear]);
const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    
   
};

    
      const handleChange = (event) => {
        const { name, value } = event.target;               
        if (name === 'assessorName') {
            const selectedAssessor = assessorOptions.find(assessor => assessor.assessorCompanyName === value);
            if (selectedAssessor) {
                setAssessorFormData(prevState => ({
                    ...prevState,
                    assessorCompanyName: value,
                    assessorId: selectedAssessor.assessorId, 
                }));
                 
                 fetchVendor(selectedAssessor.assessorId);
            }
        }
        
        if (name === 'vendorName') {
            const selectedVendor = vendorOptions.find(vendor => vendor.vendorCompanyName === value);
            if (selectedVendor) {
                setVendorFormData(prevState => ({
                    ...prevState,
                    vendorCompanyName: value,
                    vendorId: selectedVendor.vendorId, 
                }));
                    setVendorId(selectedVendor.vendorId);
                    fetchVendorDetails(selectedVendor.vendorId);
                    sessionStorage.setItem('selectedVendorId', selectedVendor.vendorId);

        
            }
        }
        
      
        if (name !== 'clientName' && name !== 'assessorName') {
            setClientFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    const fetchVendorDetails = async (vendorId) => {
        try {
          const response = await api.vendor.fetchVendorDetailsById(vendorId);
          setSelectedVendorDetails(response.data.data);
          console.log(response.data.data);
         
        } catch (error) {
          console.error('Error fetching vendor data:', error);
        }
      }

const fetchVendor = async (assessorId) => {
    try {
        const response = await api.vendor.getVendors(assessorId); 
        if(response.status === 200){
        const vendorDetails = response.data.data;
        setVendorOptions(vendorDetails); 
        }
        else if(response.status === 400){
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage(response.data.message);
            setVendorOptions([])
        }
        else if(response.status === 404){
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage(response.data.message);
            setVendorOptions([])
        }
        else if(response.status === 500){
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage(response.data.message);
            setVendorOptions([])
        }
        else{
            setErrorSnackbarOpen(true);
            setSnackbarErrorMessage("Some error occured, please try again");
            setVendorOptions([]);
        }

       
    } catch (error) {
        console.error("Vendor could not be fetched:", error);
    }
};
const fetchVendorData = async (vendorId,month,year) => {
    try {
        const response = await api.vendor.getMonthlyData(vendorId,month,year);
       if(response.status===200){
        const data = response.data.data || {};
        
        
        const initializedData = {};
        Object.keys(data).forEach((key) => {
            const entry = data[key];
            initializedData[key] = {
                fileUrl: entry.fileUrl || '',
                validation: entry.validation !== null ? entry.validation : 0,
                remark: entry.remark || '',
                vendorRemark: entry.vendorRemark || '',
                na:entry.na||''
            }; 
            setMonthlyData(initializedData);
            setComplianceFiledDate(data.complianceFiledDate);
        });
    }
      else if(response.status === 400){
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                setComplianceFiledDate('')
            }
            else if (response.status === 404) {
               setErrorSnackbarOpen(true);
               setSnackbarErrorMessage(response.data.message);
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                setComplianceFiledDate('')

            }
            else if(response.status === 500){
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage(response.data.message);
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                setComplianceFiledDate('')
            }
            else{
                setErrorSnackbarOpen(true);
                setSnackbarErrorMessage("Some error occured, please try again later");
                setSelectedYear(null);
                setSelectedMonth('');
                setMonthlyData({});
                setComplianceFiledDate('')
            }
        
    } catch (error) {
        console.error('Error fetching monthly data', error);
    }
};
const renderRows = () => {
    return titles.map((title, titleIndex) => (
        <React.Fragment key={titleIndex}>
            <tr>
                <td colSpan={6} className="fw-bold bg-light">{title}</td>
            </tr>
            {preferredSubTitles[title].map((subTitle, subTitleIndex) => {
                const dataEntry = monthlyData[titleToKeyMap[subTitle]] || {};
                const { validation = 0, remark = '', vendorRemark = '',fileUrl = '',na=false } = dataEntry;

                const rowStyle = validation === 2 ? { backgroundColor: 'lightgreen' } :
                                 validation === 3 ? { backgroundColor: 'lightcoral' } : validation === 1 ? {backgroundColor:COLORS.bggray}:{backgroundColor:'transparent'}
                                

                return (
                    <>
                    <tr key={subTitleIndex} style={rowStyle}>
                        <td>{subTitle}
                       
                     
                           
                        </td>
                        <td>
    {fileUrl ? (
        <div className="mt-2">
            <button 
                className='btn btn-sm btn-primary' 
                onClick={() => window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, '_blank')}
            >
                <i className="bi bi-eye"></i> Doc
            </button>
            <button 
                className="btn mx-1 btn-sm btn-primary" 
                onClick={() => window.open(fileUrl)}
            >
                <i className="bi bi-download"></i> Doc
            </button>
          
        </div>
    ) : (
        na ? (
            <span>Not Applicable</span>  
        ) : (
            <span></span>  
        )
    )}
</td>
<td>
    {validation==1?"Under Review": validation==2?"Accepted":validation===3?"Rejected":""}
</td>
<td>

           
        {remark && (
            <div style={{overflow:'auto',width:'250px',minHeight:'50px',maxHeight:'100px'}}>{remark}</div>
        )}
                              
</td>
<td>
{vendorRemark && (
            <div style={{overflow:'auto',width:'250px',minHeight:'50px',maxHeight:'100px'}}>{vendorRemark}</div>
        )}
</td>
   
                            
                  
                    </tr>
                   
                   
                    </>
                );
            })}
        </React.Fragment>
    ));
};
  return (
    <>
 <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',

                }}
                severity="success"
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
                sx={{
                    marginTop: '2.9%',
                    marginRight: '7%'
                }}
            >
                <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                severity="error"
                open={errorSnackbarOpen}
                autoHideDuration={5000}
                sx={{
                    marginTop: '2.9%',
                    marginRight: '7%'
                }}
                onClose={() => { setErrorSnackbarOpen(false) }}
            >
                <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
            </Snackbar>
    <div className='px-3 pt-3 pb-3' style={{ letterSpacing: '1px', borderBottom:'1px solid lightgray'}}>
<div style={{ backgroundColor: COLORS.bggray, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Under review</span>
  <div className="vr  mx-3"></div>
  <div style={{ backgroundColor: COLORS.success, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px', borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Accepted</span>
  <div className="vr mx-3"></div>
  <div style={{ backgroundColor: COLORS.danger, width: '15px', height: '15px', display: 'inline-block', marginRight: '4px',  borderRadius: '50%' }}></div>
  <span style={{ fontSize: '14px' }}>Rejected</span>
</div>

       <div className='mb-3 mt-3 mx-3 d-flex float-end'>
<Select 
        value={assessorFormData.assessorCompanyName}
        onChange={handleChange}
        name="assessorName"
        displayEmpty
        style={{height:'30px'}}
    >
        <MenuItem value="" disabled>Select Assessor</MenuItem>
        {Array.isArray(assessorOptions) && assessorOptions.map((assessor) => (
            <MenuItem key={assessor.assessorId} value={assessor.assessorCompanyName}>
                {assessor.assessorCompanyName}, {assessor.assessorStateId}
                 {/* {assessor.assessorAddress} */}
            </MenuItem>
        ))}
    </Select>
    <Select className="mx-2 "
        value={vendorFormData.vendorCompanyName}
        onChange={handleChange}
        name="vendorName"
        displayEmpty
        style={{height:'30px'}}
    >
        <MenuItem value="" disabled>Select Vendor</MenuItem>
        {Array.isArray(vendorOptions) && vendorOptions.map((vendor) => (
            <MenuItem key={vendor.vendorId} value={vendor.vendorCompanyName}>
                {vendor.vendorCompanyName}, {vendor.vendorStateId}
            </MenuItem>
        ))}
    </Select>
    <select className="mx-2" style={{borderRadius:'4px',height:'30px',border:'1px solid lightgray'}} value={selectedMonth || ''} onChange={handleMonthChange}>
                    <option value="">Select Month</option>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>
                <select  style={{borderRadius:'4px',border:'1px solid lightgray'}} value={selectedYear || ''} onChange={handleYearChange} >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
    </div>
  
   
   
   
    
  
   
               
             
                           
                         
  
     <table >
     <thead>
         <tr  className="text-white bg-dark">
             <th>Title</th>
             <th>Uploaded File</th>
             <th>Status</th>
             <th>Assessor Remark</th>
             <th>Vendor Remark</th>
             
             
            
         </tr>
     </thead>
     <tbody>
        
    {selectedVendorDetails && (
 
 <h6 className="px-2 pt-2">
Nature of Work:<span className='fw-bold'> {selectedVendorDetails.natureOfWork}</span>
</h6>

)}
        {complianceFiledDate && (
              <section className='d-flex px-2'>
    <div>
  {complianceFiledDate ? `Compliance Filed Date: ${complianceFiledDate.slice(0, 10).split('-').reverse().join('-')}` : ""}
</div>
<div>
<button className="mx-2 mb-2 btn btn-sm btn-danger" onClick={handleShow}>
    View Report
    </button>
    </div>
    </section>     
)}

         {renderRows()}
     </tbody>
    



 </table>
 {vendorId && (
 <MonthlyReport showModal={showModal} onClose={handleClose}   selectedMonth={selectedMonth} 
  selectedYear={selectedYear} />)}
 </>
  )
}

export default MonthlyDataTableClient
