import React from 'react'
import { IoIosAddCircle } from "react-icons/io";
import { FaMinusCircle } from "react-icons/fa";
import { useState } from "react"
import faqIcon from "../Images/faq.png"
import NavBar from '../layout/NavBar';
import { faQ } from '@fortawesome/free-solid-svg-icons';
import Footer from '../layout/Footer';

const FAQs = () => {
    const [faqOneOpen, setFagOneOpen] = useState(false)
    const [faqTwoOpen, setFagTwoOpen] = useState(false)
    const [faqThreeOpen, setFaqThreeOpen] = useState(false)
    const [faqFourOpen, setFaqFourOpen] = useState(false)
    const [faqFiveOpen, setFaqFiveOpen] = useState(false)
    const [faqSixOpen, setFaqSixOpen] = useState(false)
    const [faqSevenOpen, setFaqSevenOpen] = useState(false)
    const [faqEightOpen, setFaqEightOpen] = useState(false)
    const [faqNineOpen, setFaqNineOpen] = useState(false)
    const [faqTenOpen, setFaqTenOpen] = useState(false)
    const [faqElevenOpen, setFaqElevenOpen] = useState(false)
    const [faqTwelveOpen, setFaqTwelveOpen] = useState(false)
    const [faqThirteenOpen, setFaqThirteenOpen] = useState(false)
    const [faqFourteenOpen, setFaqFourteenOpen] = useState(false)
    const [faqFifteenOpen, setFaqFifteenOpen] = useState(false)
    const [faqSixteenOpen, setFaqSixteenOpen] = useState(false)
    const [faqSeventeenOpen, setFaqSeventeenOpen] = useState(false)
    const [faqEighteenOpen, setFaqEighteenOpen] = useState(false)
    const [faqNineteenOpen, setFaqNineteenOpen] = useState(false)
    const [faqTwentyOpen, setFaqTwentyOpen] = useState(false)
    const [FaqTwentyOneOpen, setFaqTwentyOneOpen] = useState(false)
    



    



  return (
    <>
    <NavBar/>
       <div className='p-12 grid grid-cols-2  gap-x-24'>
      <div className='w-[100%] py-16'>
        <h1 className='text-6xl fw-bolder text-[#B22222]'>Frequently Asked <br/> questions</h1>
        <div>
          <h1 className='text-center mt-3 text-primary fs-5'>General Questions</h1>
          <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">What is GRC@Pulse?</h1>
              {faqFourOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqFourOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqFourOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqFourOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">GRC@Pulse is a Governance, Risk, and Compliance (GRC) management platform designed to help organizations streamline risk assessment, compliance monitoring, and regulatory reporting.</h1>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">Who can use GRC@Pulse?</h1>
              {faqFiveOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqFiveOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqFiveOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqFiveOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">Organizations of all sizes across various industries, including finance, healthcare, technology, and manufacturing, can use GRC@Pulse to manage their GRC processes effectively.</h1>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">How does GRC@Pulse help with compliance management?</h1>
              {faqSixOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqSixOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqSixOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqSixOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">It automates compliance tracking, provides real-time reporting, and ensures adherence to industry regulations such as GDPR, HIPAA, ISO 27001, and others.</h1>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">As a Vendor to various organizations, why should i register on GRC@Pulse?</h1>
              {faqSevenOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqSevenOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqSevenOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqSevenOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">Registering on <span className='fw-bolder'>GRC@Pulse</span>  can offer several strategic advantages, including streamlined compliance management, enhanced credibility, and improved risk assessment.</h1>
            </section>
        </div>
        <div
            className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-2`}
          >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">How does registering on GRC@Pulse increase my business credibility?</h1>
              {faqEightOpen ? (
                <FaMinusCircle fontSize="1.6rem" onClick={() => setFaqEightOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqEightOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqEightOpen ? "block" : "hidden"}`}
            >
              <ul className="list-disc pl-6 space-y-4">
                <li className="text-xs">
                Being on a recognized GRC platform reassures clients that you follow best practices in governance, risk, and compliance.
                </li>
                <li className="text-xs">
                Reduces due diligence time, as clients can verify your compliance status instantly.
                </li>
                <li className="text-xs">
                Helps meet vendor assessment requirements, making you a preferred supplier.
                </li>
              </ul>
            </section>
        </div>
        </div>

        <div>
          <h1 className='text-center mt-3 text-primary fs-5'>Features & Functionality Questions</h1>
          <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">How can I understand the functions of GRC@Pulse?</h1>
              {faqNineOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqNineOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqNineOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqNineOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">Please click on Contact Us - fill up the form and mail to support@grcpulse.in You can also Request a Demo.</h1>
            </section>
        </div>
        <div
            className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-2`}
          >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">What are the key features of GRC@Pulse?</h1>
              {faqTenOpen ? (
                <FaMinusCircle fontSize="1.6rem" onClick={() => setFaqTenOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqTenOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqTenOpen ? "block" : "hidden"}`}
            >
              <ul className="list-disc pl-6 space-y-4">
                <li className="text-xs">
                Risk assessment and mitigation
                </li>
                <li className="text-xs">
                Policy and compliance management
                </li>
                <li className="text-xs">
                Incident tracking and reporting
                </li>
                <li className="text-xs">
                Audit and control monitoring
                </li>
                <li className="text-xs" >
                Real-time dashboards and analytics
                </li>
              </ul>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">Can GRC@Pulse help with regulatory audits?</h1>
              {faqElevenOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqElevenOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqElevenOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqElevenOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">Yes, it provides automated audit reports, compliance checklists, and reporting features to simplify regulatory audits.</h1>
            </section>
        </div>
        </div>

        <div>
        <h1 className='text-center mt-3 text-primary fs-5'>Security & Compliance Questions</h1>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">How secure is GRC@Pulse?</h1>
              {faqTwelveOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqTwelveOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqTwelveOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqTwelveOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">It employs industry-standard encryption, multi-factor authentication, and continuous monitoring to ensure data security and compliance.</h1>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">Is GRC@Pulse cloud-based or on-premises?</h1>
              {faqThirteenOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqThirteenOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqThirteenOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqThirteenOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">GRC@Pulse is primarily a cloud-based solution but with sufficient security requirements.</h1>
            </section>
        </div>
       </div>

       <div>
        <h1 className='text-center mt-3 text-primary fs-5'>GRC@Pulse User Questions</h1>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">How can I start using GRC@Pulse?</h1>
              {faqFourteenOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqFourteenOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqFourteenOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqFourteenOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">You will need user id and password to be provided by the administrator.</h1>
              <h1 className="text-xs">First please send your contact details to support@grcpulse.in</h1>
              <h1 className="text-xs">The administrator will send you a form requesting for details.</h1>
              <h1 className="text-xs">Once the details are received, your user id and login details will be created.</h1>
              <h1 className="text-xs">Administrator will complete your profile with details of organization and compliance requirements.</h1>
              <h1 className="text-xs">There will be a mail informing you about the log in credentials along with your role as a Client, Vendor and Assessor.</h1>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">Explain the roles of Client, Vendor and Assessor.</h1>
              {faqFifteenOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqFifteenOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqFifteenOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqFifteenOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs"><span className='fw-bolder'>CLIENT</span> – Represents the organization which requires Compliance Assurance Services.</h1>
              <h1 className="text-xs"><span className='fw-bolder'>VENDOR</span> – Organization whose Compliance is to be assessed.</h1>
              <h1 className="text-xs"><span className='fw-bolder'>ASSESSOR</span> – Expert who assesses the compliance status.</h1>

            </section>
        </div>
       </div>

       <div>
        <h1 className='text-center mt-3 text-primary fs-5'>Implementation & Support Questions</h1>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
          <section className="flex justify-between items-center">
            <h1 className="font-bold">How long does it take to implement GRC@Pulse?</h1>
            {faqSixteenOpen ? (
              <FaMinusCircle fontSize="1.6rem"
                onClick={() => setFaqSixteenOpen(false)} />
            ) : (
              <IoIosAddCircle
                fontSize="1.6rem"
                onClick={() => setFaqSixteenOpen(true)}
              />
            )}
          </section>
          <section
            className={`mt-2 transition-all duration-300 ${faqSixteenOpen ? "block" : "hidden"}`}
          >
            <h1 className="text-xs">Implementation time varies based on the organization’s size and requirements, but most deployments take between 4 to 8 weeks.</h1>
          </section>
        </div>
        <div
          className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-2`}
        >
          <section className="flex justify-between items-center">
            <h1 className="font-bold">Does GRC@Pulse offer training for users?</h1>
            {faqSeventeenOpen ? (
              <FaMinusCircle fontSize="1.6rem" onClick={() => setFaqSeventeenOpen(false)} />
            ) : (
              <IoIosAddCircle
                fontSize="1.6rem"
                onClick={() => setFaqSeventeenOpen(true)}
              />
            )}
          </section>
          <section
            className={`mt-2 transition-all duration-300 ${faqSeventeenOpen ? "block" : "hidden"}`}
          >
            <h1 className="text-xs">Yes, we provide onboarding sessions, training workshops, and self-help documentation to ensure users can maximize the platform’s benefits.</h1>
          </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
          <section className="flex justify-between items-center">
            <h1 className="font-bold">What kind of support does GRC@Pulse provide?</h1>
            {faqEighteenOpen ? (
              <FaMinusCircle fontSize="1.6rem"
                onClick={() => setFaqEighteenOpen(false)} />
            ) : (
              <IoIosAddCircle
                fontSize="1.6rem"
                onClick={() => setFaqEighteenOpen(true)}
              />
            )}
          </section>
          <section
            className={`mt-2 transition-all duration-300 ${faqEighteenOpen ? "block" : "hidden"}`}
          >
            <h1 className="text-xs">We offer 24/7 customer support via email, chat, and phone, along with a dedicated knowledge base and community forums.</h1>
          </section>
        </div>
      </div>

      <div>
        <h1 className='text-center mt-3 text-primary fs-5'>Pricing & Licensing Questions</h1>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">What are the pricing plans for GRC@Pulse?</h1>
              {faqNineteenOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqNineteenOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqNineteenOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqNineteenOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">Pricing varies based on the number of users and features required. Contact our sales team for a customized quote.</h1>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">Is there a free trial available?</h1>
              {faqTwentyOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqTwentyOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqTwentyOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqTwentyOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">Yes, we offer a free trial for a limited period to help organizations evaluate the platform before committing.</h1>

            </section>
        </div>
      </div>

      <div>
        <h1 className='text-center mt-3 text-primary fs-5'>Compliance to Labour Laws Questions</h1>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">What is HR statutory compliance?</h1>
              {faqOneOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFagOneOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFagOneOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqOneOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">HR statutory compliance refers to the adherence of organizations to the labor laws and regulations set by the government to ensure the welfare of employees and promote fair practices. Employers must comply with these regulations to avoid penalties, legal disputes, and ensure smooth operations. </h1>
            </section>
        </div>
        <div className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-4`} >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">Which labor laws apply to small businesses in India?</h1>
              {faqTwentyOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqTwentyOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqTwentyOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqTwentyOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">
                <ul className="list-disc pl-6 space-y-4">
                  <li>The Shops and Establishments Act: Governs working hours, leave policies, and employee conditions for establishments.</li>
                  <li>The Minimum Wages Act, 1948: Ensures employees receive the legally mandated minimum wages.</li>
                  <li>The Equal Remuneration Act, 1976: Prevents gender-based wage discrimination.</li>
                  <li>The Employees’ Provident Funds and Miscellaneous Provisions Act, 1952: Mandates EPF contributions by employers and employees.</li>
                  <li>The Employees’ State Insurance Act, 1948 (ESI): Provides medical and financial benefits to employees in case of sickness, maternity, or work-related injuries.</li>
                  <li>The Income Tax Act, 1961 Employers must deduct TDS (Tax Deducted at Source) from employees’ salaries.</li>
                  <li>The Professional Tax Act Applicable in certain states, employers must deduct and remit professional tax.</li>
                  <li>The Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013 (POSH Act): Requires organizations to set up Internal Complaints Committees and prevent harassment.</li>
                </ul>
              </h1>

            </section>
        </div> 
        <div
            className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-2`}
          >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">How often should compliance audits be conducted?</h1>
              {faqThreeOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqThreeOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqThreeOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${faqThreeOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">
                Monthly, Quarterly and Annual audits with the scope of audit being different each time.              </h1>
            </section>
        </div>
        <div
            className={`shadow-card-shadow w-full p-2 transition-all duration-300 mt-2`}
          >
            <section className="flex justify-between items-center">
              <h1 className="font-bold">What’s the validity of various Licenses obtained for the above Acts?</h1>
              {FaqTwentyOneOpen ? (
                <FaMinusCircle fontSize="1.6rem"
                  onClick={() => setFaqTwentyOneOpen(false)} />
              ) : (
                <IoIosAddCircle
                  fontSize="1.6rem"
                  onClick={() => setFaqTwentyOneOpen(true)}
                />
              )}
            </section>
            <section
              className={`mt-2 transition-all duration-300 ${FaqTwentyOneOpen ? "block" : "hidden"}`}
            >
              <h1 className="text-xs">
              The validity of each License varies according to the Act requirements. The License needs to be Renewed / Amended / Surrendered as per the specified conditions and timelines.</h1>
            </section>
        </div>
      </div>

       
    </div>
    <div className='w-[100%] h-screen  align-ceter'>
        <img src={faqIcon}alt="" className='h-[600px] w-[600px] pt-5'/>
    </div>
</div>
<Footer/>
    </>
  )
}

export default FAQs