import Navbar from "../layout/NavBar"
import aboutUsOne from "../Images/about-us-one.png"
import aboutUsTwo from "../Images/about-us-two.png"
import aboutUsThree from "../Images/about-us-three.png"
import aboutUsFour from "../Images/about-us-four.png"
import aboutUsFive from "../Images/about-us-five.png"
import Footer from "../layout/Footer"
import wwpOneLogo from "../Images/wwd1.png"
import goalsLogo from "../Images/goals-logo.png"
import engLogo from "../Images/eng-about-us.png"
// import teamMemberOne from "../Images/ArunMisra_image-PPT.png"
// import teamMembertwo from "../Images/SrinivasTanuku_Image-PPT.png"
// import teamMemberThree from "../Images/Uday_Guha_image-PPT.png"
// import teamMemberFour from "../Images/Uday_Guha_image-PPT.png"
// import teamMemberFive from "../Images/latitendu.png"
// import teamMemberSix from "../Images/sahu.png"
import { IoIosAddCircle } from "react-icons/io";
import { FaMinusCircle } from "react-icons/fa";
import { useEffect, useState } from "react"
import faqIcon from "../Images/faq.png"
import { useLocation } from "react-router-dom"
import { elements } from "chart.js"

const AboutUs = () => {
  const [faqOneOpen, setFagOneOpen] = useState(false)
  const [faqTwoOpen, setFagTwoOpen] = useState(false)
  const [faqThreeOpen, setFaqThreeOpen] = useState(false)
  

  return (
    <>
      <Navbar />
      <div className="p-12">
        <div className = "pt-4">
          <h1 className="pt-4 text-7xl text-[#B22222]">
            About Us
          </h1>
          <div className="lg:flex xl:flex justify-between mt-4">
            <p className="lg:w-[50%] xl:w-[50%]">
              <h1 className="text-xl">
                People Process Teck Private Limited is a leading provider of HR and productivity interventions, offering a comprehensive range of services, including staffing solutions, HR statutory compliance, HR consultancy, and seamless collaboration with business partners and consultancy teams across diverse sectors and industries.
              </h1>
              <h1 className="mt-2 font-bold text-xl">
                GRC@Pulse – Ensuring compliance and assurance with robust security measures.
              </h1>
            </p>
            <div className="flex flex-col lg:flex-row xl:flex-row gap-3">
              <img src={aboutUsOne} alt="" className="w-[150px] h-[200px]" />
              <img src={aboutUsTwo} alt="" className="w-[150px] h-[200px]" />
              <img src={aboutUsFour} alt="" className="w-[150px] h-[200px]" />
            </div>
          </div>
            <div className="lg:flex xl:flex justify-between mt-4">
              <p className="lg:w-[100%] xl:w-[50%]">
                <h1 className="text-xl ">
                  Governance, Risk, and Compliance (GRC) is an overarching component of a sustainable organization. In today's landscape of ever-evolving rules and regulations, the HR function faces the extraordinarily complex task of managing GRC, which stands as one of the most critical aspects of overall corporate governance.
                </h1>
              </p>
              <div className="flex gap-2 w-full md:w-[50%] justify-end mt-4 md:mt-0">
                <img src={aboutUsFive} alt="" className="w-[450px] h-[200px]" />
                <img src={aboutUsThree} alt="" className="w-[150px] h-[200px]" />
              </div>
            </div>
        </div>
        
      </div>
      <div className="grid grid-cols-2 mt-4">
        <div className="flex justify-center gap-4">
          <img src={wwpOneLogo} alt="" className="w-full h-[525px] mt-[10px] rounded-tl-[50px] rounded-br-[50px]" />
        </div>
        <div className="flex flex-col gap-4 justify-center p-[20px]">
          <h1 className="font-bold text-2xl text-center mt-2 text-[#B22222]">What We Do</h1>
          <p className="text-center text-xl">
            Governance, Risk, and Compliance (GRC) is vital for a sustainable organization, especially with the complexities of HR governance in today’s ecosystem of outsourced business models. A proactive assessment of HR service providers is crucial to avoid penalties, shutdowns, and reputational risks that weaken investor confidence.
          </p>
          <p className="text-center text-xl">
            People Process Teck introduces GRC@Pulse, an AI-based assessment tool for evaluating HR vendors and service providers. Designed for all sectors, it ensures structured and periodic evaluations to strengthen corporate governance.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 mt-[20px]">
        <div className="flex pl-[50px] justify-center flex-col p-[10px]">
          <h1 className="font-bold text-2xl mt-[50px] text-[#B22222]">The GRC@Pulse Assessment Tool offers</h1>
          <ul className="space-y-4 list-disc list-inside mt-4">
            <li className="text-xl">Reviewing governance, risk, and compliance to laws of the land.</li>
            <li className="text-xl">Delivering reports with compliance status, gaps, and improvement recommendations.</li>
            <li className="text-xl">Offering guidance to strengthen corporate governance.</li>
          </ul>
        </div>
        <div className="flex justify-end p-[20px]">
          <img src={goalsLogo} alt="" className="w-[400px] h-[400px]" />
        </div>
      </div>
      <div className="grid grid-cols-2 mt-4 gap-4">
        <div className="flex justify-center items-center">
          <img src={engLogo} alt="" className="w-[500px] h-[500px]" />
        </div>
        <div className="flex flex-col items-center mt-5">
          <h1 className="text-2xl font-bold text-[#B22222]">Our Engagements</h1>
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex gap-4 items-center ">
              <span className="min-w-[5px] h-[40px] bg-[#B22222] inline-block"></span>
              <h1 className="text-xl">Continuous evaluation and monitoring of compliance status.</h1>
            </div>
            <div className="flex gap-4 items-center ">
              <span className="min-w-[5px] h-[40px] bg-[#B22222] inline-block"></span>
              <h1 className="text-xl">
                Our Assessors are experienced professionals well versed in HR Legal and Statutory matters.                </h1>
            </div>
            <div className="flex gap-4 items-center ">
              <span className="min-w-[5px] h-[40px] bg-[#B22222] inline-block"></span>
              <h1 className="text-xl">
              Providing expert advice and actions for resolving compliance issues .</h1>
            </div>
          </div>
        </div>
      </div>
      
        {/* <h1 className="text-[#B22222] font-bold text-2xl text-center">Meet Our Team</h1>
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 mt-4 gap-4 px-4">
          <section className="flex flex-col items-center shadow-card-shadow-dark h-[600px] py-[30px] px-[30px]">
            <img src={teamMemberOne} alt="" className="w-[175px] h-[175px]" />
            <h1 className="mt-2 text-[#B22222] font-bold text-2xl">Arun K Misra</h1>
            <h1 className="mt-2">Chief Executive</h1>
            <p className="text-base text-center mt-4 leading-6">A versatile and tech savvy HR Professional with exposure to various sectors and having worked with some of the premier organizations in India.</p>
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full w-[30px] h-[30px] text-center text-white font-bold text-xl bg-[#b22222] mt-[40px]"
            >
              in
            </a>
          </section>
          <section className="flex flex-col items-center shadow-card-shadow-dark h-[600px] py-[30px] px-[30px]">
            <img src={teamMembertwo} alt="" className="w-[175px] h-[175px]" />
            <h1 className="mt-2 text-[#B22222] font-bold text-2xl">Srinivas Tanuku</h1>
            <h1 className="mt-2">Director-Productivity & Staffing</h1>
            <p className="text-base text-center mt-4 leading-6">
              A competent Industrial Engineering professional with over 25 years of experience in the areas of Lean Management, Operational Excellence, Organization Effectiveness, Resource optimization, Process improvements.
            </p>
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full w-[30px] h-[30px] text-center text-white font-bold text-xl bg-[#b22222] mt-[40px]"
            >
              in
            </a>
          </section>
          <section className="flex flex-col items-center shadow-card-shadow-dark h-[600px] py-[30px] px-[30px]">
            <img src={teamMemberThree} alt="" className="w-[175px] h-[175px]" />
            <h1 className="mt-2 text-[#B22222] font-bold text-2xl">Uday Guha</h1>
            <h1 className="mt-2">Chief Executive</h1>
            <p className="text-base text-center mt-4 leading-6">A versatile and tech savvy HR Professional with exposure to various sectors and having worked with some of the premier organizations in India.</p>
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full w-[30px] h-[30px] text-center text-white font-bold text-xl bg-[#b22222] mt-[40px]"
            >
              in
            </a>
          </section>
          <section className="flex flex-col items-center shadow-card-shadow-dark h-[600px] py-[30px] px-[30px]">
            <img src={teamMemberFour} alt="" className="w-[175px] h-[175px]" />
            <h1 className="mt-2 text-[#B22222] font-bold text-2xl">Neeraj N Mathur</h1>
            <h1 className="mt-2">Director</h1>
            <p className="text-base text-center mt-4 leading-6">
              An entrepreneur with a track record of over 28 years in travel media & localization industry, is a management graduate from New Delhi.</p>
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full w-[30px] h-[30px] text-center text-white font-bold text-xl bg-[#b22222] mt-[40px]"
            >
              in
            </a>
          </section>
          <section className="flex flex-col items-center shadow-card-shadow-dark h-[600px] py-[30px] px-[30px]">
            <img src={teamMemberFive} alt="" className="w-[175px] h-[175px]" />
            <h1 className="mt-2 text-[#B22222] font-bold text-2xl">Lalitendu Samanta</h1>
            <h1 className="mt-2">Director- HR</h1>
            <p className="text-base text-center mt-4 leading-6">
              Ex-Tata Motors
            </p>
            <p className="text-base text-center mt-4 leading-6">
              Ex-Delhi International Airport
            </p>
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full w-[30px] h-[30px] text-center text-white font-bold text-xl bg-[#b22222] mt-[40px]"
            >
              in
            </a>
          </section>
          <section className="flex flex-col items-center shadow-card-shadow-dark h-[600px] py-[30px] px-[30px]">
            <img src={teamMemberSix} alt="" className="w-[175px] h-[175px]" />
            <h1 className="mt-2 text-[#B22222] font-bold text-2xl">Gyanaranjan Sahu</h1>
            <h1 className="mt-2">Director</h1>
            <p className="text-base text-center mt-4 leading-6">
              Ex-ACC
            </p>
            <p className="text-base text-center mt-4 leading-6">
              Ex- Adani Group
            </p>
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full w-[30px] h-[30px] text-center text-white font-bold text-xl bg-[#b22222] mt-[40px]"
            >
              in
            </a>
          </section>
        </div> */}
    
      <Footer />
    </>)
}

export default AboutUs