import React from 'react';
import { Navigate } from 'react-router-dom';

// Private Route component to protect routes
const PrivateRoute = ({ element, ...rest }) => {
  const isLoggedIn = sessionStorage.getItem('Token') !== null;

  // If the user is not logged in, redirect to login page
  return isLoggedIn ? element : <Navigate to="/signin" replace />;
};

export default PrivateRoute;
