import {React, useState, useEffect} from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import grclogo from '../Images/GRC-Pulse-Logo.png'
import pptlogo from '../Images/PPT-Logo.png'
import { PieChart} from '@mui/x-charts';
import api from '../Services/api';
import { COLORS } from '../Component/colors';

import { parse } from '@fortawesome/fontawesome-svg-core';
// import { Pie } from 'react-chartjs-2';


const MonthlyReport = ({showModal,onClose,selectedMonth, selectedYear}) => {

    const [vendors, setVendors] = useState([]);
    const [monthly, setMonthly] = useState([]);
    const data = sessionStorage.getItem('user');
    const dataType = JSON.parse(data);
    const userType = dataType?.type;
    const companyId = dataType?.id; 
  const selectedVendorId = sessionStorage.getItem('selectedVendorId');
 
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const month = monthNames[selectedMonth - 1];

  const id = userType === 'vendor' ? companyId : selectedVendorId;

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await api.vendor.fetchVendorDetailsById(id);
        setVendors([response.data.data]); 
      } catch (error) {
        console.error('Error fetching vendor data:', error);
      }
    };

    fetchVendors();
  }, [id]);

  useEffect(() => {
    const fetchVendorsMonth = async () => {
      try {
        const response = await api.vendor.getMonthlyData(id,selectedMonth,selectedYear);
        setMonthly(response.data);
      } catch (error) {
        console.error('Error fetching vendor data:', error);
    
      }
    };

    fetchVendorsMonth();
  }, [id,selectedMonth,selectedYear]);


const generatePDF = () => {
    const input = document.getElementById('report');

    html2canvas(input).then((canvas) => {
        const pdf = new jsPDF();
        const imgWidth = pdf.internal.pageSize.width - 40; // Width considering left and right padding
        const pageHeight = pdf.internal.pageSize.height;
        const padding = 20; // Padding for all sides
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Calculate total height accounting for padding
        const effectiveHeight = imgHeight + 2 * padding; 
        const totalPages = Math.ceil(effectiveHeight / pageHeight);

        for (let i = 0; i < totalPages; i++) {
            // Add a new page if it's not the first one
            if (i > 0) {
                pdf.addPage();
            }

            // Calculate position for the image, ensuring padding is applied
            const position = -(i * pageHeight) + padding;

            // Draw the image for the current page, ensuring left padding is applied
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', padding, position, imgWidth, imgHeight);
        }

        // Save the PDF
        pdf.save(`${month}, ${selectedYear} Audit Report`);
    });
};

  return (
    <Modal show={showModal} onHide={onClose} size="lg" centered>
       {/* <Modal.Header closeButton style={{color:"black"}}></Modal.Header> */}
    <div style={{marginTop:'90px'}}>
      <div id="report" style={{ padding: '20px', width: '210mm', minHeight: '297mm', boxSizing:"border-box" }}>
        <div className='mb-2 d-flex justify-content-between'>
      <img
              src={grclogo}
              width="130px"
              alt="Company Logo"
             
            ></img>

            <img
             src={pptlogo}
             width="200px"
              alt="Company Logo"
             
           ></img>
           </div>


        <h1 className="pb-3" style={{ textAlign:"center",fontWeight:'normal',fontSize:'25px'}}>Audit Report Summary</h1>

        {/* {Array.isArray(vendors) && vendors.map((vendor) => (
        <h4 style={{ textAlign:"center"}}>Assessor Audit Conducted For : {vendor.vendorCompanyName}</h4> ))} */}
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '25px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>Vendor Name</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>State</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Compnay</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Address</th>
              {/* <th style={{ border: '1px solid black', padding: '8px' }}>Project Start Date</th> */}
              <th style={{ border: '1px solid black', padding: '8px' }}>Period of Audit</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Audited On</th>
              {/* <th style={{ border: '1px solid black', padding: '8px' }}>Project Closing Date</th> */}
            </tr>
          </thead>
          <tbody>
          {Array.isArray(vendors) && vendors.map((vendor, INDEX) => (
            <tr>
              <td style={{ border: '1px solid black', padding: '8px' }}>{vendor.vendorName}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{vendor.vendorStateId}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{vendor.vendorCompanyName}</td>
             
              <td style={{ border: '1px solid black', padding: '8px' }}>{vendor.vendorCompanyAddress}, {vendor.vendorPlaceName}, {vendor.vendorStateId}-{vendor.vendorPinCode}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
              {monthly?.data?.month.replace(/^(\d{2})$/, (_, month) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return months[parseInt(month) - 1];
  })}-{monthly?.data?.year}
  {/* {monthly.data.month}-{monthly.data.year} */}
  </td>
              {/* <td style={{ border: '1px solid black', padding: '8px' }}>{vendor.vendorContractStartDate.slice(0, 10)}</td> */}
              <td style={{ border: '1px solid black', padding: '8px' }}>
  {monthly?.data?.createdAt.slice(5, 7).replace(/^(\d{2})$/, (_, month) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return months[parseInt(month) - 1];
  })}-{monthly?.data?.createdAt.slice(0, 4)}
</td>


              {/* <td style={{ border: '1px solid black', padding: '8px' }}>{vendor.vendorContractEndDate.slice(0, 10)}</td> */}
            </tr>  
             ))}
          </tbody>
        </table>
      
    
    <div>
      <h4 style={{ textAlign:"center", backgroundColor:'black', padding:"8px",color:'whitesmoke'}}>Overall Compliance Score in Percentage</h4>
    <PieChart
      series={[
        {
          data: [
            { value: monthly?.AcceptedPercent, color:'#7cb342', label: `Complied ${monthly?.AcceptedPercent}%` },
            { value: monthly?.RejectedPercent,  color:'#f44336', label: `Not Complied ${monthly?.RejectedPercent}%` },
            { value: monthly?.UnderReviewPercent,  color: '#bdbdbd', label: `Under Review ${monthly?.UnderReviewPercent}%` },
          ],
        },
      ]}
      width={600}
      height={250}
    />

    </div>

        <table style={{ width: '100%', borderCollapse: 'collapse' ,marginBottom:'25px'}}>
              <thead>
                    <tr>
                      <th colSpan="3" style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: 'bold', backgroundColor:'#1C1C1C',color:'whitesmoke'}}>
                         Compliances Status Count
                      </th>
                    </tr>
                    <tr>
                     <th  className="text-center" style={{ border: '1px solid black', padding: '8px' }}>Compliances Cleared</th>
                     <th className="text-center" style={{ border: '1px solid black', padding: '8px' }}>Compliances Not Cleared</th>
                     {/* <th style={{ border: '1px solid black', padding: '8px' }}>Compliances Not Applicable</th> */}
                    </tr>
              </thead>
              <tbody>
                {monthly ? (
                  
                    <tr>
                     <td className="text-center" style={{ border: '1px solid black', padding: '8px' ,color:"green",fontWeight:"bold"}}>{monthly.complied}</td>
                     <td  className="text-center"  style={{ border: '1px solid black', padding: '8px' ,color:'red',fontWeight:"bold"}}>{monthly.nonComplied}</td>
                     {/* <td  className="text-center"  style={{ border: '1px solid black', padding: '8px' ,color:"gray",fontWeight:"bold"}}>{monthly.notApplicable}</td> */}
                    </tr>
                ):(<p>Loading Data....</p>)}

              </tbody>
        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '25px' }}>
              <thead>
                    <tr>
                      <th colSpan="3" style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: 'bold', backgroundColor:'#1C1C1C',color:'whitesmoke' }}>
                      Summary Of Audit Observations 
                      </th>
                    </tr>
                    <tr>
                     <th style={{ border: '1px solid black', padding: '8px' }}>Auditor Observation </th>
                     <th style={{ border: '1px solid black', padding: '8px' }}>Auditor Recommendation</th>
                    </tr>
              </thead>
              <tbody>
                {monthly && monthly.data ? (

                    <tr>
                     <td style={{ border: '1px solid black', padding: '8px' }}>{monthly.data.auditorObservation
  .replace(/(\d+\.\s*)/g, '$1\n')} </td>
                     <td style={{ border: '1px solid black', padding: '8px' }}>{monthly.data.auditorRecommendation}</td>
                    </tr>
                ):((<p>loading data</p>)) }
              </tbody>

        </table>

        <table  style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '25px' }}>
            <thead>
                <tr>
                    <th colSpan="3" style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: 'bold', backgroundColor:'#1C1C1C',color:"whitesmoke" }}>
                    Objectives Of The Audit </th>
                </tr>
            </thead>
            <tbody>
              {monthly && monthly.data ? (

                    <tr>
                     <td style={{ border: '1px solid black', padding: '8px' }}>{monthly.data.objectivesOfTheAudit}</td>
                    </tr>
              ):(<p>loading data</p>)}

              </tbody>
        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '25px' }} >
            <thead>
                <tr>
                    <th colSpan="3" style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: 'bold', backgroundColor:'#1C1C1C',color:"whitesmoke" }} >
                        Scope Of The Audit Review
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} > List Of Acts Covered Under The Audit</td>
                </tr>
            </tbody>
        </table>

        <table style={{ width: '100%', borderCollapse: 'collapse' }} >
            <thead>
                <tr>
                    <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: 'bold', backgroundColor:'#1C1C1C',color:"whitesmoke" }} >
                    List Of Internal Compliances
                    </th>
                </tr>
          
                <tr>
                    <th style={{ border: '1px solid black', padding: '8px' }} >Internal Compliance</th>
                    <th style={{ border: '1px solid black', padding: '8px' }} >Critical</th>
                    <th style={{ border: '1px solid black', padding: '8px' }} >Risk</th>
                    <th style={{ border: '1px solid black', padding: '8px' }} >Nature </th>
                    {/* <th style={{ border: '1px solid black', padding: '8px' }} >Consider ForScore</th> */}
                    <th style={{ border: '1px solid black', padding: '8px' }} >Status</th>
                </tr>

                <tr>
                    <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }}>The Contract Labour (Regulation & Abolition) Act, 1970</th>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Combined Muster Roll-cum-Register of Wages</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.combinedMusterRollCumRegisterOfWages?.validation === 1  ? 'gray' : 
      monthly?.data?.combinedMusterRollCumRegisterOfWages?.validation === 2 ? 'green' : 
      monthly?.data?.combinedMusterRollCumRegisterOfWages?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.combinedMusterRollCumRegisterOfWages?.validation === 2  && monthly?.data?.combinedMusterRollCumRegisterOfWages?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.combinedMusterRollCumRegisterOfWages?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.combinedMusterRollCumRegisterOfWages?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.combinedMusterRollCumRegisterOfWages?.validation === 1   ? "Under Review" : "N/A"}</td>
                
               </tr>   
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register of Workmen</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Medium</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.RegisterOfWorkmen?.validation === 1  ? 'gray' : 
      monthly?.data?.RegisterOfWorkmen?.validation === 2 ? 'green' : 
      monthly?.data?.RegisterOfWorkmen?.validation === 3  ? 'red' : 
      'gray' }} >{ (monthly?.data?.RegisterOfWorkmen?.validation === 2  && monthly?.data?.RegisterOfWorkmen?.na === true )  ? "Not applicable, but deemed acceptable."
                                                                               : monthly?.data?.RegisterOfWorkmen?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.RegisterOfWorkmen?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.RegisterOfWorkmen?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr>   
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Leave Register /Leave Book</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.leaveRegisterLeaveBook?.validation === 1 ? 'gray' : 
      monthly?.data?.leaveRegisterLeaveBook?.validation === 2 ? 'green' : 
      monthly?.data?.leaveRegisterLeaveBook?.validation === 3 ? 'red' : 
      'gray' }} >{(monthly?.data?.leaveRegisterLeaveBook?.validation === 2  && monthly?.data?.leaveRegisterLeaveBook?.na === true )  ? "Not applicable, but deemed acceptable."
                                                                               : monthly?.data?.leaveRegisterLeaveBook?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.leaveRegisterLeaveBook?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.leaveRegisterLeaveBook?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr>   
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Employment Card</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.EmploymentCard?.validation === 1 ?'gray' : 
      monthly?.data?.EmploymentCard?.validation === 2 ? 'green' : 
      monthly?.data?.EmploymentCard?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.EmploymentCard?.validation === 2  && monthly?.data?.EmploymentCard?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.EmploymentCard?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.EmploymentCard?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.EmploymentCard?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr>  
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Wage Slip</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.WageSlip?.validation === 1  ? 'gray' : 
      monthly?.data?.WageSlip?.validation === 2 ? 'green' : 
      monthly?.data?.WageSlip?.validation === 3 ? 'red' : 
      'gray'  }} >{(monthly?.data?.WageSlip?.validation === 2  && monthly?.data?.WageSlip?.na === true )  ? "Not applicable, but deemed acceptable."
                                                                               : monthly?.data?.WageSlip?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.WageSlip?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.WageSlip?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr>   
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Service Certificate for Left Employees</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Medium</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.ServiceCertificateForLeftEmployees?.validation === 1 ? 'gray' : 
      monthly?.data?.ServiceCertificateForLeftEmployees?.validation === 2 ? 'green' : 
      monthly?.data?.ServiceCertificateForLeftEmployees?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.ServiceCertificateForLeftEmployees?.validation === 2  && monthly?.data?.ServiceCertificateForLeftEmployees?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.ServiceCertificateForLeftEmployees?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.ServiceCertificateForLeftEmployees?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.ServiceCertificateForLeftEmployees?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr>   
               

               <tr>
                    <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }}> Generic </th>
                </tr>
                <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >In and out time Register</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >No</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Low</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.inAndOutTimeRegister?.validation === 1 ?'gray' : 
      monthly?.data?.inAndOutTimeRegister?.validation === 2 ? 'green' : 
      monthly?.data?.inAndOutTimeRegister?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.inAndOutTimeRegister?.validation === 2  && monthly?.data?.inAndOutTimeRegister?.na === true )  ? "Not applicable, but deemed acceptable."
                                                                               : monthly?.data?.inAndOutTimeRegister?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.inAndOutTimeRegister?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.inAndOutTimeRegister?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr> 
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Bank Statement for Salary Credit Confirmation</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >General </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.BankStatementOrSalaryCreditConfirmation?.validation === 1 ? 'gray' : 
      monthly?.data?.BankStatementOrSalaryCreditConfirmation?.validation === 2 ? 'green' : 
      monthly?.data?.BankStatementOrSalaryCreditConfirmation?.validation === 3 ? 'red' : 
      'gray' }} >{(monthly?.data?.BankStatementOrSalaryCreditConfirmation?.validation === 2  && monthly?.data?.BankStatementOrSalaryCreditConfirmation?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.BankStatementOrSalaryCreditConfirmation?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.BankStatementOrSalaryCreditConfirmation?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.BankStatementOrSalaryCreditConfirmation?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr> 
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >PF Declaration on letterhead</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Low</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >General </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.PFDeclarationOnLetterhead?.validation === 1 ? 'gray' : 
      monthly?.data?.PFDeclarationOnLetterhead?.validation === 2 ? 'green' : 
      monthly?.data?.PFDeclarationOnLetterhead?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.PFDeclarationOnLetterhead?.validation === 2  && monthly?.data?.PFDeclarationOnLetterhead?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.PFDeclarationOnLetterhead?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.PFDeclarationOnLetterhead?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.PFDeclarationOnLetterhead?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr> 
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >ESI Declaration on letterhead</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Low</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >General </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.ESIDeclarationOnLetterhead?.validation === 1 ? 'gray' : 
      monthly?.data?.ESIDeclarationOnLetterhead?.validation === 2 ? 'green' : 
      monthly?.data?.ESIDeclarationOnLetterhead?.validation === 3 ? 'red' : 
      'gray' }} >{(monthly?.data?.ESIDeclarationOnLetterhead?.validation === 2  && monthly?.data?.ESIDeclarationOnLetterhead?.na === true )  ? "Not applicable, but deemed acceptable."
                                                                               : monthly?.data?.ESIDeclarationOnLetterhead?.validation === 2 ? "Complied" 
                                                                               : monthly?.data?.ESIDeclarationOnLetterhead?.validation === 3    ? "Rejected" 
                                                                               : monthly?.data?.ESIDeclarationOnLetterhead?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr> 
               <tr>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Overtime Declaration on letterhead</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >Low</td>
                    <td style={{ border: '1px solid black', padding: '8px' }} >General </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                    <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.OvertimeDeclarationOnLetterhead?.validation === 1 ? 'gray' : 
      monthly?.data?.OvertimeDeclarationOnLetterhead?.validation === 2 ? 'green' : 
      monthly?.data?.OvertimeDeclarationOnLetterhead?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.OvertimeDeclarationOnLetterhead?.validation === 2  && monthly?.data?.OvertimeDeclarationOnLetterhead?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.OvertimeDeclarationOnLetterhead?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.OvertimeDeclarationOnLetterhead?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.OvertimeDeclarationOnLetterhead?.validation === 1   ? "Under Review" : "N/A"}</td>
               </tr>    

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} >Minimum Wages Act, 1936</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Payment done as per revised rate of Minimum wages</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Rule </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.validation === 1 ? 'gray' : 
      monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.validation === 2 ? 'green' : 
      monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.validation === 2  && monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.PaymentDoneAsPerRevisedRateOfMinimumwages?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} > The Employee's Provident Funds & Miscellaneous Provisions Act and Scheme, 1952</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >PF Monthly ECR</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Returns </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.PFMonthlyECR?.validation === 1 ? 'gray' : 
      monthly?.data?.PFMonthlyECR?.validation === 2 ? 'green' : 
      monthly?.data?.PFMonthlyECR?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.PFMonthlyECR?.validation === 2  && monthly?.data?.PFMonthlyECR?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.PFMonthlyECR?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.PFMonthlyECR?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.PFMonthlyECR?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >PF Combined Challan</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance</td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.PFCombinedChallan?.validation === 1 ? 'gray' : 
      monthly?.data?.PFCombinedChallan?.validation === 2 ? 'green' : 
      monthly?.data?.PFCombinedChallan?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.PFCombinedChallan?.validation === 2  && monthly?.data?.PFCombinedChallan?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.PFCombinedChallan?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.PFCombinedChallan?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.PFCombinedChallan?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >PF TRRN Summary</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.PFTRRNSummary?.validation === 1? 'gray' : 
      monthly?.data?.PFTRRNSummary?.validation === 2 ? 'green' : 
      monthly?.data?.PFTRRNSummary?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.PFTRRNSummary?.validation === 2  && monthly?.data?.PFTRRNSummary?.na === true )  ? "Not applicable, but deemed acceptable."
                                                                               : monthly?.data?.PFTRRNSummary?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.PFTRRNSummary?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.PFTRRNSummary?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >IW-1 International Worker Monthly Returns</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Return </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.IW1InternationalWorkerMonthlyReturns?.validation === 1  ? 'gray' : 
      monthly?.data?.IW1InternationalWorkerMonthlyReturns?.validation === 2 ? 'green' : 
      monthly?.data?.IW1InternationalWorkerMonthlyReturns?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.IW1InternationalWorkerMonthlyReturns?.validation === 2  && monthly?.data?.IW1InternationalWorkerMonthlyReturns?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.IW1InternationalWorkerMonthlyReturns?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.IW1InternationalWorkerMonthlyReturns?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.IW1InternationalWorkerMonthlyReturns?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} > The Employees' State Insurance Act and Regulations, 1948</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >ESI-Accident Register</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.ESIAccidentRegister?.validation === 1  ? 'gray' : 
      monthly?.data?.ESIAccidentRegister?.validation === 2 ? 'green' : 
      monthly?.data?.ESIAccidentRegister?.validation === 3 ? 'red' : 
      'gray' }} >{ (monthly?.data?.ESIAccidentRegister?.validation === 2  && monthly?.data?.ESIAccidentRegister?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.ESIAccidentRegister?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.ESIAccidentRegister?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.ESIAccidentRegister?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >ESIC Contribution Challan</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.ESICContributionChallan?.validation === 1 ? 'gray' : 
      monthly?.data?.ESICContributionChallan?.validation === 2 ? 'green' : 
      monthly?.data?.ESICContributionChallan?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.ESICContributionChallan?.validation === 2  && monthly?.data?.ESICContributionChallan?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.ESICContributionChallan?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.ESICContributionChallan?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.ESICContributionChallan?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >ESIC Monthly Statement</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance</td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.ESICMonthlyStatement?.validation === 1? 'gray' : 
      monthly?.data?.ESICMonthlyStatement?.validation === 2 ? 'green' : 
      monthly?.data?.ESICMonthlyStatement?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.ESICMonthlyStatement?.validation === 2  && monthly?.data?.ESICMonthlyStatement?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.ESICMonthlyStatement?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.ESICMonthlyStatement?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.ESICMonthlyStatement?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Pehchan Card (Insured Person)</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Medium</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Rule </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.PehchanCard?.validation === 1? 'gray' : 
      monthly?.data?.PehchanCard?.validation === 2 ? 'green' : 
      monthly?.data?.PehchanCard?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.PehchanCard?.validation === 2  && monthly?.data?.PehchanCard?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.PehchanCard?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.PehchanCard?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.PehchanCard?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px' , textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} > Professional Tax </th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Professional Tax Returns</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.professionalTaxReturns?.validation === 1  ? 'gray' : 
      monthly?.data?.professionalTaxReturns?.validation === 2 ? 'green' : 
      monthly?.data?.professionalTaxReturns?.validation === 3 ? 'red' : 
      'gray'   }}> { (monthly?.data?.professionalTaxReturns?.validation === 2  && monthly?.data?.professionalTaxReturns?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.professionalTaxReturns?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.professionalTaxReturns?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.professionalTaxReturns?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >PT Challan</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance</td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.PTChallan?.validation === 1  ? 'gray' : 
      monthly?.data?.PTChallan?.validation === 2 ? 'green' : 
      monthly?.data?.PTChallan?.validation === 3  ? 'red' : 
      'gray'  }} >{ (monthly?.data?.PTChallan?.validation === 2  && monthly?.data?.PTChallan?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.PTChallan?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.PTChallan?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.PTChallan?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} >The Payment of Bonus Act, 1972</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Monthly Bonus Paid or Not</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.MonthlyBonusPaidOrNot?.validation === 1 ? 'gray' : 
      monthly?.data?.MonthlyBonusPaidOrNot?.validation === 2 ? 'green' : 
      monthly?.data?.MonthlyBonusPaidOrNot?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.MonthlyBonusPaidOrNot?.validation === 2  && monthly?.data?.MonthlyBonusPaidOrNot?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.MonthlyBonusPaidOrNot?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.MonthlyBonusPaidOrNot?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.MonthlyBonusPaidOrNot?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Statutory Bonus Return</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Return </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.statutoryBonusReturn?.validation === 1 ? 'gray' : 
      monthly?.data?.statutoryBonusReturn?.validation === 2 ? 'green' : 
      monthly?.data?.statutoryBonusReturn?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.statutoryBonusReturn?.validation === 2  && monthly?.data?.statutoryBonusReturn?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.statutoryBonusReturn?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.statutoryBonusReturn?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.statutoryBonusReturn?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               
              

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} >Shops And Commercial Establishments Act </th>
               </tr>
              
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Registration Of Establishment</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Certificate</td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.registrationOfEstablishment?.validation === 1 ? 'gray' : 
      monthly?.data?.registrationOfEstablishment?.validation === 2 ? 'green' : 
      monthly?.data?.registrationOfEstablishment?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.registrationOfEstablishment?.validation === 2  && monthly?.data?.registrationOfEstablishment?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.registrationOfEstablishment?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.registrationOfEstablishment?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.registrationOfEstablishment?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Appointment Order In Form Q</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.appointmentOrderInFormQ?.validation === 1 ? 'gray' : 
      monthly?.data?.appointmentOrderInFormQ?.validation === 2 ? 'green' : 
      monthly?.data?.appointmentOrderInFormQ?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.appointmentOrderInFormQ?.validation === 2  && monthly?.data?.appointmentOrderInFormQ?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.appointmentOrderInFormQ?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.appointmentOrderInFormQ?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.appointmentOrderInFormQ?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid gray', padding: '8px' , textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} > The Maternity Benefit Act, 1961</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Muster Roll Of Women Workers</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.musterRollOfWomenWorkers?.validation === 1 ? 'gray' : 
      monthly?.data?.musterRollOfWomenWorkers?.validation === 2 ? 'green' : 
      monthly?.data?.musterRollOfWomenWorkers?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.musterRollOfWomenWorkers?.validation === 2  && monthly?.data?.musterRollOfWomenWorkers?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               :  monthly?.data?.musterRollOfWomenWorkers?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.musterRollOfWomenWorkers?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.musterRollOfWomenWorkers?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px' , textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} > The Employees Compensation Act, 1923</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Employees Compensation Policy</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.employeesCompensationPolicy?.validation === 1 ? 'gray' : 
      monthly?.data?.employeesCompensationPolicy?.validation === 2 ? 'green' : 
      monthly?.data?.employeesCompensationPolicy?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.employeesCompensationPolicy?.validation === 2  && monthly?.data?.employeesCompensationPolicy?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               :  monthly?.data?.employeesCompensationPolicy?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.employeesCompensationPolicy?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.employeesCompensationPolicy?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px' , textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} > The Equal Remuneration Act, 1976</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Equal Remuneration And Register To Be Maintained</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.validation === 1  ? 'gray' : 
      monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.validation === 2 ? 'green' : 
      monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.validation === 2  && monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.EqualRemunerationAndRegisterToBeMaintained?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px' , textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} > The Factories Act, 1948</th>
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register of Overtime and Payment</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.RegisterofOvertimeandPayment?.validation === 1 ? 'gray' : 
      monthly?.data?.RegisterofOvertimeandPayment?.validation === 2 ? 'green' : 
      monthly?.data?.RegisterofOvertimeandPayment?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.RegisterofOvertimeandPayment?.validation === 2  && monthly?.data?.RegisterofOvertimeandPayment?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.RegisterofOvertimeandPayment?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.RegisterofOvertimeandPayment?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.RegisterofOvertimeandPayment?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register Of Leave And Leave Book</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.RegisterOfLeaveAndLeaveBook?.validation === 1 ? 'gray' : 
      monthly?.data?.RegisterOfLeaveAndLeaveBook?.validation === 2 ? 'green' : 
      monthly?.data?.RegisterOfLeaveAndLeaveBook?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.RegisterOfLeaveAndLeaveBook?.validation === 2  && monthly?.data?.RegisterOfLeaveAndLeaveBook?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.RegisterOfLeaveAndLeaveBook?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.RegisterOfLeaveAndLeaveBook?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.RegisterOfLeaveAndLeaveBook?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register Of Adult Workers Cum Muster Roll</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.validation === 1? 'gray' : 
      monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.validation === 2 ? 'green' : 
      monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.validation === 3 ? 'red' : 
      'gray'   }} >{(monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.validation === 2  && monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.na === true )  ? "Not applicable, but deemed acceptable." 
                                                                               : monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.RegisterOfAdultWorkersCumMusterRoll?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Combined register for Muster Roll and Wages</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.CombinedregisterforMusterRollandWages?.validation === 1  ? 'gray' : 
      monthly?.data?.CombinedregisterforMusterRollandWages?.validation === 2 ? 'green' : 
      monthly?.data?.CombinedregisterforMusterRollandWages?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.CombinedregisterforMusterRollandWages?.validation === 2  && monthly?.data?.CombinedregisterforMusterRollandWages?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.CombinedregisterforMusterRollandWages?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.CombinedregisterforMusterRollandWages?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.CombinedregisterforMusterRollandWages?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>

               <tr>
                  <th colSpan="6" style={{ border: '1px solid black', padding: '8px', textAlign:"left", backgroundColor:COLORS.bggray, color:"black" }} >The Labour Welfare Fund Act, 1965</th>
               </tr>
              
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Register Of Unclaimed Wages And Payment To The Board</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Low</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px',color: 
      monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.validation === 1 ? 'gray' : 
      monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.validation === 2 ? 'green' : 
      monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.validation === 3 ? 'red' : 
      'gray'   }} >{ (monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.validation === 2  && monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.RegisterOfUnclaimedWagesAndPaymentToTheBoard?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
               <tr>
                   <td style={{ border: '1px solid black', padding: '8px' }} >LWF Challan</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >High</td>
                   <td style={{ border: '1px solid black', padding: '8px' }} >Remittance </td>
                   {/* <td style={{ border: '1px solid black', padding: '8px' }} >Yes</td> */}
                   <td style={{ border: '1px solid black', padding: '8px' ,color: 
      monthly?.data?.LWFChallan?.validation === 1 ? 'gray' : 
      monthly?.data?.LWFChallan?.validation === 2 ? 'green' : 
      monthly?.data?.LWFChallan?.validation === 3 ? 'red' : 
      'gray'  }} >{ (monthly?.data?.LWFChallan?.validation === 2  && monthly?.data?.LWFChallan?.na === true )  ? "Not applicable, but deemed acceptable."  
                                                                               : monthly?.data?.LWFChallan?.validation === 2  ? "Complied" 
                                                                               : monthly?.data?.LWFChallan?.validation === 3   ? "Rejected" 
                                                                               : monthly?.data?.LWFChallan?.validation === 1   ? "Under Review" : "N/A"}</td>    
               </tr>
        
              

            </thead>
       
        </table>


      </div>
      <button className=" m-3 btn btn-md btn-dark float-end" onClick={generatePDF}>Download PDF</button>
    </div>
    </Modal>
  );
};

export default MonthlyReport;
