import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import grclogo from '../Images/GRC-Pulse-Logo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { COLORS } from '../Component/colors';
import LogoutIcon from '@mui/icons-material/Logout';
import { Email, Phone } from '@mui/icons-material';
import { SlCalender } from 'react-icons/sl';


const pages = ['Assessor Dashboard', 'Vendor MasterData', 'Vendor MonthlyData', 'Create Vendor'];
const settings = ['Profile', 'Vendors List'];
export default function AssessorAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showProfileText, setShowProfileText] = React.useState(false);
  const [showLogoutText, setShowLogoutText] = React.useState(false);
    const navigate=useNavigate();
  
   
  
    const handleLogout = () => {
      navigate("/signin");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      console.log("log out clicked");
    };
  
  
   
      const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleCloseMenu = () => {
        setAnchorEl(null);
      };
    
      const handleProfileClick = () => {
        navigate('/profile/assessor'); 
        handleCloseMenu();
      };
    
      const handleVendorsListClick = () => {
        navigate('/vendorList'); 
        handleCloseMenu();
      };
  return (
    <AppBar position="sticky" sx={{backgroundColor:'#1C1C1C	'}}>
      <Container maxWidth="xxl">

        <Toolbar disableGutters>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/assessordashboard"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
            >
              <img
                src={grclogo}
                width="130px"
                alt="Company Logo"
                style={{
                  padding: '5px'
                }}
              />
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 12, color: COLORS.ivory }}>
            <Typography sx={{ mr: 1 }}>
  <a href="mailto:support@grcpulse.in" style={{ textDecoration: 'none', color: COLORS.ivory }}>
    <Email /> support@grcpulse.in
  </a>
</Typography>
              <Typography><Phone /> 080-31634351</Typography>
            </Box>
          </Box>
        </Toolbar>
        <Box sx={{ backgroundColor: '#B22222' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{
                    m: 1,

                    display: 'block',
                    textTransform: 'none',
                  }}
                >
                  <Link className="nav-link" to={`/${page.replace(/\s+/g, '').toLowerCase()}`} style={{ color: COLORS.ivory, textDecoration: 'none' }}>
                    <Typography sx={{ fontSize: 15 }}>{page}</Typography>
                  </Link>
                </Button>
              ))}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>

              <Link to="/complience-calender">
                <SlCalender size="1.6rem" color='#ffffff' />
              </Link>
              <Tooltip title={showProfileText ? 'Profile' : ''}>
                <IconButton
                  onClick={handleOpenMenu} // Open menu when clicked
                  onMouseEnter={() => setShowProfileText(true)}
                  onMouseLeave={() => setShowProfileText(false)}
                  sx={{ p: 1 }}
                >
                  <AccountCircle sx={{ fontSize: '35px', color: COLORS.ivory }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}

              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      if (setting === 'Profile') {
                        handleProfileClick();
                      } else if (setting === 'Vendors List') {
                        handleVendorsListClick();
                      }
                    }}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>


              <Tooltip title={showLogoutText ? 'Log Out' : ''}>
                <IconButton
                  onClick={handleLogout}
                  onMouseEnter={() => setShowLogoutText(true)}
                  onMouseLeave={() => setShowLogoutText(false)}
                  sx={{ p: 1 }}
                >
                  <LogoutIcon sx={{ fontSize: '30px', color: COLORS.ivory }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
}
