import React, { useState, useEffect } from "react";
import {Container, Row, Col,Form,Button, ButtonGroup,} from "react-bootstrap";
import { FaArrowRight } from 'react-icons/fa';
import grclogo from "../Images/GRC-Pulse-Logo.png";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import SnackbarContent from '@mui/material/SnackbarContent';
import Snackbar from '@mui/material/Snackbar';
import * as yup from "yup";
import api from "../Services/api";
import AssessorAppBar from "../Component/AssessorAppBar";
 
 
 
const Login = ({setIsLoggedIn}) => {
  const [userType, setUserType] = useState("vendor");
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  let navigate = useNavigate();
 
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  const handleUserTypeChange = (type) => {
    setUserType(type);
  };
  
 
  const clientvalidationSchema = yup.object({
    clientUsername: yup.string().required("Username is required"),
    clientPassword: yup.string().required("Password is required"),
  });
  const adminvalidationSchema = yup.object({
    adminPhone: yup.string().required("Username is required"),
    adminPassword: yup.string().required("Password is required"),
  });
  const vendorvalidationSchema = yup.object({
    vendorUsername: yup.string().required("Username is required"),
    vendorPassword: yup.string().required("Password is required"),
  })
  const assessorvalidationSchema = yup.object({
    assessorUsername: yup.string().required("Username is required"),
    assessorPassword: yup.string().required("Password is required"),
  })
 
  const initialValues = {
    clientUsername: "",
    clientPassword: "",
  };
  const initialVendorValues = {
    vendorUsername: "",
    vendorPassword: "",
  };
  const initialAdminValues = {
    adminPhone: "",
    adminPassword: "",
  };
 
const initialAssessorValues = {
  assessorUsername: "",
  assessorPassword: "",
}


useEffect(() => {
  formik.resetForm({
    values: userType === "client" ? initialValues :
           userType === "vendor" ? initialVendorValues :
           userType === "assessor" ? initialAssessorValues : initialAdminValues
  });
}, [userType]);


const formik = useFormik({
  key: userType,
  initialValues: userType === "client" ? initialValues : userType === "vendor" ? initialVendorValues : userType === "assessor"  ? initialAssessorValues  : initialAdminValues,
  validationSchema: userType === "client" ? clientvalidationSchema : userType === "vendor" ? vendorvalidationSchema : userType === "assessor" ? assessorvalidationSchema : adminvalidationSchema,
    onSubmit: async (values) => {
        // console.log("form values", values);
      try {
        let result;
        if (userType === "client") {
          result = await api.login.clientLogin({
            clientUsername: values.clientUsername,
            clientPassword: values.clientPassword,
          });
        }
        else if(userType === 'admin'){
          result = await api.login.adminLogin({
            adminPhone: values.adminPhone,
            adminPassword: values.adminPassword,
          });
        }
        else if(userType === 'assessor' ) {
          result = await api.login.assessorLogin({
            assessorUsername: values.assessorUsername,
            assessorPassword: values.assessorPassword,
 
          });
        }
      
        else {
          result = await api.login.vendorLogin({
            vendorUsername: values.vendorUsername,
            vendorPassword: values.vendorPassword,
          });
        // console.log("entered else", result);
        }
        // console.log("result", result?.data);
 
        let temp = result?.data?.filtterData;
        let temp1 = result?.data;
        
        let user ={
          id : temp?.id,
          Name: temp?.name,
          // companyName : temp?.name,
          companyId : temp?.companyId,
          // licenseMode : temp?.licenseMode,
          type : temp?.type
        }
        
        sessionStorage.setItem(  "user",  JSON.stringify(user)  );

        //  sessionStorage.setItem("Token",JSON.stringify(temp?.token))
         sessionStorage.setItem("Token",temp?.token);
 
        if (userType == 'client'){
         if(result.status === 400){
            setSnackbarMessage( result.message);
            setSnackbarType("error");
            setOpenSnackbar(true);
          }
          else if(result.status === 404){
            setSnackbarMessage( result.data.message);
            setSnackbarType("error");
            setOpenSnackbar(true);
          }
          else if(result.status === 401){
            setSnackbarMessage( result.data.message);
            setSnackbarType("error");
            setOpenSnackbar(true);
          }
          else if(result.status === 500){
            setSnackbarMessage( result.data.message);
            setSnackbarType("error");
            setOpenSnackbar(true);
          }
        else if(result.status === 200) {
            setSnackbarMessage(result.data.message);
            setSnackbarType("success");
            setOpenSnackbar(true);
            setTimeout(() => {
              navigate(`/${userType}dashboard`);
              setIsLoggedIn(true);
            }, 800);
          } 
          else{
            setSnackbarMessage("Some error occured please try again later");
          setSnackbarType('error');
          setOpenSnackbar(true);
          }
        }
        
       else if (userType == 'admin'){
          if(result.status === 400){
             setSnackbarMessage( result.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 404){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 401){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 500){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
         else if(result.status === 200) {
             setSnackbarMessage(result.data.message);
             setSnackbarType("success");
             setOpenSnackbar(true);
             setTimeout(() => {
               navigate(`/${userType}dashboard`);
               setIsLoggedIn(true);
             }, 800);
           } 
           else{
             setSnackbarMessage("Some error occured please try again later");
           setSnackbarType('error');
           setOpenSnackbar(true);
           }
         }
         else if (userType == 'assessor'){
          if(result.status === 400){
             setSnackbarMessage( result.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 404){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 401){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 500){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
         else if(result.status === 200) {
             setSnackbarMessage(result.data.message);
             setSnackbarType("success");
             setOpenSnackbar(true);
             setTimeout(() => {
               navigate(`/${userType}dashboard`);
               setIsLoggedIn(true);
             }, 800);
           } 
           else{
             setSnackbarMessage("Some error occured please try again later");
           setSnackbarType('error');
           setOpenSnackbar(true);
           }
         }
         else if (userType == 'vendor'){
          if(result.status === 400){
             setSnackbarMessage( result.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 404){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 403){
            setSnackbarMessage( result.data.message);
            setSnackbarType("error");
            setOpenSnackbar(true);
          }
           else if(result.status === 401){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
           else if(result.status === 500){
             setSnackbarMessage( result.data.message);
             setSnackbarType("error");
             setOpenSnackbar(true);
           }
         else if(result.status === 200) {
             setSnackbarMessage(result.data.message);
             setSnackbarType("success");
             setOpenSnackbar(true);
             setTimeout(() => {
               navigate(`/${userType}dashboard`);
               setIsLoggedIn(true);
             }, 800);
           } 
           else{
             setSnackbarMessage("Some error occured please try again later");
           setSnackbarType('error');
           setOpenSnackbar(true);
           }
         }
       
        else{
         
          setSnackbarMessage("Please select userType and try to login");
          setSnackbarType('error');
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error('Please fill in the correct credentials!!', error);
       

      }
    },
  });
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
 
  return (
    <>
<Container fluid style={{ height: "100vh", padding: 0 }}>
 
      <Row style={{ height: "100%", margin: 0 }}>
      <Col
  xs={12} md={5}
  style={{
    background: "linear-gradient(45deg, rgba(28,28,28,1) 68%, rgba(213,214,215,1) 48%)",
    display: 'flex',           
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center',    
    height: '100vh',           
  }}
>
 <a href="https://www.grcpulse.in/" target="_blank"><img width="250px" src={grclogo} alt="Logo" /></a> 
  <p className=" pt-2 text-center" style={{fontSize:'20px',color:'gray',letterSpacing:'0.5px'}}>ʏᴏᴜʀ ᴄᴏᴍᴘʟɪᴀɴᴄᴇ ᴘᴀʀᴛɴᴇʀ</p>
</Col>
 
     
        <Col
          xs={12} md={7}
          style={{
            backgroundColor: "#B22222",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button className=" text-center" style={{position:'absolute',top:'2%',right:'2%',color:'white'}}  onClick={() => handleUserTypeChange("admin")}  variant={userType === "admin" ? "dark" : "white"}  >
 {/* <img src={adminIcon} alt="adminIcon" height="40px" width="55px"></img> */}
<p className='text-center '>Admin Login <FaArrowRight/></p>
 
</Button>
      
          <div
            style={{
              position: "absolute",
              top: "53%",
              left: "55%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#ffff",
              padding: "30px",
              borderRadius: "2px",
          
              width: "100%",
              maxWidth: "400px",
            }}
          >

           <h4 className="text-center p-2 text-secondary">Login as</h4>
          <hr ></hr>
            <form onSubmit={formik.handleSubmit}>

            <ButtonGroup
          toggle
          className="m-3 d-flex"
         
        >
 
<Button className="text-center  "  onClick={() => handleUserTypeChange("client")} variant={userType === "client" ? "success" : "white"}>
 {/* <img src={clientIcon} alt="clientIcon" height="40px" width="40px"></img> */}
<p className='text-center '>Client</p>
 
</Button>
{/* <div className="m-2" style={{borderLeft:'0.5px solid gray',height:'40px'}}></div> */}
<Button className='  text-center'  onClick={() => handleUserTypeChange("vendor")} variant={userType === "vendor" ? "danger" : "white"} >  
{/* <img  src={vendorIcon} alt="vendorIcon" height="40px" width="60px" ></img> */}
<p className='text-center '>Vendor</p>
 
</Button>
{/* <div className="m-2"style={{borderLeft:'0.5px solid gray',height:'40px'}}></div> */}
<Button className='  text-center' onClick={() => handleUserTypeChange("assessor")} variant={userType === "assessor"  ? "success" : "white"} >  
{/* <img  src={assesorIcon} alt="assesorIcon" height="40px" width="60px" ></img> */}
 <p className='text-center '>Assessor</p>
 
</Button>
{/* <Button className=" text-center p-2"  onClick={() => handleUserTypeChange("admin")}  variant={userType === "admin" ? "warning" : "white"}  >
 <img src={adminIcon} alt="adminIcon" height="40px" width="55px"></img>
<p className='text-center '>Admin</p>
 
</Button> */}
</ButtonGroup>

              <div style={{ marginBottom: "20px" }}>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  name={userType === "client" ? "clientUsername" : userType === "admin" ? "adminPhone" : userType === "assessor" ? "assessorUsername" : "vendorUsername"}
                  value={formik.values[userType === "client" ? "clientUsername" : userType === "admin" ? "adminPhone" : userType === "assessor" ? "assessorUsername" : "vendorUsername"]}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }} 
                  error={formik.touched[userType === "client" ? "clientUsername" : userType === "admin" ? "adminPhone" : userType === "assessor" ? "assessorUsername" : "vendorUsername"] && Boolean(formik.errors[userType === "client" ? "clientUsername" : userType === "admin" ? "adminPhone" : userType === "assessor" ? "assessorUsername" : "vendorUsername"])}
                  helperText={formik.touched[userType === "client" ? "clientUsername" : userType === "admin" ? "adminPhone" : userType === "assessor" ? "assessorUsername" : "vendorUsername"] && formik.errors[userType === "client" ? "clientUsername" : userType === "admin" ? "adminPhone" : userType === "assessor" ? "assessorUsername" : "vendorUsername"]}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    name={userType === "client" ? "clientPassword" : userType === "admin" ? "adminPassword" : userType === "assessor" ? "assessorPassword" : "vendorPassword"}
                    value={formik.values[userType === "client" ? "clientPassword" : userType === "admin" ? "adminPassword" : userType === "assessor" ? "assessorPassword" : "vendorPassword"]}
                    onChange={formik.handleChange}
                    error={formik.touched[userType === "client" ? "clientPassword" : userType === "admin" ? "adminPassword" : userType === "assessor" ? "assessorPassword" : "vendorPassword"] && Boolean(formik.errors[userType === "client" ? "clientPassword" : userType === "admin" ? "adminPassword" : userType === "assessor" ? "assessorPassword" : "vendorPassword"])}
                    helperText={formik.touched[userType === "client" ? "clientPassword" : userType === "admin" ? "adminPassword" : userType === "assessor" ? "assessorPassword" : "vendorPassword"] && formik.errors[userType === "client" ? "clientPassword" : userType === "admin" ? "adminPassword" : userType === "assessor" ? "assessorPassword" : "vendorPassword"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
              <div className="form-check pb-2" >
                <input type="checkbox"   className="form-check-input"  id="termsCheck"   onChange={(e) => formik.setFieldValue('terms', e.target.checked)}  />
                  <label className="form-check-label" htmlFor="termsCheck" style={{textAlign:"center"}}>
                    I agree to the{" "}
                       <a href="/terms-and-condition" target="_blank" rel="noopener noreferrer" style={{ color:"red"}}>
                           Terms and <a href="/PrivacyPolicy" target="_blank" rel="noopener noreferrer">Policy</a>
                       </a>
                  </label>
              </div>
              <button type="submit" className="btn btn-dark" style={{ width: "100%" }} disabled={!(formik.isValid && formik.dirty && formik.values.terms)}>
                Login
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
    <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx ={{
          marginRight:'5%',
          marginTop:'2%'
        }}
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{
            backgroundColor: snackbarType === "success" ? "green" : "red",
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      </Snackbar>
    </>
  );
};
 
export default Login;
 
