import Footer from "../layout/Footer";
import Navbar from "../layout/NavBar";

const Terms = () => {
  return (
    <>
      <Navbar />
      <div className="w-full min-h-screen p-12">
        <h1 className="text-4xl pt-5 font-bold text-center">Terms and Conditions</h1>
        <p className="mt-2 text-xl">
          Please read these Terms and Conditions (“Terms”), a legal agreement between PEOPLE PROCESS TECK PRIVATE LIMITED, a Private Limited Company incorporated under the laws of India, having its registered office at No. B-404, Hoysala Habitat, Doddaballapur Road, Yelahanka, Bangalore, Karnataka – 560119 (hereinafter referred to as “PPTeck”, “Company”, “We”, “Our” or “Us”).  of the one part and “CLIENT” OR “VENDOR” (defined below), hereinafter referred to as “you”, “yours” or similar, of the other part. The domain name www.grcpulse.in is owned by the Company.
        </p >
        <p className="mt-2 text-xl">The Terms have been drafted in compliance with the (i) the Indian Contract Act, 1872, (ii) the (Indian) Information Technology Act, 2000, and the rules, regulations, guidelines, and clarifications framed there under and any amendments to the same, including the (iii) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”), and the (iv) Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 (the “IG Rules”).  </p>
        <p className="mt-2 text-xl">Please carefully go through these Terms and the Privacy Policy available at https://www.grcpulse.in (“Privacy Policy”) before you decide to access or use the Application. These Terms and the Privacy Policy together constitute a legal agreement (“Agreement”) between You and PPTECK in connection with Your access and use of the Application which is conditioned upon your agreement and compliance with all the terms, conditions and notices contained or referenced in this agreement (the “Terms”), as well as any other written agreement between us and you as applicable. </p>
        <p className="mt-2 text-xl" >In some circumstances, a separate document that provides additional conditions may apply to a service offered via the Application (“Additional Terms”). To the extent there is a conflict between these Terms and any Additional Terms, the Additional Terms will prevail unless the Additional Terms expressly state otherwise.  </p>
        <p className="mt-2 text-xl">By clicking “Sign In”, “Login” &  Sign Up” or similar tabs at the time of registration, or by entering into an agreement with PPTECK or through the continued use or accessing the Application, You accept the Terms and consent your approval to the provisions of the Terms as laid down herein below. You are also responsible for complying with your obligations under applicable laws for visiting, accessing, and using the PPTECK Application. If you do not agree and do not wish to be bound by the Terms, you have no right to access or use the PPTECK Application.</p>
        <p className="mt-2 text-xl">We reserve the right to modify, discontinue or terminate the Application or Services (defined below) offered thereunder or to change these Terms at any time. You acknowledge and agree that it is your responsibility to review the Application and these Terms from time to time and to familiarize yourself with any modifications. If we modify these Terms, we will post the modification on the Application and/or Website. Your continued access or use of the Application after we have posted a modification will constitute acknowledgement of the modified Terms and agreement to abide by and be bound by the modified Terms. We will periodically inform you about the changes made to the Terms and Privacy Policy on the PPTECK Application, and in case of non-compliance with the Terms and Privacy Policy, we shall have the right to terminate the access or usage rights of the Client / Vendor to the Application immediately or remove non-compliant information, as per the situation. If the modified Terms are not acceptable to you, you may cease using the Application and the Services.</p>

        <h1 className="mt-4 font-bold text-2xl">1.Definitions</h1>
        <p className="text-gray-700 leading-relaxed mt-4 text-xl">
          As used in these Terms and Conditions, the following terms shall have the meaning set forth below:
          <br /><br />
          <span className="font-bold">Application Content</span> means all material, content and information made available on the Application including but not limited to written text, graphics, images, logos, trademarks, audio/video material, or any other forms of expression.
          <br /><br />
          <span className="font-bold">Assessor</span> means an individual who is a domain expert with great experience in their area of expertise. They assess the Compliance requirements and provide their observations.
          <br /><br />
          <span className="font-bold">Client</span> shall mean a registered user who is seeking to utilize the services provided by the application on behalf of an organization that wants to engage.
          <br /><br />
          <span className="font-bold">Client / Vendor Information</span> means information pertaining to the Client’s / Vendor’s name, details about the organization, designation, industry and department, number of employees, location, GSTIN and PAN details, phone number, email address, sub-user information and such other information/documents that the Company may require to be submitted from time to time or as the case may be etc.
          <br /><br />
          <span className="font-bold">Confidential Information</span> shall mean all information and materials furnished by a party which: (a) if in written format is marked as confidential, or (b) if disclosed verbally is noted as confidential at time of disclosure, or (c) in the absence of either (a) or (b) is information which a reasonable party would deem to be non-public information and confidential. Confidential Information shall include, but not be limited to the Client Information, and Vendor Information; all types of data, information, ideas, processes, procedures, software specifications, technical processes, financial information, business plans, projections, marketing data and other similar information provided by one party to the other; documentation, the existence and contents of this Agreement or agreements between the Company and the Client whether such is transmitted in writing, orally, visually, (e.g. video terminal display) or on magnetic media, and shall include all proprietary information, customer and prospect lists, trade secrets, or proposed trade names, know-how, concepts, source code, object code, drawings, flow charts, diagrams and other intellectual property relating to the subject matter of this Agreement.
          <br /><br />
          <span className="font-bold ">Effective Date</span> means the Date on which You accept these Terms and Privacy Policy by clicking ‘Sign Up,’ ‘Sign In,’ ‘Log In’ or ‘I Accept’ or enter into an agreement with GRC@Pulse.
          <br /><br />
          <span className="font-bold ">Registered User</span> means any person who signs up and registers by creating an Account as a Client / Vendor / Assessor on the GRC@Pulse through their email ID for availing the Services.
          <br /><br />
          <span className="font-bold ">Services</span> refers to the service of functioning as an online facilitator on a digital Application that seeks to connect Clients with Vendors to achieve their business goals as per their industry and domain requirement, but not limited to the Services stipulated under Clause 2(B) of the Terms and such other services as may be provided by the Registered Users and the Company.
          <br /><br />
          <span className="font-bold">Vendor</span> means a Registered User who is interested in using this application for knowing the compliance status and presenting it to the Client.
          <br /><br />
          All other capitalized terms shall have the meaning ascribed to them in the Other Terms.
        </p>
        <h1 className="mt-4 font-bold text-2xl">
          2.ELIGIBILITY
        </h1>
        <p className="text-gray-700 leading-relaxed mt-4 text-xl">
          You confirm that you are of legal age and competent to contract and have the authority to enter into this Agreement as per the provisions of the Indian Contract Act, 1872. PPTECK reserves the right to terminate Your Account and/or refuse to provide You with access to the Services available on the Application if it is discovered that You are under the legal age or are not competent to contract or if any information provided by You is inaccurate. It shall be Your sole responsibility to ensure that You meet the required qualifications.
        </p>

        <section className="mt-4">
          <h1 className="mt-4 font-bold text-2xl">3.YOUR USE OF THE SERVICES AVAILABLE ON THE GRC@PULSE APPLICATION </h1>
          <h2 className="font-bold text-xl mt-2">Access and Registration</h2>
          <p className="text-gray-700 leading-relaxed mt-4 text-xl">
            In order to access, browse, view, and use the Services available on the Application, you will need to create an account (“Account”) and register to avail the Services on the Application as a Client or Vendor.
            <br /><br />
            You can create an account with your email address as your User ID, followed by 2-step authentication through email and phone number.
            <br /><br />
            To create an Account, you represent, warrant, and covenant that you will provide accurate and complete registration information (including but not limited to your first name, last name, email address, phone number, address, username, and password – “Registered User Information”) and keep it accurate and up to date. Failure to do so constitutes a breach of the Terms and may result in immediate termination of your Account.
          </p>
        </section>

        <section className="mt-4">
          <h2 className="font-bold text-xl mt-2">Account Activation</h2>
          <p className="text-gray-700 leading-relaxed mt-4 text-xl">
            If you register on the Application through your email address, a link or code will be sent to the email address and phone number you provide to verify and activate the Account. This is part of the 2-step authentication process. Upon completing this process, you will gain access to the Application.
          </p>
        </section>

        <section className="mt-4">
          <h2 className="font-bold text-xl">Your Responsibilities</h2>
          <p className="text-gray-700 leading-relaxed mt-4 text-xl">
            By registering as a Client or Vendor, you represent and warrant that:
          </p>
          <ul className="list-disc ml-8 text-gray-700 mt-4 text-xl leading-relaxed">
            <li>Your Registered User Information is accurate, authentic, and complete, and you will keep it up to date.</li>
            <li>Financial details provided (e.g., credit/debit card, bank account) are correct and verified.</li>
            <li>You will safeguard your password and not disclose it to any third party.</li>
            <li>You will notify PPTECK of any unauthorized use of your Account or personal/financial details immediately.</li>
            <li>You will not use the Application in violation of any applicable laws or codes of conduct.</li>
          </ul>
        </section>

        <section className="mt-4">
          <h2 className="font-bold text-xl">Services for Clients and Vendors</h2>
          <p className="text-gray-700 leading-relaxed mt-4 text-xl">
            <span className="font-bold">Setting up an Account:</span> Clients and Vendors must create an Account to access the Application. Each will have a unique User ID and password.
            <br /><br />
            <span className="font-bold">Creating a Vendor Profile:</span> Vendors must provide details such as name, address, phone number, GSTIN, PAN details, and required compliance documents. These records will be verified by an Assessor.
            <br /><br />
            <span className="font-bold">Assessment by Assessors:</span> Assessors will periodically review Vendor documents for compliance and provide a report shared with the Client.
            <br /><br />
            <span className="font-bold">Automatic Email Alerts:</span> Clients and Vendors will receive alerts for activities like document submissions, assessments, and reports.
            <br /><br />
            <span className="font-bold">Feedback:</span> Feedback provided by Clients and Vendors may be used by PPTECK for any purpose without obligation.
          </p>
        </section>

        <section className="mt-4">
          <h2 className="font-bold text-xl">Disputes Between Registered Users</h2>
          <p className="text-gray-700 leading-relaxed mt-4 text-xl">
            PPTECK is not responsible for disputes between Registered Users. Users are encouraged to take reasonable precautions and conduct due diligence before engaging with others.
            <br /><br />
            In the event of a dispute, PPTECK may assist at its discretion but is not obligated to intervene. By using the Application, you release PPTECK and its affiliates from claims or damages arising from disputes with other users.
          </p>
        </section>
        <h1 className="mt-4 font-bold text-2xl">4.YOUR CONDUCT IN USING THE APPLICATION</h1>
        <h1 className="mt-4 font-bold text-xl">Your use of the Application shall be subject to the following restrictions: </h1>
        <p className="text-xl mt-4">
          You will not delete or modify any content on the Application including but not limited to, legal notices, disclaimers, or proprietary notices such as copyright or trademark symbols, logos.
        </p>
        <p className="text-xl mt-4">You will not decompile, reverse engineer, or disassemble the Website and/or Application, or remove any copyright, trademark registration, or other proprietary notices from the Website and/or Application. You further agree not to access or use Website and/or Application in any manner that may be harmful to the operation of the Website and/or Application or its content</p>
        <p className="text-xl mt-4">You will not use Website and/or Application and/or Services in any way that is unlawful, or harms PPTECK or any other person or entity.  </p>
        <p className="text-xl mt-4">You will immediately notify Us of any breach or suspected breach of the security of the Application of which You become aware, or any unauthorized use or disclosure of information within or obtained from the Application, and You will take such action to mitigate the breach or suspected breach as We may direct and will cooperate with Us in investigating and mitigating such breach.</p>
        <p className="text-xl mt-4">You will not engage in any form of antisocial, disrupting, or destructive acts, including “flaming”, “spamming”, “flooding”, “trolling”, “phishing” and “griefing” as those terms are commonly understood and used on the Internet.  </p>
        <p className="text-xl mt-4">You will not host, display, upload, modify, publish, transmit, update or share any information that belongs to another person and to which You do not have any right to; is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; harm minors in any way; infringes any patent, trademark, copyright or other proprietary right(s), violates any law for the time being in force; deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; impersonate another person; contains viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.  </p>
        <p className="text-xl mt-4">You will not forge headers or otherwise manipulate identifiers to disguise the origin of any message or transmittal you send to us on or through the Application or when availing any Service offered on or through the Application. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.</p>
        <p className="text-xl mt-4">PPTECK does not support and will not tolerate its Services being used to discriminate against others, including when based on age, ancestry, colour, creed, disability, gender identity, marital status, national origin, parental status, political ideology, race, religion, sex, sexual orientation, military status or veteran, or any other status protected by law. You are not permitted to use the Services in a manner which would incite, promote, or support such discrimination and you must not use the Services to incite or promote hostility or violence. If we believe in our sole determination that your use of the Services is being used to discriminate, we may permanently or temporarily terminate or suspend your access to the Services without notice and liability for any reason.  </p>
        <p className="text-xl mt-4">You may not have more than one active account at any time without the written consent of PPTECK in any circumstances.  </p>
        <p className="text-xl mt-4">You agree not to use the Application in any other way that violates (i) these Terms, (ii) applicable laws, or (iii) the rights of any third party.  </p>

        <h1 className="mt-4 font-bold text-2xl">5.GRANT OF RIGHTS</h1>
        <section className="mt-4">
          <h2 className="text-xl font-semibold mb-4">Application and Application Content</h2>
          <p className="text-lg mb-4">
            The Application is owned and operated by PPTECK. PPTECK owns all rights, title, and interest, including all intellectual
            property rights such as copyright, trademarks, and other proprietary rights in and to the Application, software therein,
            the present or future modifications/upgrades thereof and standard enhancements thereto.
          </p>
          <p className="text-lg mb-4">
            Content on the Application is solely for your use as per the Terms of this agreement. All content posted on the Application
            except the Registered User Information is wholly owned by PPTECK or licensed by it (GRC@Pulse Content). You agree not to
            reproduce, modify, sell, distribute, mirror, frame, republish, download, transmit, or create derivative works of the
            Application and/or GRC@Pulse Content, in whole or in part, by any means.
          </p>
          <p className="text-lg mb-4">
            You must not remove or modify any copyright or trademark notice, or other notice of ownership. Modification of the material
            on the Application, use of such material on any other Application or networked computer environment, or use of the
            materials for any purpose other than those stipulated in these terms is a violation of our intellectual property rights and is
            prohibited.
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Limited License to use the Application and GRC@Pulse Content</h2>
          <p className="text-lg mb-4">
            We grant you a non-exclusive, personal, non-transferable, revocable, and limited right to have access to and make use of
            the Website and/or Application and Services offered therein only for the duration you are registered and in accordance with
            the Terms contained therein. You are, however, not permitted to reproduce, duplicate, copy, sell or otherwise exploit the
            Application or any product image, product listing, product description, price, page layout, page design, trade dress,
            trademark, logo, or other GRC@Pulse Content for any commercial purpose, except as expressly provided.
          </p>
          <p className="text-lg mb-4">
            These Terms do not and shall not transfer any ownership or proprietary interest in the Website and/or Application or
            GRC@Pulse Content to You, except as may be otherwise expressly provided herein or as may be agreed to by and between the
            Parties. You also acknowledge that the Registered User Information provided on the Website and/or Application is proprietary
            to and owned by the Registered User and such Registered User Information of the Engager shall be provided to you under
            license.
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Vendor’s Submission and Grant of License</h2>
          <p className="text-lg mb-4">
            PPTECK does not claim ownership of materials you provide, post, upload, input, or submit as part of the Services (“Submissions”),
            which may include documents related to statutory compliance. By posting your Submissions using the Services, you expressly grant
            PPTECK a multi-use, sub-licensable, transferable, perpetual, irrevocable, non-exclusive, royalty-free, and worldwide license to use,
            reproduce, publish, translate, distribute and publicly display your Submissions in whole or in part, and in any form, media, or
            technology, whether now known or hereafter developed, for use in connection with the Services and PPTECK’s (and its successors’ and
            affiliates’) business.
          </p>
          <ul className="list-disc list-inside text-lg mb-4">
            <li>You represent and warrant that your Submissions do not violate any relevant law or infringe any rights of a third party, including intellectual property rights, privacy, and publicity rights.</li>
            <li>You have the rights necessary to grant the license to any Submissions you post.</li>
            <li>Your Submissions are not obscene, defamatory, abusive, racially, or ethnically offensive, or otherwise objectionable.</li>
            <li>Your Submissions won’t create harm, loss, injury, or emotional distress to anyone.</li>
            <li>Your submissions are truthful and accurate, and you have the necessary rights to use any materials included.</li>
          </ul>
        </section>

        <h1 className="mt-4 font-bold text-2xl">6.ROLE OF THE COMPANY</h1>
        <section className="mt-4">
          <h2 className="text-xl font-semibold mb-4">Company's Role as an Intermediary</h2>
          <p className="text-lg mb-4">
            The Company is a digital Application acting merely as an online facilitator or a marketplace for offering Compliance services to Clients.
          </p>
          <p className="text-lg mb-4">
            The Company does not direct, has no control over, makes no representations, and does not guarantee the credibility of the Registered User Information of the Clients and the Vendors.
          </p>
          <p className="text-lg mb-4">
            Clients, Vendors, and Assessors are independent parties. The Company is not responsible for and disclaims any and all liability related to the actions of Clients, Vendors, and Assessors.
          </p>
          <p className="text-lg mb-4">
            The Company’s role is that of an ‘intermediary’ as defined under the Information Technology Act, 2000, as amended from time to time, and the rules thereunder. Being an intermediary, the Company is merely providing an Application as an online facilitator to Clients and Vendors to connect and interact with each other and thus has no responsibility and/or liability in respect of the transactions being conducted amongst them through the Website and/or Application or outside it.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Fraudulent Activity Disclaimer</h2>
          <p className="text-lg mb-4">
            If the Company suspects any illegal, wrongful, or fraudulent activity on the Application by the Registered User, notwithstanding any other rights the Company may have, the Company reserves the right to inform the relevant government or law enforcement authorities.
          </p>
          <p className="text-lg mb-4">
            The Company will cooperate in all investigative procedures or requests for access/information initiated by any such government or law enforcement authorities.
          </p>
        </section>

        <h1 className="mt-4 font-bold text-2xl">7.DISCLAIMER AND EXCLUSION OF WARRANTIES</h1>
        <section className="mt-4">
          <h2 className="text-xl font-semibold mb-4">Company’s Role as Facilitator</h2>
          <p className="text-lg mb-4">
            You hereby acknowledge and agree that PPTECK is only offering an Application where it is acting as an online facilitator to suggest and connect Clients and Vendors on the Application and it is not a party to any oral or written agreement for service or any contract entered between Client and Vendor in connection with any Services that is sought from the Vendor, directly or indirectly, through the Application.
          </p>
          <p className="text-lg mb-4">
            Notwithstanding the foregoing, you agree that since the Company only seeks to provide an online facilitating Application wherein the Clients and Vendors are brought together and the Company itself has no role in the execution, completion, and delivery of the work/service by the Vendor, the Company shall not be responsible or liable for any loss or damage of any sort whatsoever incurred as a result of any such engagement which happens over the Application or outside of it between the Clients and Vendors.
          </p>
          <p className="text-lg mb-4">
            If there is a dispute between and amongst the Clients and Vendors, you acknowledge and agree that the Company is under no obligation to become involved. In the event that a dispute arises between Clients and Vendors, or any third party, you hereby release the Company, its officers, affiliates, managers, directors, attorneys, agents, and successors in right from any claims, demands, and damages (actual and consequential) of every kind or nature, known or unknown, suspected or unsuspected, foreseeable or unforeseeable, disclosed or undisclosed, arising out of or in any way related to such disputes and/or any Services provided thereunder.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Risk and Liability Disclaimer</h2>
          <p className="text-lg mb-4">
            You agree that your use of the Website or Application shall be at your own risk. To the fullest extent permitted by law, the Company and its officers, affiliates, managers, directors, successors, and agents disclaim all warranties, express, implied, statutory or otherwise, or any guarantees in connection with this Website, the Application, the quality, suitability, accuracy, or completeness of the Application, any information or material contained or presented on this Application by the Engagers, the services offered on or through this Application, or any data, service materials offered by other third parties through any links or other sources through the Application.
          </p>
          <p className="text-lg mb-4">
            Unless otherwise explicitly stated, to the maximum extent permitted by applicable law, this Application, the Services offered on or through this Application, data, materials, and any information or material contained or presented on this Application is provided to you on an “as is” and “as available” basis with no implied warranty of merchantability, fitness for a particular purpose, or non-infringement or third-party rights.
          </p>
          <p className="text-lg mb-4">
            Company does not provide any warranties against errors, mistakes, or inaccuracies of data, content, information, materials, substance of the Application, or any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, any bugs, viruses, trojan horses, or the like which may be transmitted to or through the Application by any third party, any interruption or cessation of transmission to or from the Application, any defamatory, offensive, or illegal conduct of any third party or Clients or Vendors, or any loss or damage of any kind incurred as a result of the use of any data, content, information, materials, substance of the Website or Application or the Client’s or the Vendor’s information uploaded, shared, posted, transmitted, or otherwise made available via the Application.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Liability and Damages Disclaimer</h2>
          <p className="text-lg mb-4">
            YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE WEBSITE OR APPLICATION OR THE INFORMATION IN THE APPLICATION, INCLUDING INACCURATE OR INCOMPLETE INFORMATION. IT IS EXPRESSLY AGREED THAT IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL, REMOTE OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES, LOSS OF USE, OR LOSS OF INFORMATION OR DATA, WHETHER A CLAIM FOR ANY SUCH LIABILITY DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF LIABILITY, EVEN IF THE COMPANY HAS BEEN APPRISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES, RESULTING FROM (I) THE USE OR THE INABILITY TO USE THE SERVICES/ APPLICATION; (II) ANY DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA NON-DELIVERY, LOSS, THEFT, MISDELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION; (IV) STATEMENTS OF CONDUCT OF ANYONE ON THE SERVICE; (V) MALICIOUS OR CRIMINAL BEHAVIOUR, OR FALSE OR FRAUDULENT TRANSACTIONS OR (VI) NETWORK, TECHNICAL OR OTHER OPERATIONAL LAPSES ON THE APPLICATION OR (VII) INTERRUPTION OF ENGAGEMENT BETWEEN THE GIG TALENT AND THE ENGAGER (VIII) ACCESS DELAYS OR ACCESS INTERRUPTION TO THE WEBSITE OR APPLICATION (IX) ANY OTHER MATTER RELATING TO THE SERVICES OFFERED ON THE APPLICATION. WE DISCLAIM ANY AND ALL LIABILITY FOR ERRONEOUS TRANSMISSIONS AND LOSS OF SERVICE RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION SERVICE PROVIDERS, INTERNET SERVICE PROVIDERS OR THE SYSTEM.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">General Disclaimer</h2>
          <p className="text-lg mb-4">
            We do not warrant that your use of the Application and the Services under these Terms will not violate any law or regulations applicable to you.
          </p>
        </section>
        <h1 className="mt-4 font-bold text-2xl">8. LIMITATION OF LIABILITY </h1>
        {/* Disclaimer Text */}
        <section className="mt-4">
          <p className="text-lg mb-4">
            You expressly understand and agree that we shall not be liable for any direct, indirect, incidental, punitive, or consequential damages, including without limitation negligence, damages for loss of revenue, profits, use, or data, resulting from the access or use or inability to use our services and/or content on the Website, Application and/or the Services, including without limitation, use of or reliance on information, interruptions, errors, defects, mistakes, omissions, deletions of files, delays in operation or transmission, non-delivery of information, disclosure of communications, or any other failure of performance.
          </p>
          <p className="text-lg mb-4">
            In any event, our overall and aggregate liability arising out of these Terms or use of the Website, Application and/or the Services shall not exceed the total value of the dispute or INR 1,000, whichever is lower.
          </p>
          <p className="text-lg mb-4">
            You understand and agree that we shall not be liable to you in contract, tort, or equity in relation to any direct, indirect, or consequential loss that you might incur in relation to the contents or use of or reliance on Application Content or otherwise in connection with the Application.
          </p>
          <p className="text-lg mb-4">
            You acknowledge and agree that some of the Application Content may be provided by way of blogs or comments made by other Registered Users of the Application, and PPTECK does not accept any responsibility or make any representation for the accuracy of such information or your reliance on the same.
          </p>
          <p className="text-lg">
            By using this Application, you agree and accept that the limitations of liability provided in this Clause are reasonable.
          </p>
        </section>
        <h1 className="mt-4 font-bold text-2xl">9.INDEMNIFICATION </h1>
        <section className="mt-4">
          <p className="text-lg mb-4">
            You agree to defend, indemnify, and hold harmless the Company, and its officers, affiliates, managers, members, directors, successors, assigns, subsidiaries, service providers, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, charge and expenses (including but not limited to attorney's fees) arising from your use of or access to, and participation in the Application; your violation of any third party right, including without limitation any copyright, trademark, proprietary, intellectual property, or privacy right.
          </p>
          <p className="text-lg mb-4">
            Claims also include actions arising on account of false, inaccurate, incomplete information or suppression of any material information, incorrect or misleading statements, defamation, disparagement, breach of contract, misuse of confidential information, breach of trust, breach of privacy, obscenity or any other cause or for violation of any employment-related laws, rules, regulations or orders or notifications which may be brought on account of the information, content, data shared, uploaded or displayed by you.
          </p>
          <p className="text-lg">
            By using this Application, you agree and accept that the provisions related to indemnity provided in this Clause are reasonable.
          </p>
        </section>
        <h1 className="mt-4 font-bold text-2xl">10.THIRD PARTY LINKS </h1>
        <p className="text-lg mb-4">The Website, Application and Services may contain links to third-party websites or resources. You acknowledge and agree that PPTECK is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources; or (iii) privacy practices of such websites or resources. Links to such websites or resources do not imply any endorsement by PPTECK of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources or the content, products, or services on or available from such websites or resources. We recommend that you review their terms of service and privacy policies before accessing and using the Third-Party websites through these links</p>
        <h1 className="mt-4 font-bold text-2xl">11.TERMINATION / SUSPENSION </h1>
        <section className="mt-4">
          <p className="text-lg mb-4">
            PPTECK may at any time, terminate its legal agreement with you if you have breached any provision of the Terms, or there are suspected illegal, fraudulent, or abusive activity or loss of confidence (or have acted in manner which clearly shows that you do not intend to, or are unable to comply with the provisions of the Terms).
          </p>
          <p className="text-lg mb-4">
            If you wish to cease use of the Website and/or Application, you may deactivate your Account from the Application and delete the Application.
          </p>
          <p className="text-lg mb-4">
            Notwithstanding anything to the contrary in these Terms, We have the right to terminate, suspend, or amend your use of Website, Application or Services offered therein without liability:
            <ul className="list-disc pl-6 mt-2">
              <li>(a) to comply with any order issued or proposed to be issued by any governmental agency;</li>
              <li>(b) to comply with any provision of law;</li>
              <li>(c) if performance of any provision of these Terms by either Party would cause it to be in violation of law.</li>
            </ul>
          </p>
          <p className="text-lg mb-4">
            Upon termination of your (Vendor) Account by PPTECK or deactivation of the Account by you, your right to use the Website, Application or Services offered therein shall automatically terminate. Any such termination by you shall not cancel your duties to fulfil the contractual obligations with the Client.
          </p>
          <p className="text-lg mb-4">
            You acknowledge and agree that your right to use Website, Application or Services offered herein is conditional upon your adherence to the Terms, and the continuous activation of your Account.
          </p>
          <p className="text-lg">
            Upon Termination, PPTECK shall retain Registered User Information for a period of one hundred and eighty days (180 days) after any cancellation or withdrawal of the registration. PPTECK may use the non-personal information provided by You for internal analysis and archival purposes.
          </p>
          <p className="text-lg mb-4">
            All provisions of these Terms which by their nature should survive termination will survive termination, including, without limitation, ownership provisions, provisions related to intellectual property rights, warranty disclaimers, indemnity, and limitations of liability.
          </p>
        </section>
        <h1 className="mt-4 font-bold text-2xl">12.GOVERNING LAW AND JURISDICTION  </h1>
        <p className="text-lg mb-4">The Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to the principles of conflict of laws thereunder. The courts at Bangalore, India shall have exclusive jurisdiction, subject to Clause 13 of the Terms</p>
        <h1 className="mt-4 font-bold text-2xl">13.ARBITRATION  </h1>
        <p>Any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of these Terms to arbitrate, or to Your use of Website, Application or Services offered therein or the information to which it gives access, shall be determined by Arbitration in India before an independent arbitrator who shall be appointed by the Company. The arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996 along with all amendments.

          The venue and seat of arbitration shall be Bangalore, India. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language.

          The governing law of the Agreement shall be the substantive law of India. The award shall be final and binding on the Parties.

          This provision survives termination of your Account or relationship with PPTECK, bankruptcy, assignment, or transfer. If a portion of this arbitration provision is deemed unenforceable, the remaining portions of this arbitration provision will remain in full force and effect.

          Subject to the aforesaid, the Courts at Bangalore shall have exclusive jurisdiction over any proceedings arising in respect of the Agreement.</p>

        <h1 className="mt-4 font-bold text-2xl">14.GRIEVANCE REDRESSAL MECHANISM </h1>
        <section className="mt-4">
          <h2 className="text-2xl font-semibold mb-4">Details of the Grievance Officer</h2>
          <div className="text-lg mb-4">
            <p><strong>Name:</strong> SRINIVAS TANUKU</p>
            <p><strong>Designation:</strong> DIRECTOR</p>
            <p><strong>Phone:</strong> 9686601696</p>
            <p><strong>Email Id:</strong> <a href="mailto:srinivas.tanuku@peopleprocessteck.com" className="text-blue-500">srinivas.tanuku@peopleprocessteck.com</a></p>
            <p><strong>Time:</strong> Monday – Friday (10:00 AM – 6:00 PM)</p>
          </div>
        </section>

        {/* Nodal Person Section */}
        <section className="mt-4">
          <h2 className="text-2xl font-semibold mb-4">Details of the Nodal Person</h2>
          <div className="text-lg mb-4">
            <p><strong>Name:</strong> ARUN KUMAR MISRA</p>
            <p><strong>Designation:</strong> CEO</p>
            <p><strong>Address:</strong> B-404, Hoysala Habitat, Doddaballapur Road, Bangalore 560119</p>
            <p><strong>Phone:</strong> 997298341</p>
            <p><strong>Email Id:</strong> <a href="mailto:arun.misra@peopleprocessteck.com" className="text-blue-500">arun.misra@peopleprocessteck.com</a></p>
          </div>
        </section>

        {/* Legal Reference */}
        <section className="text-lg">
          <p className="mb-4">
            In accordance with the (i) Information Technology Act 2000, the (ii) Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021, and the (iii) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rule, 2011, the name and contact details of the Grievance Officer and Nodal Person who can be contacted for any complaints or concerns pertaining to the Application, including those pertaining to breach of the Terms or any other policies are published as under:
          </p>
        </section>
        <h1 className="mt-4 font-bold text-2xl">15.MISCELLANEOUS PROVISIONS </h1>
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Severability</h2>
          <p className="text-lg mb-4">
            If any provision of this Agreement is held by a court of competent jurisdiction or arbitral tribunal to be unlawful, conflicting with provisions of another Act, or otherwise unenforceable under applicable law, then such provision shall be excluded from the Agreement and the remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms. If two or more provisions of these Terms are deemed to conflict each other’s operation, PPTECK shall have the sole right to elect which provision remains in force.
          </p>
        </section>

        {/* Notices Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Notices</h2>
          <p className="text-lg mb-4">
            All notices and other communications required or permitted hereunder to be given to a Party shall be in writing, in English language, and shall be sent by fax, e-mail, or mailed by prepaid nationally recognized courier, or otherwise delivered by hand or by messenger, addressed to such Party’s address as set forth above.
          </p>
        </section>

        {/* Waiver Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Waiver</h2>
          <p className="text-lg mb-4">
            Nothing in these Terms shall be deemed waived, and no breach excused, unless such waiver or consent shall be in writing and signed by the Party claimed to have waived or consented.
          </p>
        </section>

        {/* Assignment Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Assignment</h2>
          <p className="text-lg mb-4">
            You may not assign or sub-license, without the prior written consent of PPTECK, the rights, duties and/or obligations under these Terms, in whole or in part, to any person or entity. We may assign, transfer, or delegate our rights and/or obligations under these Terms to any other party at our discretion.
          </p>
        </section>
      </div>
      <Footer className="p-0 m-0"/>
    </>
  )
}

export default Terms