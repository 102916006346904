import Footer from "../layout/Footer"
import Navbar from "../layout/NavBar"
import { Link } from "react-router-dom"
import imageOne from "../Images/pr-one.png"
import imageTwo from "../Images/pr-two.png"
import imageThree from "../Images/pr-three.png"

const PayrollManagement = () => {
  return (
    <>
      <Navbar />
      <div className="p-4 bg-primary-color h-[400px] bg-opacity-20 grid grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl text-primary-color text-center font-bold text-4xl">
            Payroll Management
          </h1>
          <h1 className="leading-6 text-center mt-2">
            Accurate and compliant payroll management is the backbone of employee satisfaction and organizational integrity. Our payroll management services ensure timely salary disbursement, accurate tax calculations, and adherence to statutory requirements such as PF, ESI, and gratuity. We simplify the complex process, enabling businesses to focus on growth.           </h1>
          <div className="mt-lg-3 mt-sm-1 flex justify-center item-center"> 
                  <Link to="/contact-us">
                    <button className=" p-2 bg-primary-color text-white rounded-md shadow-lg hover:bg-danger transition duration-300">
                      Contact Us
                    </button>
                  </Link>
                </div>         
          </div>
        <div className="flex justify-center items-center">
          <img src={imageOne} alt="" className="w-[400px] h-[400px]" />
        </div>
      </div>
      <div className="mt-4 px-[100px] grid grid-cols-2">
        <div>
          <img src={imageThree} alt="" className="w-[350px] h-[350px]" />
        </div>
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Benefits of Payroll Management
          </h1>
          <div className="flex gap-2 mt-2 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Accuracy and Compliance: </h1>
              <h1 className="mt-1"> Ensures accurate calculations and adherence to tax laws, minimizing errors and legal issues.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Time Savings:</h1>
              <h1 className="mt-1"> Automates payroll processes, freeing up HR staff for other tasks.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Employee Satisfaction:</h1>
              <h1 className="mt-1"> Timely and accurate payments boost employee morale and trust. </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Data Security:  </h1>
              <h1 className="mt-1"> Protects sensitive employee information through secure payroll systems.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Scalability: </h1>
              <h1 className="mt-1"> Easily adapts to business growth, handling more employees as needed.</h1>
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Regulatory Updates:  </h1>
              <h1 className="mt-1"> Keeps up with changes in tax laws and labor regulations, ensuring up to date compliance.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 px-4 mt-4">
        <div>
          <h1 className="font-bold text-primary-color text-xl">
            Key Areas of Payroll Management
          </h1>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Payroll Processing </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold"> Tax Compliance (LWF)
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Benefits Administration
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Record-Keeping </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Employee Self-Service
              </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">Direct Deposits </h1>
            </div>
          </div>
          <div className="flex gap-2 mt-2 items-center ">
            <span className="w-[5px] h-[45px] bg-primary-color block"></span>
            <div>
              <h1 className="font-bold">
                Reporting and AnalyticsProductivity Audit
              </h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <img src={imageTwo} alt="" className="w-[450px] h-[450px]" />
        </div>
      </div>
      <Footer />
    </>)
}

export default PayrollManagement