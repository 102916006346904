import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Bar } from 'react-chartjs-2';
import api from '../../../Services/api';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import MonthlyReport from '../../../Component/MonthlyReport';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement);

const COLORS = {
  success: '#0BDA51',
  overall: '#50c878',
  danger: '#B22222',
  notUploaded: 'white',
  warning: '#EFCC00'
};

const months = [
  { name: "JANUARY", value: "01" },
  { name: "FEBRUARY", value: "02" },
  { name: "MARCH", value: "03" },
  { name: "APRIL", value: "04" },
  { name: "MAY", value: "05" },
  { name: "JUNE", value: "06" },
  { name: "JULY", value: "07" },
  { name: "AUGUST", value: "08" },
  { name: "SEPTEMBER", value: "09" },
  { name: "OCTOBER", value: "10" },
  { name: "NOVEMBER", value: "11" },
  { name: "DECEMBER", value: "12" }
];
const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2020 + 1 }, (x, i) => 2020 + i);

const ReportTable = ({ rejected, underReview, onPercentagesChange }) => {
  let data = sessionStorage.getItem('user');
  let vendorData = JSON.parse(data);
  let vendorId = vendorData.id;

  const currentDate = new Date();
 
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  const [vendorChartData1, setVendorChartData1] = useState({});
  const [report, setReport] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(null);
  const [monthlyData, setMonthlyData] = useState({});  // Data for current month
  const [selectedMonthData, setSelectedMonthData] = useState({});  // Data for selected month (for report)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
  useEffect(() => {
    // Make sure you pass the correct values when the data is available
    const { rejectedPercent, notUploadedPercent } = getRejectedAndNotUploadedPercentages(monthlyData);
    onPercentagesChange(rejectedPercent, notUploadedPercent);
    console.log("percentage",rejectedPercent, notUploadedPercent);
  }, [monthlyData]);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Fetch current month's data when the component mounts
  useEffect(() => {
    if (currentDay < 16) {
      setErrorSnackbarOpen(true);
      setSnackbarErrorMessage('Compliance start Date is 18th');
    }
      fetchCurrentMonthData(vendorId, currentMonth < 10 ? `0${currentMonth}` : currentMonth, currentYear);
    
  }, [vendorId, currentMonth, currentYear, currentDay]);

  // Fetch selected month data for the report when selectedMonth or selectedYear changes
  useEffect(() => {
    if (selectedMonth && selectedYear) {
      fetchSelectedMonthData(vendorId, selectedMonth, selectedYear);
    }
  }, [selectedMonth, selectedYear]);
  const handleMonthChange = (event) => setSelectedMonth(event.target.value);
  const handleYearChange = (event) => setSelectedYear(event.target.value);
  // Fetch data for current month (status)
  const fetchCurrentMonthData = async (vendorId, month, year) => {
    try {
      const response = await api.vendor.getMonthlyData(vendorId, month, year);
      if (response.status === 200) {
        const chartData = response.data;
        setVendorChartData1((prevData) => ({
          ...prevData,
          [vendorId]: chartData,
        }));
        setMonthlyData(chartData);
        console.log(chartData,"un");
      } else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching current month data', error);
    }
  };

  // Fetch data for selected month (for report)
  const fetchSelectedMonthData = async (vendorId, month, year) => {
    try {
      const response = await api.vendor.getMonthlyData(vendorId, month, year);
      if (response.status === 200) {
        const chartData = response.data;
        setSelectedMonthData(chartData);
        setReport(true);
      } else {
        setErrorSnackbarOpen(true);
        setSnackbarErrorMessage(response.data.message);
        setReport(false);
      }
    } catch (error) {
      console.error('Error fetching selected month data', error);
    }
  };

  const getRejectedAndNotUploadedPercentages = (data) => {
    const accepted = data.AcceptedPercent || 0;
    const rejected = data.RejectedPercent || 0;
    const underReview = data.UnderReviewPercent || 0;
    return { rejectedPercent: rejected, UnderReviewPercent: underReview };
  };

  const StackedProgressBar = ({ segments, gap }) => {
    // If all percentages are zero, return a message instead of a progress bar
    const totalPercentage = segments.reduce((total, segment) => total + segment.percentage, 0);
    if (totalPercentage === 0) {
      return <div className="text-light">Not yet started</div>; // Show message when no data is available
    }
  
    return (
      <div style={{ width: '100%', height: '30px', display: 'flex', borderRadius: '0px', overflow: 'hidden', position: 'relative' }}>
        {segments.map((segment, index) => {
          const { percentage, color, label } = segment;
          const marginRight = `${gap || 0}px`;
  
          return (
            <div key={index} style={{ position: 'relative', width: `${percentage}%`, marginRight, border: '1px solid gray' }}>
              <div style={{ width: '100%', height: '100%', backgroundColor: color }} />
              <div style={{ position: 'absolute', top: '0px', left: '5px', top: '3px', fontSize: '13px', color: '#1c1c1c' }}>
                {percentage ? `${percentage}%` : ''}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="success"
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
      >
        <SnackbarContent style={{ backgroundColor: "green" }} message={snackbarMessage} />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        severity="error"
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        sx={{ marginTop: '2.9%', marginRight: '7%' }}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <SnackbarContent style={{ backgroundColor: "red" }} message={snackbarErrorMessage} />
      </Snackbar>

      <div className="mt-1  d-grid" >
        <div className="mb-3" style={{ border: '1px solid lightgray',backgroundColor:'rgba(0,0,0,0.85)' }}>
      
          <h6 className="text-center text-light p-2 m-0" style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>Current-Month Status</h6>
         
          <section >
            <table className="table table-hover table-light m-0 pt-0" style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th className="text-dark text-center">Actions</th>
                  <th className="text-dark text-center">Percentage</th>
                </tr>
              </thead>
              <tbody>
              
                  {monthlyData ? (
                    <>
                      <tr>
                        <td className="text-center">Risk</td>
                        <td className="text-danger text-center">
                          {getRejectedAndNotUploadedPercentages(monthlyData).rejectedPercent}%
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">Under Review</td>
                        <td className="text-secondary text-center">
                          {getRejectedAndNotUploadedPercentages(monthlyData).UnderReviewPercent}%
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">Loading...</td>
                    </tr>
                  )}
                
              </tbody>
            </table>
          </section>

          <section className="d-flex justify-content-center align-items-center w-100">
  <div className="d-flex flex-wrap justify-content-center align-items-center w-100">
    {Object.keys(vendorChartData1).map((vendorId) => {
      const vendorData = vendorChartData1[vendorId];

      const acceptedPercentage = monthlyData.AcceptedPercent || 0;
      const rejectedPercentage = monthlyData.RejectedPercent || 0;
      const underReviewPercentage = monthlyData.UnderReviewPercent || 0;

      const segments = [];

      // Push only non-zero percentages to the segments array
      if (acceptedPercentage > 0) {
        segments.push({ percentage: acceptedPercentage, color: COLORS.success, label: 'Accepted' });
      }

      if (rejectedPercentage > 0) {
        segments.push({ percentage: rejectedPercentage, color: COLORS.danger, label: 'Rejected' });
      }

      if (underReviewPercentage > 0) {
        segments.push({ percentage: underReviewPercentage, color: COLORS.warning, label: 'Under Review' });
      }

      // If no valid segments, show "Not yet started" message
      if (segments.length === 0) {
        return <div key={vendorId}>Not yet started</div>;
      }

      return (
        <div key={vendorId} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '10px' }}>
          <StackedProgressBar segments={segments} gap={4} />
        </div>
      );
    })}
  </div>
</section>
        </div>

        <section style={{ border: '1px solid lightgray',backgroundColor:'rgba(0,0,0,0.85)'  }}>
        
          <h6 className="text-light text-center p-2" style={{ backgroundColor: 'rgba(0,0,0,0.1)' ,borderBottom:'0.2px solid gray'}}>Report</h6>
       
          <div className="mx-4 mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <select value={selectedMonth || ''} onChange={handleMonthChange} style={{ marginRight: '10px' }}>
              <option value="">Select Month</option>
              {months.map((month) => (
                <option key={month.value} value={month.value}>{month.name}</option>
              ))}
            </select>
            <select value={selectedYear || ''} onChange={handleYearChange}>
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="mb-4 mt-3 d-flex justify-content-center">
            <button className="btn btn-sm btn-danger mx-2" onClick={handleShow} disabled={!selectedMonth || !selectedYear || !report}>
              View Report
            </button>
          </div>
          <MonthlyReport showModal={showModal} onClose={handleClose} selectedMonth={selectedMonth} selectedYear={selectedYear} />
        </section>
      </div>
    </div>
  );
};

export default ReportTable;

